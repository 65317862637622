export const FONT_FALLBACKS = {
  sansSerif:
    '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol',
  serif: '"Book Antiqua",Palatino,"Palatino Linotype","Palatino LT STD",Georgia,serif'
};

export const DEFAULT_STYLES_COLORS = {
  /* Main Colors */
  main: {
    color: '#3D3D3D',
    contrastColor: '#FFFFFF'
  },
  accent: {
    color: '#967E6E',
    contrastColor: '#FFFFFF'
  },
  ambient: {
    color: '#FFFFFF',
    contrastColor: '#3D3D3D'
  },
  /* Special colors */
  error: {
    color: '#f6374e',
    contrastColor: '#FFFFFF'
  }
};

export const FAILURE_DEFAULT = [
  {
    key: 'standard',
    value: {
      color: '#f6374e',
      contrastColor: '#FFFFFF'
    }
  }
];

export const DEFAULT_STYLES_TYPOGRAPHY = {
  p: {
    fontFamily: 'secondary',
    fontWeight: 'normal',
    uppercase: false,
    letterSpacing: 0,
    fontSize: '18px',
    lineHeight: '24px'
  },
  label: {
    fontFamily: 'secondary',
    fontWeight: 'normal',
    uppercase: false,
    letterSpacing: 0,
    fontSize: '11px',
    lineHeight: '16px'
  },
  button: {
    fontFamily: 'secondary',
    fontWeight: 'normal',
    uppercase: false,
    letterSpacing: 0,
    fontSize: '14px',
    lineHeight: '20px'
  },
  small: {
    fontFamily: 'secondary',
    fontWeight: 'normal',
    uppercase: false,
    letterSpacing: 0,
    fontSize: '14px',
    lineHeight: '20px'
  }
};

export const DEFAULT_STYLES = {
  alignment: 'left',
  borderRadius: '2px',
  boxShadowSpreadRadius: '1px',
  boxShadow: '0 1px 2px 0 rgba(0,0,0,0.2)'
};

export const StyleValues = {
  globals: DEFAULT_STYLES,
  colors: DEFAULT_STYLES_COLORS,
  typography: DEFAULT_STYLES_TYPOGRAPHY,
  fonts: {
    primary: FONT_FALLBACKS.sansSerif,
    secondary: FONT_FALLBACKS.serif,
    fontWeightBold: 'bold'
  }
};

export const defaultTexts = {
  de: {
    ctaButtonText: 'Newsletter abonnieren'
  },
  it: {
    ctaButtonText: 'iscriviti'
  },
  en: {
    ctaButtonText: 'subscribe'
  },
  fr: {
    ctaButtonText: 'S`inscrire à la newsletter'
  },
  nl: {
    ctaButtonText: 'Newsletter abonneren'
  }
};

/**
 * Util that transforms given colors into an array which are supported by our select-component
 *
 *
 * The input param should always have the correct format as defined by the api.
 *
 * ```js
 * colors: {
 *  key: {
 *    main: {
 *      color: #000,
 *      contrastColor: #ff
 *    }
 *  }
 * }
 * ```
 * Available colors keys are `main`, `accent` and `ambient`.
 * Each one is again an object which consists of a color and a contrast color.
 *
 * @function getColorOptions
 * @param {Object} colors
 * @return {Array} colorOptions
 */
export const getColorOptions = (colors) => {
  const colorOptions = Object.keys(colors).map((key) => {
    return {
      key,
      value: colors[key]
    };
  });

  return colorOptions;
};
