import ENV from 'additive-newsletter/config/environment';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { all, task, timeout } from 'ember-concurrency';
import { format } from 'date-fns';
import { tracked } from '@glimmer/tracking';

export default class InstanceDashboardController extends Controller {
  @service currentUser;
  @service authenticatedFetch;
  @service uiToast;
  @service intl;

  @tracked
  dateRanges = null;

  @tracked
  dateRange = null;

  @tracked
  period = {
    name: this.intl.t(`dashboard.periodSelection.last_12_months`),
    value: 'past_twelve_months'
  };

  get organizationSlug() {
    return this.currentUser?.currentOrganization?.id;
  }

  get getSelectedDateRange() {
    if (!this.dateRanges || !this.dateRange) {
      return null;
    }

    const selected = this.dateRanges.filter(
      (dateRange) =>
        dateRange.start === this.dateRange.start && dateRange.end === this.dateRange.end
    )[0];

    return selected;
  }

  get salutation() {
    const time = format(new Date(), 'HH:mm');
    let salutation;
    let name = this.currentUser?.user?.fullName.split(' ')[0];
    name == undefined ? (name = '') : (name = `, ${name}`);

    time > '00:00' && time <= '12:00'
      ? (salutation = this.intl.t(`dashboard.salutations.morning`))
      : time > '12:00' && time < '17:00'
      ? (salutation = this.intl.t(`dashboard.salutations.afternoon`))
      : (salutation = this.intl.t(`dashboard.salutations.evening`));

    return `${salutation}${name}!`;
  }

  @task(function* () {
    const tasks = [];
    const baseUrl = ENV.APP.apiBaseHost;
    const url = `${baseUrl}/${this.organizationSlug}/dashboard/filters`;

    try {
      tasks.push(this.authenticatedFetch.fetch(url));
      tasks.push(timeout(250));

      const [response] = yield all(tasks);
      if (response && response.ok) {
        const { dateranges } = yield response.json();
        this.dateRanges = dateranges;
        this.dateRange = { start: dateranges[0].start, end: dateranges[0].end };
      } else {
        const { errors } = yield response.json();
        throw new Error(`${errors}`);
      }
    } catch (error) {
      this.uiToast.showToast({
        title: this.intl.t('toast.unexpectedError'),
        type: 'error'
      });
    }
  })
  getDateRange;

  @action
  onDateRangeChange(value) {
    const dateRange = this.dateRanges.filter((dateRange) => dateRange.value === value)[0];
    this.dateRange = { start: dateRange.start, end: dateRange.end };
  }
}
