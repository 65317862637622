import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

import { hasAccess } from '@additive-apps/auth/utils/roles-map';
import { onboardingData } from 'additive-newsletter/utils/constants';

// add instance Routes here
const instanceRoutes = [
  {
    path: 'instance.dashboard',
    intlKey: 'routes.dashboard.title',
    svg: 'home'
  },
  {
    path: 'instance.address-books',
    intlKey: 'routes.addressBooks.title',
    svg: 'people'
  },
  {
    path: 'instance.campaigns',
    intlKey: 'routes.campaigns.title',
    svg: 'mail'
  },
  {
    path: 'instance.reports',
    intlKey: 'routes.reports.title',
    svg: 'arrow-chart'
  },
  {
    path: 'instance.multimedia',
    intlKey: 'routes.multimedia.title',
    svg: 'image'
  },
  {
    path: 'instance.templates',
    intlKey: 'routes.templates.title',
    svg: 'template'
  },
  {
    path: 'instance.styles',
    intlKey: 'routes.styles.navTitle',
    svg: 'style'
  },
  {
    path: 'instance.auditlog',
    intlKey: 'routes.auditLog.title',
    svg: 'audit-log',
    requiredRole: 'member'
  },
  {
    path: 'instance.settings',
    intlKey: 'routes.settings.title',
    svg: 'settings'
  }
];

export default class ApplicationController extends Controller {
  @service authenticatedFetch;
  @service currentUser;
  @service session;
  @service store;
  @service router;
  @service uiState;
  @service uiAppSettings;

  @tracked
  onboarding = null;

  queryParams = ['onboarding'];

  get instanceRoutes() {
    return instanceRoutes.filter((route) => {
      if (route.requiredRole) {
        return !route.requiredRole || hasAccess(route.requiredRole, this.currentUser?.user?.role);
      }

      return true;
    });
  }

  get onboardingOptions() {
    return onboardingData(this.intl);
  }

  get helpUrl() {
    const locale = this.uiLocale?.locale || 'de';

    return `https://knowledge.additive.eu/${locale}/knowledge/additive-newsletter`;
  }

  @action
  transitionToOrganizationRoute(organization) {
    this.currentUser.currentOrganization = organization;
    this.currentUser.user = null;

    this.store.unloadAll('content-block');
    this.store.unloadAll('content-block-variation');
    this.store.unloadAll('address-book');
    this.store.unloadAll('address-book-subscriber');
    this.store.unloadAll('campaign');
    this.store.unloadAll('campaign-block');
    this.store.unloadAll('enquiry-integration');
    this.store.unloadAll('group');
    this.store.unloadAll('layout');
    this.store.unloadAll('style');
    this.store.unloadAll('style-block');
    this.store.unloadAll('report');
    this.store.unloadAll('subscriber');
    this.store.unloadAll('template');
    this.store.unloadAll('template-block');
    this.store.unloadAll('widget');
    this.store.unloadAll('medium');
    this.store.unloadAll('folder');
    this.uiAppSettings.reset();

    /*
    we have always to pass the id and not the object or
    the instance-routes model-hook won't trigger again
  */
    this.router.transitionTo('instance', organization.id);
  }

  @action
  finishOnboarding() {
    this.onboarding = null;
  }
}
