import { Response } from 'miragejs';

export const InfoHandler = (schema, request) => {
  const {
    params: { abId, absId }
  } = request;

  const abses = schema.addressBookSubscribers.where({ addressBookId: abId, id: absId });
  if (abses.models.length !== 1) {
    return new Response(404);
  }

  const abs = abses.models[0];

  return {
    info: {
      createdAt: abs.subscriber.createdAt,
      updatedAt: abs.subscriber.updatedAt,
      confirmations: abs.confirmations,
      subscribedAt: abs.subscribedAt,
      unsubscribedAt: abs.unsubscribedAt,
      source: abs.subscriber.source,
      addressBooks: abs.subscriber.addressBooks.models.map((addressBook) => ({
        id: addressBook.id,
        name: addressBook.name
      })),
      groups: abs.subscriber.groups.models.map((group) => ({
        id: group.id,
        name: group.name,
        addressBookId: group.addressBook.id,
        addressBookName: group.addressBook.name
      }))
    }
  };
};

export const CreateHandler = (schema, request, server) => {
  const json = JSON.parse(request.requestBody);

  return server.create(
    'address-book-subscriber',
    Object.assign({
      email: json.email,
      language: json.language
    })
  );
};

export const DeleteHandler = (schema, request) => {
  const {
    params: { abId }
  } = request;

  const abses = schema.addressBookSubscribers.where({ addressBookId: abId });
  if (abses.models.length !== 1) {
    return new Response(404);
  }
  abses.models[0].destroy();
  return new Response(201);
};

export const Subscribers = (server) => {
  server.post('/addressbooks/:abId/subscribers', (schema, request) => {
    return CreateHandler(schema, request, server);
  });

  server.get('/addressbooks/:abId/subscribers/:absId', (schema, { params: { absId } }) => {
    return schema.addressBookSubscribers.find(absId);
  });

  server.get('/addressbooks/:abId/subscribers/:absId/info', (schema, request) => {
    return InfoHandler(schema, request);
  });

  server.get('/addressbooks/:id/subscribers', (schema, { params: { id } }) => {
    return schema.addressBookSubscribers.where({ addressBookId: id });
  });

  server.get('/addressbooks/:aId/groups/:gId/subscribers', (schema, { params: { aId, gId } }) => {
    return schema.addressBookSubscribers.where({ addressBookId: aId, groupIds: [gId] });
  });

  server.delete('/addressbooks/:abId/subscribers', (schema, request) => {
    return DeleteHandler(schema, request);
  });

  server.put('/addressbooks/:aId/subscribers/:sId', (schema, request) => {
    const { aId, sId } = request.params;
    const requestBody = JSON.parse(request.requestBody);

    const addressBookSubscriber = schema.addressBookSubscribers.where({
      addressBookId: aId,
      id: sId
    });

    return addressBookSubscriber.models[0].update(
      Object.assign(requestBody, { salutationText: 'updatedSalutationText' })
    );
  });
};
