import ENV from 'additive-newsletter/config/environment';

/* TODO: move me into an addon */
const DEFAULTS = {
  contentLanguages: ['de', 'en', 'it'],
  defaultLanguage: 'de'
};

const accountApiRequests = (server, orgSlug) => {
  server.get(`${ENV.APP.accountApiHost}/api/organizations`, (schema) => {
    return schema.organizations.all();
  });

  server.get(`${ENV.APP.accountApiHost}/api/organizations/${orgSlug}`, (schema) => {
    return schema.organizations.findBy({ id: orgSlug });
  });

  server.get(`${ENV.APP.accountApiHost}/api/organizations/${orgSlug}/apps`, (schema) => {
    return schema.organizations.findBy({ id: orgSlug });
  });
};

export const starter = (server, orgSlug = 'test-org') => {
  server.get(`${server.urlPrefix}/${orgSlug}`, () => {
    return {
      organization: Object.assign({}, DEFAULTS, { planName: 'starter_yearly' })
    };
  });

  accountApiRequests(server, orgSlug);
};

export const professional = (server, orgSlug = 'test-org') => {
  server.get(`${server.urlPrefix}/${orgSlug}`, () => {
    return {
      organization: Object.assign({}, DEFAULTS, { planName: 'professional_yearly' })
    };
  });

  accountApiRequests(server, orgSlug);
};

export const enterprise = (server, orgSlug = 'test-org') => {
  server.get(`${server.urlPrefix}/${orgSlug}`, () => {
    return {
      organization: Object.assign({}, DEFAULTS, { planName: 'enterprise_yearly' })
    };
  });

  accountApiRequests(server, orgSlug);
};
