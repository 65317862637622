import { helper } from '@ember/component/helper';
import { CAMPAIGN_STATES, PENDING_CAMPAIGN_STATES } from 'additive-newsletter/utils/constants';

export const getCampaignStatus = (params) => {
  const [status] = params;

  if (
    status === CAMPAIGN_STATES.DRAFT ||
    status === CAMPAIGN_STATES.SENDING_ABORTED ||
    status === CAMPAIGN_STATES.RESENDING_ABORTED
  ) {
    return 'warning';
  }

  if (
    status === CAMPAIGN_STATES.SENDING_FAILED_NO_SUBSCRIBERS ||
    status === CAMPAIGN_STATES.SENDING_FAILED_NO_VALID_EMAIL
  ) {
    return 'error';
  }

  if (PENDING_CAMPAIGN_STATES.includes(status)) {
    return 'null';
  }

  return 'success';
};

export default helper(getCampaignStatus);
