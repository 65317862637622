import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { set } from '@ember/object';

import { decodeSortString } from '@additive-apps/ui/utils/sort-util';

export default class SubscribersRoute extends Route {
  @service uiFilter;

  model() {
    return super.model(...arguments);
  }

  setupController(controller) {
    const { filter } = this.uiFilter.getQPFilter('address-book-subscribers');
    const value = filter.getSortValue() || '-subscribedAt';
    set(controller, 'sortState', decodeSortString(value));

    set(controller, 'key', 'address-book-subscribers');

    this.uiFilter.registerCallback('address-book-subscribers', 'onChange', () => {
      if (
        !controller ||
        !controller.selection ||
        typeof controller.selection.clearSelection !== 'function'
      ) {
        return;
      }

      controller.selection.clearSelection();
    });
  }

  resetController(controller) {
    if (
      controller &&
      controller.selection &&
      typeof controller.selection.clearSelection === 'function'
    ) {
      controller.selection.clearSelection();
    }

    set(controller, 'key', null);
  }
}
