import { Factory, trait } from 'ember-cli-mirage';
import faker from 'faker';

export default Factory.extend({
  confirmed: () => faker.random.boolean(),
  subscribedAt: () => faker.date.recent(),
  unsubscribedAt: null,
  salutationText: () => faker.lorem.words(),

  unsubscribed: trait({
    unsubscribedAt: () => faker.date.recent()
  }),

  subscribed: trait({
    unsubscribedAt: null
  }),

  isConfirmed: trait({
    confirmed: true
  }),

  hasGroup: trait({
    /* Internal property to attach group in afterCreate hook */
    _hasGroup: true
  }),

  /* Only exposed within `/info` response */
  isConfirmedForInfo: trait({
    confirmed: true,
    confirmations: [
      {
        confirmationIpAddress: faker.internet.ip(),
        confirmationType: faker.random.arrayElement(['double_opt_in']),
        confirmedAt: faker.date.recent()
      }
    ]
  }),

  /* Create relationships if not provided */
  afterCreate(model, server) {
    if (!model.addressBook) {
      model.update({
        addressBook: server.create('address-book')
      });
    }

    if (!model.subscriber) {
      model.update({
        subscriber: server.create('subscriber', {
          addressBooks: [model.addressBook]
        })
      });

      /* Properties from subscriber, if given pass to subscriber */
      if (model.email) {
        model.subscriber.update({ email: model.email });
      }
      if (model.language) {
        model.subscriber.update({ language: model.language });
      }
    }

    let groups = model.subscriber.groups.models || [];

    if (model._hasGroup && (!Array.isArray(model.groups) || model.groups.length === 0)) {
      groups = [server.create('group', { addressBook: model.addressBook })];
    }

    if (groups.length >= 1) {
      model.subscriber.update({
        groups
      });

      groups.forEach((group) => group.update({ addressBook: model.addressBook }));

      model.subscriber.update({ groups });
    }
  }
});
