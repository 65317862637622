import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.subscriber}}\n  <UiModal\n    @title={{t \"components.anlSubscriber.noteEdit.title\"}}\n    @size=\"md\"\n    @onClose={{this.onClose}}\n    as |modal|\n  >\n    <modal.header />\n    <modal.content as |content|>\n      <content.body data-test-anl-subscriber-note-edit-content={{true}}>\n        <UiInput @textarea={{true}} @value={{this._noteValue}} @rows={{2}} />\n      </content.body>\n    </modal.content>\n\n    <modal.footer data-test-anl-subscriber-note-edit-footer={{true}}>\n      <UiButton\n        @label={{t \"global.actions.save\"}}\n        @isSecondary={{true}}\n        @onClick={{perform this._save}}\n      />\n    </modal.footer>\n  </UiModal>\n{{/if}}", {"contents":"{{#if this.subscriber}}\n  <UiModal\n    @title={{t \"components.anlSubscriber.noteEdit.title\"}}\n    @size=\"md\"\n    @onClose={{this.onClose}}\n    as |modal|\n  >\n    <modal.header />\n    <modal.content as |content|>\n      <content.body data-test-anl-subscriber-note-edit-content={{true}}>\n        <UiInput @textarea={{true}} @value={{this._noteValue}} @rows={{2}} />\n      </content.body>\n    </modal.content>\n\n    <modal.footer data-test-anl-subscriber-note-edit-footer={{true}}>\n      <UiButton\n        @label={{t \"global.actions.save\"}}\n        @isSecondary={{true}}\n        @onClick={{perform this._save}}\n      />\n    </modal.footer>\n  </UiModal>\n{{/if}}","moduleName":"additive-newsletter/components/anl-subscriber/note-edit.hbs","parseOptions":{"srcName":"additive-newsletter/components/anl-subscriber/note-edit.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { arg } from 'ember-arg-types';
import { object, func } from 'prop-types';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import Model from '@ember-data/model';

export default class AnlSubscriberNoteEditComponent extends Component {
  @service intl;
  @service uiDialog;
  @service uiToast;

  @arg(object)
  @tracked
  subscriber = null;

  @arg(func)
  onClose = () => {};

  _noteValue = null;

  constructor(owner, args) {
    super(owner, args);

    if (!(args.subscriber instanceof Model)) {
      return;
    }

    this._noteValue = args.subscriber && args.subscriber.note;
  }

  @task(function* () {
    try {
      this.subscriber.set('note', this._noteValue);

      yield this.subscriber.save();

      this.uiToast.showToast({
        type: 'success',
        title: this.intl.t('global.toast.success.savedChanges')
      });

      this.onClose();
    } catch (e) {
      this.subscriber.rollbackAttributes();

      this.uiDialog.showError();
    }
  })
  _save;
}
