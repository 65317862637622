import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<UiModal\n  @title={{t \"components.anlWidgets.deleteDialog.title\"}}\n  @theme=\"error\"\n  @onClose={{this.onClose}}\n  as |modal|\n>\n  <modal.header />\n  <modal.content as |content|>\n    <content.body>\n      <div class=\"font-md\">\n        {{t \"components.anlWidgets.deleteDialog.description\"}}\n      </div>\n    </content.body>\n  </modal.content>\n  <modal.footer>\n    <UiButton\n      @label={{t \"global.actions.delete\"}}\n      @isDanger={{true}}\n      @isSecondary={{true}}\n      @onClick={{perform this.deleteWidget}}\n    />\n  </modal.footer>\n</UiModal>", {"contents":"<UiModal\n  @title={{t \"components.anlWidgets.deleteDialog.title\"}}\n  @theme=\"error\"\n  @onClose={{this.onClose}}\n  as |modal|\n>\n  <modal.header />\n  <modal.content as |content|>\n    <content.body>\n      <div class=\"font-md\">\n        {{t \"components.anlWidgets.deleteDialog.description\"}}\n      </div>\n    </content.body>\n  </modal.content>\n  <modal.footer>\n    <UiButton\n      @label={{t \"global.actions.delete\"}}\n      @isDanger={{true}}\n      @isSecondary={{true}}\n      @onClick={{perform this.deleteWidget}}\n    />\n  </modal.footer>\n</UiModal>","moduleName":"additive-newsletter/components/anl-widgets/delete-dialog.hbs","parseOptions":{"srcName":"additive-newsletter/components/anl-widgets/delete-dialog.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task, timeout, all } from 'ember-concurrency';
import { arg } from 'ember-arg-types';
import { func, object } from 'prop-types';

/**
 * Dialog that is shown when a user wants to delete a widget
 *
 * @module anl-widgets
 * @class AnlWidgetsDeleteDialog
 */
export default class AnlWidgetsDeleteDialog extends Component {
  @service intl;
  @service uiDialog;
  @service uiToast;

  /**
   * The widget to be deleted
   * @argument widget
   * @type {Model}
   * @default null
   *
   */
  @arg(object)
  widget = null;

  /**
   * The callback function which is executed on close
   * @argument onClose
   * @type {Function}
   *
   */
  @arg(func)
  onClose = () => {};

  @task(function* () {
    try {
      const tasks = [this.widget.destroyRecord(), timeout(250)];

      yield all(tasks);

      this.uiToast.showToast({
        type: 'success',
        title: this.intl.t('global.toast.success.savedChanges')
      });
    } catch (error) {
      if (error?.errors?.length > 0) {
        const errorData = error.errors[0];

        // handle conflict error
        if (errorData.status === 409) {
          const { intl } = this;

          this.uiDialog.showConflictError(
            intl.t('components.anlWidgets.deleteDialog.conflictError.title'),
            intl.t('components.anlWidgets.deleteDialog.conflictError.description'),
            errorData.dependencies
          );
          return;
        }
      }

      throw new Error('[WIDGET] delete widget', error);
    } finally {
      this.onClose();
    }
  })
  deleteWidget;
}
