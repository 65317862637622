import Model, { attr, hasMany } from '@ember-data/model';
import { computed } from '@ember/object';

export default Model.extend({
  gender: attr('string'),
  givenName: attr('string'),
  familyName: attr('string'),
  birthday: attr('string'),
  language: attr('string'),
  country: attr('string'),
  email: attr('string'),
  note: attr('string'),
  organisation: attr('string'),
  source: attr('number', {
    defaultValue: 0
  }),
  salutation: attr('string'),
  customSalutation: attr('string'),

  // TODO: add these properties when API supports them
  // telephone: attr('string'),
  // streetAddress: attr('string'),
  // postalCode: attr('string'),
  // addressLocality: attr('string'),

  deactivatedAt: attr('string'),

  addressBookSubscriber: hasMany('address-book-subscriber'),

  isDeactivated: computed.notEmpty('deactivatedAt'),

  fullName: computed('givenName', 'familyName', {
    get() {
      return `${this.givenName || '-'} ${this.familyName || ''}`;
    }
  })
});
