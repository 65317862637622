/**
 * Route for the link activities
 * @module routes: instance/reports/report
 */

/**
 * @param {*} self
 */
export const LinkActivities = (server) => {
  server.get('/reports/:reportId/links', () => {
    return {
      links: [
        {
          clickedTotal: 491,
          clickedUniqueTotal: 349,
          link: 'https://www.testhotel-post.com/online-buchen/',
          rate: 69.8
        },
        {
          clickedTotal: 137,
          clickedUniqueTotal: 110,
          link: 'https://www.testhotel-post.com/informationen/coronavirus',
          rate: 22
        },
        {
          clickedTotal: 89,
          clickedUniqueTotal: 15,
          link: 'https://www.instagram.com/testhotel-post/',
          rate: 3
        },
        {
          clickedTotal: 32,
          clickedUniqueTotal: 17,
          link: 'https://www.facebook.com/Testhotel.Post',
          rate: 3.4000000000000004
        },
        {
          clickedTotal: 11,
          clickedUniqueTotal: 6,
          link: 'https://www.testhotel-post.com/impressum.html',
          rate: 1.2
        },
        {
          clickedTotal: 6,
          clickedUniqueTotal: 3,
          link: 'https://www.facebook.com/Testhotel.Post/',
          rate: 0.6
        }
      ]
    };
  });
};
