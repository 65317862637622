import ENV from 'additive-newsletter/config/environment';
import AuthorizationAdapter from '@additive-apps/auth/adapters/application';

import { inject as service } from '@ember/service';
import { pluralize } from 'ember-inflector';
import { camelize } from '@ember/string';

export default class ApplicationAdapter extends AuthorizationAdapter {
  @service currentUser;
  @service uiAppSettings;

  host = ENV.APP.apiBaseHost;

  get currentLocale() {
    return this.uiAppSettings?.currentLocale;
  }

  get headers() {
    const headers = {
      Accept: 'application/vnd.additive+json; version=2',
      'Accept-Language': this.currentLocale || 'de',
      'Content-Type': 'application/json'
    };

    return Object.assign({}, super.headers, headers);
  }

  get namespace() {
    return this.currentUser?.currentOrganization?.id;
  }

  pathForType(modelName) {
    // Remove dashes and set to lower case
    return camelize(pluralize(modelName))?.toLowerCase();
  }

  buildURL() {
    let url = super.buildURL(...arguments);

    return url?.toLowerCase();
  }
}
