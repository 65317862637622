import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<UiItemMenu as |menu|>\n  <menu.menuItem\n    @label={{t \"global.actions.rename\"}}\n    @onClick={{fn (mut this._isRenameDialog) true}}\n  />\n  <menu.menuItem\n    @label={{t \"global.actions.delete\"}}\n    @isDanger={{true}}\n    @onClick={{fn (mut this._isDeleteDialog) true}}\n  />\n</UiItemMenu>\n\n{{#if this._isRenameDialog}}\n  <AnlAddressBook::Groups::Edit\n    @addressBook={{this.addressBook}}\n    @group={{this.group}}\n    @onClose={{fn (mut this._isRenameDialog) false}}\n  />\n{{/if}}\n\n{{#if this._isDeleteDialog}}\n  <AnlAddressBook::Groups::Delete\n    @addressBook={{this.addressBook}}\n    @group={{this.group}}\n    @onClose={{fn (mut this._isDeleteDialog) false}}\n  />\n{{/if}}", {"contents":"<UiItemMenu as |menu|>\n  <menu.menuItem\n    @label={{t \"global.actions.rename\"}}\n    @onClick={{fn (mut this._isRenameDialog) true}}\n  />\n  <menu.menuItem\n    @label={{t \"global.actions.delete\"}}\n    @isDanger={{true}}\n    @onClick={{fn (mut this._isDeleteDialog) true}}\n  />\n</UiItemMenu>\n\n{{#if this._isRenameDialog}}\n  <AnlAddressBook::Groups::Edit\n    @addressBook={{this.addressBook}}\n    @group={{this.group}}\n    @onClose={{fn (mut this._isRenameDialog) false}}\n  />\n{{/if}}\n\n{{#if this._isDeleteDialog}}\n  <AnlAddressBook::Groups::Delete\n    @addressBook={{this.addressBook}}\n    @group={{this.group}}\n    @onClose={{fn (mut this._isDeleteDialog) false}}\n  />\n{{/if}}","moduleName":"additive-newsletter/components/anl-address-book/groups/list-actions.hbs","parseOptions":{"srcName":"additive-newsletter/components/anl-address-book/groups/list-actions.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { arg } from 'ember-arg-types';
import { object } from 'prop-types';

/**
 * component that contains an item-menu with the actions of a group
 *
 * @module anl-address-book/groups
 * @class anl-address-book/groups/list-actions
 */
export default class anlAddressBookGroupsListActionsComponent extends Component {
  /**
   * the address book model
   *
   * @argument addressBook
   * @type {Model}
   * @default null
   */
  @arg(object)
  @tracked
  addressBook = null;

  /**
   * the active group
   *
   * @argument group
   * @type {Model}
   * @default null
   */
  @arg(object)
  @tracked
  group = null;

  /**
   * whether the rename dialog is open
   *
   * @property _isRenameDialog
   * @type {Boolean}
   * @default false
   */
  @tracked
  _isRenameDialog = false;

  /**
   * whether the delete confirm dialog is open
   *
   * @property _isDeleteDialog
   * @type {Boolean}
   * @default false
   * @private
   */
  @tracked
  _isDeleteDialog = false;
}
