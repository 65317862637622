import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"ui-upgrade-notification bg-blue pa3 pa4-sm br1 flex-row justify-between\">\n  <div class=\"flex mh3-md flex-shrink-1 items-center relative\">\n    {{#if this.plan}}\n      <div\n        class=\"ui-upgrade-notification__plan-badge absolute top-0 right-0 bg-white font-xs ph05 blue shadow-1\"\n      >\n        {{@plan}}\n      </div>\n    {{/if}}\n    {{svg-jar this.logo width=\"56\"}}\n\n    <div class=\"pl2 font-lg white\">\n      {{@title}}\n    </div>\n  </div>\n\n  <div class=\"ph0 ph3-md pt1 pt0-md font-md white\">\n    {{@text}}\n  </div>\n\n  <UiActionButton\n    @class=\"mt2 ph0 mh3-md bg-white flex-shrink-1\"\n    @label={{this.buttonText}}\n    @onClick={{this.transitionToUpgrade}}\n    @isBlur={{true}}\n    @isLarge={{true}}\n  />\n</div>", {"contents":"<div class=\"ui-upgrade-notification bg-blue pa3 pa4-sm br1 flex-row justify-between\">\n  <div class=\"flex mh3-md flex-shrink-1 items-center relative\">\n    {{#if this.plan}}\n      <div\n        class=\"ui-upgrade-notification__plan-badge absolute top-0 right-0 bg-white font-xs ph05 blue shadow-1\"\n      >\n        {{@plan}}\n      </div>\n    {{/if}}\n    {{svg-jar this.logo width=\"56\"}}\n\n    <div class=\"pl2 font-lg white\">\n      {{@title}}\n    </div>\n  </div>\n\n  <div class=\"ph0 ph3-md pt1 pt0-md font-md white\">\n    {{@text}}\n  </div>\n\n  <UiActionButton\n    @class=\"mt2 ph0 mh3-md bg-white flex-shrink-1\"\n    @label={{this.buttonText}}\n    @onClick={{this.transitionToUpgrade}}\n    @isBlur={{true}}\n    @isLarge={{true}}\n  />\n</div>","moduleName":"additive-newsletter/components/ui-upgrade-notification.hbs","parseOptions":{"srcName":"additive-newsletter/components/ui-upgrade-notification.hbs"}});
import Component from '@glimmer/component';
import { arg } from 'ember-arg-types';
import { string } from 'prop-types';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class UiUpgradeNotificationComponent extends Component {
  @arg(string)
  @tracked
  plan = null;

  @arg(string)
  @tracked
  href = null;

  @arg(string)
  @tracked
  title = null;

  @arg(string)
  @tracked
  text = null;

  @arg(string)
  @tracked
  logo = null;

  @arg(string)
  @tracked
  buttonText = null;

  @action
  transitionToUpgrade() {
    window.open(this.href, '_blank');
  }
}
