import Model, { attr } from '@ember-data/model';
import { A } from '@ember/array';

export default Model.extend({
  defaultAddressBook: attr('string'),

  addressBookPerEnquiryChannel: attr({
    defaultValue() {
      return A();
    }
  }),

  enquiryChannels: attr({
    defaultValue() {
      return A();
    }
  })
});
