import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AnlCampaigns::SendDialog::ListItem\n  @title={{@title}}\n  @description={{@description}}\n  @statusState={{@statusState}}\n  @height={{56}}\n  @isLoading={{@isLoading}}\n  @class=\"button-list-item\"\n  ...attributes\n  as |item|\n>\n  <item.action>\n    <UiButton\n      @label={{@buttonLabel}}\n      @isSecondary={{true}}\n      @isDisabled={{@buttonDisabled}}\n      @onClick={{@onClickButton}}\n    />\n  </item.action>\n  <item.extra>\n    {{yield (hash extra=(component \"ui-blank-template\"))}}\n  </item.extra>\n</AnlCampaigns::SendDialog::ListItem>\n\n{{yield (hash extra=(component \"ui-blank-template\"))}}", {"contents":"<AnlCampaigns::SendDialog::ListItem\n  @title={{@title}}\n  @description={{@description}}\n  @statusState={{@statusState}}\n  @height={{56}}\n  @isLoading={{@isLoading}}\n  @class=\"button-list-item\"\n  ...attributes\n  as |item|\n>\n  <item.action>\n    <UiButton\n      @label={{@buttonLabel}}\n      @isSecondary={{true}}\n      @isDisabled={{@buttonDisabled}}\n      @onClick={{@onClickButton}}\n    />\n  </item.action>\n  <item.extra>\n    {{yield (hash extra=(component \"ui-blank-template\"))}}\n  </item.extra>\n</AnlCampaigns::SendDialog::ListItem>\n\n{{yield (hash extra=(component \"ui-blank-template\"))}}","moduleName":"additive-newsletter/components/anl-campaigns/send-dialog/button-list-item.hbs","parseOptions":{"srcName":"additive-newsletter/components/anl-campaigns/send-dialog/button-list-item.hbs"}});
import Component from '@glimmer/component';
import { arg } from 'ember-arg-types';
import { string, func, boolean } from 'prop-types';

export default class ButtonListItem extends Component {
  /**
   * The title of the ListItem
   *
   * @argument title
   * @type {String}
   * @default null
   */
  @arg(string)
  title = null;

  /**
   * The description of the ListItem
   *
   * @argument description
   * @type {String}
   * @default null
   */
  @arg(string)
  description = null;

  /**
   * The state of the UiStatus component.
   *
   * @argument statusState
   * @type {String}
   * @default null
   */
  @arg(string)
  statusState = null;

  /**
   * The function executed when the button is clicked.
   *
   * @argument onClickButton
   * @type {Function}
   */
  @arg(func)
  onClickButton = () => {};

  /**
   * The boolean whether or not the button is disabled.
   *
   * @argument buttonDisabled
   * @type {Boolean}
   * @default null
   */
  @arg(boolean)
  buttonDisabled = null;
}
