import { validateSalutationPlaceholders } from 'additive-newsletter/validators/salutation';

export const genderSalutationValidation = (intl) => {
  return {
    f: validateSalutationPlaceholders({
      message: intl.t('errors.invalidSalutationPlaceholder')
    }),
    m: validateSalutationPlaceholders({
      message: intl.t('errors.invalidSalutationPlaceholder')
    })
  };
};

export const simpleSalutationValidation = (intl) => {
  return {
    value: validateSalutationPlaceholders({
      message: intl.t('errors.invalidSalutationPlaceholder')
    })
  };
};
