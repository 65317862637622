function listenerFunction(e, showDialogFunction) {
  const showDialog = showDialogFunction && showDialogFunction();

  if (!showDialog) return;

  e = e || window.event;
  // For IE and Firefox prior to version 4
  if (e) {
    e.preventDefault();
    e.returnValue = '';
  }

  // For Safari
  return '';
}

// Adds event listener before tab/window is reloaded/closed. A popup is shown to the user
export function showWarningDialogBeforeClose(
  router,
  currentRouteName,
  showDialogFunction,
  onChangeUrlWhenDirty
) {
  // Native
  window.onbeforeunload = (event) => listenerFunction(event, showDialogFunction);

  // Ember changes url
  router.on('routeWillChange', (transition) => {
    const doesChangeRoute = !transition.to.name.startsWith(currentRouteName);

    if (doesChangeRoute && showDialogFunction()) {
      router.transitionTo(router.currentRouteName);
      onChangeUrlWhenDirty();
    }
  });
}

export function removeWarningDialogBeforeClose(router) {
  window.onbeforeunload = null;

  router.on('routeWillChange', null);
}
