import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import FullWidthMixin from '@additive-apps/ui/mixins/ui-full-width-view';

export default class InstanceCampaignsIndexCampaignEditRoute extends Route.extend(FullWidthMixin) {
  @service store;

  renderTemplate() {
    this.render('instance/campaigns/index/campaign/edit', {
      into: 'application'
    });
  }

  model() {
    return this.modelFor('instance.campaigns.index.campaign');
  }

  resetController() {
    super.resetController(...arguments);

    this.store.unloadAll('style-block');
    this.store.unloadAll('campaign-block');
  }
}
