import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! TODO: Could make header-list-item use list-item }}\n\n{{#if @isLoading}}\n  <tr>\n    <td colspan=\"3\" class=\"pa0\">\n      <UiSkeleton @items={{1}} @itemsPerRow={{1}} @cardHeight={{56}} @gap={{0}} />\n    </td>\n  </tr>\n{{else}}\n  <tr class=\"secondary bb b--secondary-10 list-item\">\n    <td class=\"pl3 pv2\">\n      <div class=\"flex items-center\">\n        {{@title}}\n      </div>\n    </td>\n    <td class=\"dn dt-sm\"></td>\n    <td class=\"pr3 pv2\">\n      <div class=\"flex items-center\">\n        {{or @actionColumnLabel (t \"routes.campaigns.sendCampaign.action\")}}\n      </div>\n    </td>\n  </tr>\n{{/if}}", {"contents":"{{! TODO: Could make header-list-item use list-item }}\n\n{{#if @isLoading}}\n  <tr>\n    <td colspan=\"3\" class=\"pa0\">\n      <UiSkeleton @items={{1}} @itemsPerRow={{1}} @cardHeight={{56}} @gap={{0}} />\n    </td>\n  </tr>\n{{else}}\n  <tr class=\"secondary bb b--secondary-10 list-item\">\n    <td class=\"pl3 pv2\">\n      <div class=\"flex items-center\">\n        {{@title}}\n      </div>\n    </td>\n    <td class=\"dn dt-sm\"></td>\n    <td class=\"pr3 pv2\">\n      <div class=\"flex items-center\">\n        {{or @actionColumnLabel (t \"routes.campaigns.sendCampaign.action\")}}\n      </div>\n    </td>\n  </tr>\n{{/if}}","moduleName":"additive-newsletter/components/anl-campaigns/send-dialog/header-list-item.hbs","parseOptions":{"srcName":"additive-newsletter/components/anl-campaigns/send-dialog/header-list-item.hbs"}});
import Component from '@glimmer/component';
import { arg } from 'ember-arg-types';
import { string } from 'prop-types';

export default class HeaderListItem extends Component {
  /**
   * The title of the HeaderListItem
   *
   * @argument title
   * @type {String}
   * @default null
   */
  @arg(string)
  title = null;

  /**
   * The label of the action column
   *
   * @argument actionColumnLabel
   * @type {String}
   * @default null
   */
  @arg(string)
  actionColumnLabel = null;
}
