import ENV from 'additive-newsletter/config/environment';
import { Response } from 'miragejs';

/*
  TODO: Add this to an addon, or as test-support of additive-utils
*/

// https://github.com/additive-apps/additive-utils/blob/master/addon/services/localization.js
export const Countries = (server) => {
  server.get(`${ENV.APP.localizationApiBaseHost}/api/countries`, () => {
    return new Response(
      200,
      {},
      {
        countries: [
          { code: 'IT', name: 'Italien' },
          { code: 'DE', name: 'Deutschland' },
          { code: 'AT', name: '\u00d6sterreich' },
          { code: 'CH', name: 'Schweiz' }
        ]
      }
    );
  });
};
