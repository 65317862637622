import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default Route.extend({
  store: service(),
  uiFilter: service(),

  setupController() {
    this.uiFilter.register('reports', 'report', {
      paginated: true
    });
  },
  resetController() {
    this.uiFilter.unregister('reports');
  }
});
