import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class InstanceStylesIndexRoute extends Route {
  @service store;

  setupController(controller) {
    super.setupController(...arguments);

    controller.fetchLayouts.perform();
  }

  resetController() {
    this.store.unloadAll('style');
    this.store.unloadAll('style-block');

    super.resetController(...arguments);
  }
}
