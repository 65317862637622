import Controller from '@ember/controller';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';

import { BLOCK_GROUPS_V2, ACCEPT_HEADER } from 'additive-newsletter/utils/constants';
import { STYLE_PREVIEW_BLOCKS } from 'additive-newsletter/utils/block-template-utils';

export default class InstanceStylesIndexStyleController extends Controller {
  @service anlCd;
  @service aeSaveStack;
  @service authenticatedFetch;
  @service currentUser;
  @service intl;
  @service router;
  @service store;
  @service uiToast;

  @tracked
  showDiscardChanges = false;

  @tracked
  isFullTemplate = false;

  @tracked
  blockGroups = [];

  @tracked
  styleBlocks = [];

  @tracked
  templateBlocks = [];

  @computed('router.currentRouteName')
  get isVariationSettings() {
    return this.router.isActive('instance.styles.index.style.block.variation');
  }

  @computed('router.currentRoute.attributes.variation.id')
  get activeVariation() {
    return this.router.currentRoute.attributes?.variation;
  }

  @action
  async save() {
    try {
      const responses = await this.aeSaveStack.save();
      // if only the style model was saved we need to reload the blocks
      if (responses?.length === 1 && responses.find((r) => r.constructor?.modelName === 'style')) {
        await this.store.findAll('style-block', {
          adapterOptions: { style: this.model.id }
        });
      }

      this.uiToast.showToast({
        type: 'success',
        title: this.intl.t('global.toast.success.savedChanges'),
        parent: '.aw-configurator__preview'
      });
    } catch (e) {
      if (e?.length) {
        const error = e[0];

        // transition to route with the first error
        this.router.transitionTo(
          'instance.styles.index.style.block.variation',
          error.block_identifier,
          error.variation_identifier
        );
      }

      this.uiToast.showToast({
        title: this.intl.t('global.toast.error.savedChanges'),
        type: 'error',
        parent: '.aw-configurator__preview'
      });
    }
  }

  @action
  back() {
    if (this.aeSaveStack.isDirty) {
      this.showDiscardChanges = true;
      return;
    }
    this.router.transitionTo('instance.styles.index');
  }

  @action
  discardChanges() {
    this.aeSaveStack.discardChanges();
    this.router.transitionTo('instance.styles.index');
    this.showDiscardChanges = false;
  }

  @action
  setup() {
    this.aeSaveStack.batchModels = ['style-block'];
    this.aeSaveStack.requestOptions = {
      adapterOptions: { style: this.model.id },
      headers: ACCEPT_HEADER
    };

    return Promise.all([this.anlCd.fetchCorporateDesign.perform(), this.fetchVariants.perform()]);
  }

  @task(function* () {
    let styleBlocks = yield this.store.findAll('style-block', {
      adapterOptions: { style: this.model.id }
    });

    // hack to make sure only style blocks of the current style are loaded
    let hasDuplicates = false;
    const aggregator = [];
    styleBlocks.toArray().some((block) => {
      if (aggregator.includes(`${block.blockIdentifier}-${block.variationIdentifier}`)) {
        hasDuplicates = true;
        return;
      }

      aggregator.push(`${block.blockIdentifier}-${block.variationIdentifier}`);
    });

    if (hasDuplicates) {
      this.store.unloadAll('style-block');
      styleBlocks = yield this.store.findAll('style-block', {
        adapterOptions: { style: this.model.id }
      });
    }
    this.styleBlocks = styleBlocks;

    const blockGroups = [];
    BLOCK_GROUPS_V2.forEach((group) => {
      const groupBlocks = [];
      group.forEach((block) => {
        const blockVariant = this.styleBlocks.find((v) => v.blockIdentifier === block);
        if (blockVariant) {
          groupBlocks.push(blockVariant);
        }
      });
      blockGroups.push(groupBlocks);
    });
    this.blockGroups = blockGroups;

    this.templateBlocks = STYLE_PREVIEW_BLOCKS.map((block) => {
      const [b, v] = block.split('.');
      return this.styleBlocks.filterBy('blockIdentifier', b).findBy('variationIdentifier', v);
    }).filter((b) => b);
  })
  fetchVariants;
}
