import Route from '@ember/routing/route';

export default Route.extend({
  model() {
    const addressBook = this._super(...arguments);

    const group = this.store.createRecord('group');

    return { addressBook, group };
  }
});
