import Model, { attr } from '@ember-data/model';

export default class StyleBlockModel extends Model {
  @attr('string') blockName;
  @attr('string') name;
  @attr('string') blockIdentifier;
  @attr('string') variationIdentifier;
  @attr('number') variationPosition;
  @attr('boolean') active;

  @attr() style;
  @attr() content;
}
