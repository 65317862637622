import { Factory, trait } from 'ember-cli-mirage';
import faker from 'faker';

import { CAMPAIGN_STATES } from 'additive-newsletter/utils/constants';

export default Factory.extend({
  createdAt: () => faker.date.recent(),
  isTimeShifted: false,
  name: () => faker.lorem.word(),
  resend: false,
  sender: () => faker.internet.exampleEmail(),
  senderName: () => faker.company.companyName(),
  status: () => faker.random.arrayElement(Object.values(CAMPAIGN_STATES)),
  subject: () => faker.lorem.words(),
  resendTime: '',
  subscriberCount: () => faker.random.number(),
  trackCampaignName: () => faker.lorem.word(),
  trackClicks: () => faker.random.boolean(),
  trackGoogleAnalytics: () => faker.random.boolean(),
  trackOpenings: () => faker.random.boolean(),
  updatedAt: () => faker.date.recent(),
  version: 2,

  draft: trait({
    status: CAMPAIGN_STATES.DRAFT
  }),

  failedNoSubscribers: trait({
    status: CAMPAIGN_STATES.SENDING_FAILED_NO_SUBSCRIBERS
  }),

  failedNoValidEmail: trait({
    status: CAMPAIGN_STATES.SENDING_FAILED_NO_VALID_EMAIL
  }),

  sent: trait({
    status: CAMPAIGN_STATES.SENT
  }),

  aborted: trait({
    status: CAMPAIGN_STATES.SENDING_ABORTED
  }),

  resent: trait({
    status: CAMPAIGN_STATES.RESENT
  }),

  scheduled: trait({
    status: CAMPAIGN_STATES.SCHEDULED
  })
});
