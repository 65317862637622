import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default Route.extend({
  uiFilter: service(),

  setupController() {
    this.uiFilter.register('campaigns', 'campaign', {
      sort: 'status,-sentAt,-createdAt'
    });
  },

  resetController() {
    this.uiFilter.unregister('campaigns');
  }
});
