import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<UiCategoryCard\n  @onClick={{fn @onClick this.style}}\n  @category={{this.style}}\n  @isReadOnly={{true}}\n  @isSelected={{this.isSelected}}\n  as |card|\n>\n  <card.header as |header|>\n    <header.content as |content|>\n      <content.image @url={{this.style.image.url}} />\n    </header.content>\n  </card.header>\n\n  <card.footer as |footer|>\n    <footer.content as |content|>\n      <content.default @title={{this.style.name}} />\n    </footer.content>\n  </card.footer>\n</UiCategoryCard>", {"contents":"<UiCategoryCard\n  @onClick={{fn @onClick this.style}}\n  @category={{this.style}}\n  @isReadOnly={{true}}\n  @isSelected={{this.isSelected}}\n  as |card|\n>\n  <card.header as |header|>\n    <header.content as |content|>\n      <content.image @url={{this.style.image.url}} />\n    </header.content>\n  </card.header>\n\n  <card.footer as |footer|>\n    <footer.content as |content|>\n      <content.default @title={{this.style.name}} />\n    </footer.content>\n  </card.footer>\n</UiCategoryCard>","moduleName":"additive-newsletter/components/anl-campaigns/card.hbs","parseOptions":{"srcName":"additive-newsletter/components/anl-campaigns/card.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { arg } from 'ember-arg-types';
import { object, func, bool } from 'prop-types';

export default class AnlCampaignsCardComponent extends Component {
  @arg(object)
  @tracked
  changeset = null;

  @arg(bool)
  @tracked
  isSelected = false;

  @arg(object)
  @tracked
  style = null;

  @arg(func)
  onClick = () => {};
}
