import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class InstanceTemplatesIndexTemplateRoute extends Route {
  @service store;
  @service uiAppSettings;

  renderTemplate() {
    this.render('instance/templates/index/template', {
      into: 'instance/templates',
      outlet: 'detail'
    });
  }

  model(params) {
    return params.template_id;
  }

  setupController(controller) {
    super.setupController(...arguments);

    controller.fetchTemplate.perform();
    controller.setup();
  }

  resetController(controller) {
    super.resetController(...arguments);

    this.uiAppSettings.setLocale(this.uiAppSettings.languages.defaultLanguage);
    controller.tearDown();
  }
}
