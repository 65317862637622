import {
  ACCEPT_HEADER,
  CAMPAIGN_STATES,
  STORE_EVENTS_KEY_DRAFT_CAMPAIGNS,
  STORE_EVENTS_KEY_PENDING_CAMPAIGNS,
  STORE_EVENTS_KEY_FAILED_CAMPAIGNS
} from 'additive-newsletter/utils/constants';

import { task } from 'ember-concurrency';
import { defineProperty } from '@ember/object';

export const abortCampaign = {};

/**
 * aborts the active campaign
 *
 * @function _abortCampaign
 * @type {Task}
 */
const abortCampaignTask = task(function* (self) {
  try {
    const resendAbort = self.model.status === CAMPAIGN_STATES.RESENDING;
    const response = yield self.authenticatedFetch.fetch(
      `${self._apiBaseUrl}/${resendAbort ? 'abort-resend' : 'abort'}`,
      {
        method: 'POST',
        headers: ACCEPT_HEADER
      }
    );
    if (!response || !response.ok) {
      throw new Error();
    }

    if (response.status !== 204) {
      const campaign = yield response.json();
      self.store.pushPayload('campaign', campaign);
      self.uiStoreEvents.didChange('campaigns');
    }

    self.uiStoreEvents.updateByKey(STORE_EVENTS_KEY_DRAFT_CAMPAIGNS);
    self.uiStoreEvents.updateByKey(STORE_EVENTS_KEY_PENDING_CAMPAIGNS);
    self.uiStoreEvents.updateByKey(STORE_EVENTS_KEY_FAILED_CAMPAIGNS);

    self.uiToast.showToast({
      type: 'success',
      title: self.intl.t('global.toast.success.savedChanges'),
      duration: 2000
    });
  } catch (e) {
    self.uiToast.showToast({
      type: 'error',
      title: self.intl.t('global.toast.error.savedChanges'),
      duration: 2000
    });
  }
});

defineProperty(abortCampaign, 'task', abortCampaignTask);

export const retryCampaign = {};

const retryCampaignTask = task(function* (self) {
  try {
    const response = yield self.authenticatedFetch.fetch(`${self._apiBaseUrl}/retry`, {
      method: 'POST',
      headers: ACCEPT_HEADER
    });

    if (!response || !response.ok) {
      throw new Error();
    }

    const campaign = yield response.json();
    self.store.pushPayload('campaign', campaign);

    self.uiStoreEvents.didChange('campaigns');

    self.uiToast.showToast({
      type: 'success',
      title: self.intl.t('global.toast.success.savedChanges')
    });
  } catch (e) {
    self.uiToast.showToast({
      type: 'error',
      title: self.intl.t('global.toast.error.savedChanges')
    });
  }
}).drop();

defineProperty(retryCampaign, 'task', retryCampaignTask);
