import ENV from 'additive-newsletter/config/environment';
import Controller from '@ember/controller';

import { isArray } from '@ember/array';
import { action } from '@ember/object';
import { all, task, timeout } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { htmlSafe } from '@ember/string';
import { isPresent } from '@ember/utils';
import Changeset from 'ember-changeset';

import { tracked } from '@glimmer/tracking';

import { validatePresence } from 'ember-changeset-validations/validators';
import lookupValidator from 'ember-changeset-validations';

import cloneDeep from 'lodash.clonedeep';
import merge from 'lodash.merge';

import { loadFont } from '@additive-apps/utils/utils/fonts';
import { ACCEPT_HEADER } from 'additive-newsletter/utils/constants';
import { getFontOptions } from 'additive-newsletter/utils/constants/fonts';
import {
  defaultTexts,
  getColorOptions,
  StyleValues
} from 'additive-newsletter/utils/constants/widget-default-values';

const DEFAULT_INSTANCE = 'testhotel-post-com';

const options = {
  selectorId: 'newsletter-preview',
  instance: DEFAULT_INSTANCE,
  locale: 'de',
  config: {
    typography: {
      embedFonts: false,
      names: {
        primary: {
          normal: '',
          light: '',
          bold: ''
        },
        secondary: {
          normal: '',
          light: '',
          bold: ''
        }
      }
    }
  },
  texts: {},
  tracking: {}
};

export default class InstanceWidgetsWidgetController extends Controller {
  @service authenticatedFetch;
  @service currentUser;
  @service intl;
  @service router;
  @service store;
  @service uiAppSettings;
  @service uiDialog;
  @service uiState;
  @service uiToast;

  /**
   * the changeset for the widget
   *
   * @argument changeset
   * @type {Object}
   */
  @tracked
  changeset = null;

  /**
   * the color options for the select
   *
   * @argument colorOptions
   * @type {Array}
   */
  @tracked
  colorOptions = null;

  /**
   * The typography defined in the corporate design
   *
   * @property corporateDesignTypography
   * @type {Object}
   * @default null
   */
  @tracked
  corporateDesignTypography = null;

  /**
   * the font options for the select
   *
   * @argument fontOptions
   * @type {Array}
   */
  @tracked
  fontOptions = null;

  /**
   * the fonts of the organizations corporate design
   *
   * @argument fonts
   * @type {Array}
   */
  @tracked
  fonts = null;

  /**
   * the widget instance
   *
   * @argument instance
   * @type {Object}
   */
  @tracked
  instance = null;

  /**
   * the options of the widget
   *
   * @argument options
   * @type {Object}
   */
  @tracked
  options = null;

  /**
   * the background color of the widget
   *
   * @argument previewBackgroundColor
   * @type {String}
   */
  @tracked
  previewBackgroundColor = null;

  @tracked
  previousPreviewBackgroundColor = null;

  /**
   * the styles of the widget
   *
   * @argument styles
   * @type {Object}
   */
  @tracked
  styles = null;

  /**
   * the widget texts
   *
   * @argument texts
   * @type {Object}
   */
  @tracked
  texts = null;

  /**
   * the widget model
   *
   * @argument model
   * @type {Object}
   */
  @tracked
  model = null;

  /**
   * the data of the model filled with default values
   *
   * @argument modelData
   * @type {Object}
   */
  @tracked
  modelData = null;

  @tracked
  activeTab = null;

  @tracked
  tabs = [
    { title: this.intl.t('configurator.general.title'), name: 'general' },
    { title: this.intl.t('configurator.contents.title'), name: 'contents' },
    { title: this.intl.t('configurator.colors.title'), name: 'colors' },
    { title: this.intl.t('configurator.typography.title'), name: 'typography' },
    { title: this.intl.t('configurator.tracking.title'), name: 'tracking' }
  ];

  @alias('currentUser.currentOrganization.id') organizationSlug;
  @alias('uiAppSettings.languages.contentLanguages') languages;

  /**
   * whether the data is being fetched
   *
   * @computed isLoading
   * @type {Boolean}
   */
  @alias('setup.isRunning') isLoading;

  get previewBackgroundStyle() {
    return htmlSafe(`background-color: ${this.previewBackgroundColor}`);
  }

  get _isViewer() {
    return this.currentUser.isViewer;
  }

  /**
   * saves the changes and creates a new changeset
   */
  @task(function* () {
    try {
      const { changeset } = this;

      yield changeset.validate();
      if (!changeset.isValid) {
        if (this.activeTab.name !== 'contents') {
          this.activeTab = this.tabs[1];
          this.uiToast.showToast({
            title: this.intl.t('configurator.contents.errorToast.title'),
            description: this.intl.t('configurator.contents.errorToast.description'),
            type: 'error'
          });
        }
        return;
      }

      const changesetChanges = changeset.get('changes');
      const changesObject = {};

      /*
       * key in changes of changeset may be a concatenated string of keys
       * so we need to create a nested object of it
       */
      changesetChanges.forEach((changedProperty) => {
        const splittedPropertyKey = changedProperty.key.split('.');
        const lastKey = splittedPropertyKey.pop();

        let lastObjectLeaf = splittedPropertyKey.reduce(
          (obj, key) => (obj[key] = obj[key] || {}),
          changesObject
        );
        lastObjectLeaf[lastKey] = changedProperty.value;
      });

      let changedKeys = [];
      Object.keys(changesObject).forEach((propertyKey) => {
        if (
          typeof changesObject[propertyKey] === 'object' &&
          isPresent(changesObject[propertyKey])
        ) {
          this._getChangedPropertyKeys(
            changesObject[propertyKey],
            this.modelData[propertyKey],
            changedKeys,
            propertyKey
          );
          return;
        }
        changedKeys.push(propertyKey);
      });

      // update color and contrastColor of custom colors if
      ['error'].forEach((colorKey) => {
        const colorPrefix = `styles.colors.${colorKey}`;
        if (
          changedKeys.indexOf(`${colorPrefix}.color`) !== -1 ||
          changedKeys.indexOf(`${colorPrefix}.contrastColor`) !== -1
        ) {
          if (changedKeys.indexOf(`${colorPrefix}.color`) < 0) {
            changedKeys.push(`${colorPrefix}.color`);
          }

          if (changedKeys.indexOf(`${colorPrefix}.contrastColor`) < 0) {
            changedKeys.push(`${colorPrefix}.contrastColor`);
          }
        }
      });

      // apply changes to model
      changedKeys.forEach((changedKey) => {
        const splittedKey = changedKey.split('.');

        // get new value of the changed key
        const newValue = splittedKey.reduce((object, key) => object[key], changesObject);

        // initialize key at model if it not exists
        let alreadyIntialized = '';
        splittedKey.forEach((keyPart) => {
          let key = alreadyIntialized ? `${alreadyIntialized}${keyPart}` : keyPart;
          if (!this.model.get(key)) {
            this.model.set(key, {});
          }
          alreadyIntialized = `${alreadyIntialized}${keyPart}.`;
        });

        this.model.set(changedKey, newValue);
      });

      let tasks = [];

      tasks.push(this.model.save());
      tasks.push(timeout(500));

      yield all(tasks);

      this._setupChangeset();
      this.uiToast.showToast({
        title: this.intl.t('global.toast.success.savedChanges'),
        type: 'success',
        parent: '.aw-configurator__preview'
      });
    } catch (e) {
      this.uiToast.showToast({
        title: this.intl.t('global.toast.error.savedChanges'),
        type: 'error',
        parent: '.aw-configurator__preview'
      });
    }
  })
  save;

  @task(function* () {
    try {
      this.activeTab = this.tabs[0];

      let tasks = [];
      // load corporate design definition
      const request = this.authenticatedFetch.fetch(
        `${ENV.APP.apiBaseHost}/${this.organizationSlug}/corporate-design`,
        {
          headers: ACCEPT_HEADER
        }
      );

      tasks.push(request);
      tasks.push(timeout(500));

      const [response] = yield all(tasks);
      if (!response || !response.ok) {
        // handle widget teardown
        return;
      }

      const { corporateDesign } = yield response.json();
      this.corporateDesignTypography = corporateDesign.typography;

      if (corporateDesign.fonts) {
        yield this.loadFonts(corporateDesign.fonts);
      }

      let { fonts } = corporateDesign;
      this.fonts = fonts;
      this.fontOptions = getFontOptions(fonts);

      let colors = {};
      Object.keys(this.model.get('styles.colors')).forEach((colorKey) => {
        if (['main', 'accent', 'ambient', 'error'].indexOf(colorKey) === -1) {
          return;
        }

        const modelColor = cloneDeep(this.model.get(`styles.colors.${colorKey}`));

        const colorObject = Object.assign({}, modelColor, {
          color: modelColor.color || modelColor.syncedColor,
          contrastColor: modelColor.contrastColor || modelColor.syncedContrastColor
        });
        colors[colorKey] = colorObject;
      });
      this.model.set('styles.colors', colors);
      this.colorOptions = getColorOptions(corporateDesign.colors);

      let typography = {};
      Object.keys(this.model.get('styles.typography')).forEach((typographyKey) => {
        const modelTypography = cloneDeep(this.model.get(`styles.typography.${typographyKey}`));

        const typographyObject = Object.assign({}, modelTypography, {
          fontFamily: modelTypography.fontFamily || modelTypography.syncedFontFamily,
          fontSize: modelTypography.fontSize || modelTypography.syncedFontSize,
          lineHeight: modelTypography.lineHeight || modelTypography.syncedLineHeight,
          color: modelTypography.color || modelTypography.syncedColor,
          serif: modelTypography.serif || modelTypography.syncedSerif
        });
        typography[typographyKey] = typographyObject;
      });
      this.model.set('styles.typography', typography);

      if (!this.model.get('texts') || isArray(this.model.get('texts'))) {
        this.model.set('texts', {});
      }

      this._setupChangeset();

      const widget = yield import('@additive-apps/newsletter-widget');
      this.instance = widget;
    } catch (error) {
      return;
    }
  })
  setup;

  loadFonts(fonts) {
    const fontPromises = [];
    Object.keys(fonts).forEach((key) => {
      Object.keys(fonts[key]).forEach((type) => {
        fonts[key][type] && fontPromises.push(loadFont(`${key}${type}`, fonts[key][type].url));
      });
    });
    Promise.all(fontPromises).catch((error) => {
      throw new Error('[WIDGET CONFIGURATOR] Could not load font', error);
    });

    return Promise.allSettled(fontPromises);
  }

  /**
   * compares two objects and returns the keys of the properties that changed
   *
   * @param {*} data the object which may have changed properties
   * @param {*} compare the object without changes
   * @param {*} changedKeys an array where the keys of the changed properties are pushed
   * @param {*} keyPrefix the concatenated keys to keep track on recursive calls
   *
   * @function _getChangedPropertyKeys
   */
  _getChangedPropertyKeys(data, compare, changedKeys, keyPrefix) {
    Object.keys(data).forEach((propertyKey) => {
      const dataProperty = data[propertyKey];
      const compareProperty = compare && compare[propertyKey];

      if (typeof dataProperty === 'object' && isPresent(dataProperty)) {
        this._getChangedPropertyKeys(
          dataProperty,
          compareProperty,
          changedKeys,
          `${keyPrefix}.${propertyKey}`
        );
        return;
      }

      if (data[propertyKey] !== null && dataProperty !== compareProperty) {
        changedKeys.push(`${keyPrefix}.${propertyKey}`);
      }
    });
  }

  /**
   * setup changeset for the model and
   *
   * @function _setupChangeset
   */
  _setupChangeset() {
    // deep clone model data and fill with defaults
    const modelData = cloneDeep(this.model.serialize({ includeId: true }));
    const modelStyles = modelData && modelData.styles;
    const validation = {};
    const presenceMessage = this.intl.t('errors.required');

    // fill model styles with default values and values from corporate design
    let styles = merge(
      {},
      {
        colors: StyleValues.colors,
        typography: StyleValues.typography,
        globals: StyleValues.globals
      },
      {
        colors: modelStyles.colors,
        typography: modelStyles.typography,
        globals: modelStyles.globals
      }
    );
    this.styles = styles;

    modelData.styles = cloneDeep(styles);

    if (isArray(modelData.texts)) {
      modelData.texts = {};
    }
    this.languages.forEach((language) => {
      modelData.texts[language] = merge({}, defaultTexts[language], modelData.texts[language]);

      validation[`texts.${language}.ctaButtonText`] = validatePresence({
        presence: true,
        message: presenceMessage
      });
    });

    this.modelData = modelData;

    const changeset = new Changeset(cloneDeep(modelData), lookupValidator(validation), validation);
    this.changeset = changeset;

    /* Initialize background-color of widget preview */
    const { sync, color, syncedColor } = styles.colors.ambient;
    if (sync) {
      this.previewBackgroundColor = syncedColor;
    } else {
      this.previewBackgroundColor = color;
    }
    this.previousPreviewBackgroundColor = color;

    const config = changeset.get('config');
    const texts = changeset.get('texts');
    const tracking = changeset.get('tracking');
    this.model.set('tracking', tracking);

    this.texts = texts;
    this.options = merge({}, options, {
      config,
      texts: texts.de,
      type: this.model.type,
      tracking: tracking
    });
  }

  _back() {
    this.router.transitionTo('instance.styles.widgets');
    this.changeset = null;
  }

  @action
  back() {
    if (this.changeset.get('isDirty')) {
      this.uiDialog.showDiscardChangesConfirm(() => {
        this.changeset.rollback();
        this.model.rollbackAttributes();
        this._back();
      });
    } else {
      this._back();
    }
  }

  @action
  onUpdate(onUpdate, key, val, grouping = 'styles') {
    /* We use the ambient color as background color of the widget preview */
    if (key === 'colors.ambient') {
      if (val.sync) {
        /* Use synced color */
        this.previewBackgroundColor = val.syncedColor || this.styles.colors.ambient.syncedColor;
        this.previousPreviewBackgroundColor = this.previewBackgroundColor;
      } else {
        if (val.color) {
          /* Use custom color */
          this.previewBackgroundColor = val.color;
          this.previousPreviewBackgroundColor = val.color;
        } else {
          /* Sync was deactivated, use previous background-color */
          this.previewBackgroundColor = this.previousPreviewBackgroundColor;
        }
      }
    }

    const data = onUpdate(key, val, grouping);

    this.changeset.set('options', data.options);
    this.changeset.set('styles', data.styles);
  }

  @action
  toggleDetail() {
    this.uiState.getState('widget-detail').toggle();
  }

  @action
  onChangeGeneralSettings(key, value) {
    this.changeset.set(key, value);
  }
}
