import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class SubscribersRoute extends Route {
  @service store;

  renderTemplate() {
    this.render('instance/address-books/address-book/subscribers/subscriber', {
      into: 'instance/address-books/address-book',
      outlet: 'detail'
    });
  }

  model(params) {
    const addressBook = this.modelFor('instance.address-books.address-book');
    return { addressBook, subscriberId: params.subscriber_id };
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    controller.fetchSubscriber.perform();
    controller.fetchAddressBooks.perform();
  }
}
