import ENV from 'additive-newsletter/config/environment';

import Controller from '@ember/controller';
import { set, computed } from '@ember/object';
import { alias, or } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { task, timeout, all } from 'ember-concurrency';

import { ACCEPT_HEADER } from 'additive-newsletter/utils/constants';
import { filterSettingsApps } from 'additive-newsletter/utils/settings-utils';

export default Controller.extend({
  authenticatedFetch: service(),
  currentUser: service(),
  intl: service(),
  uiToast: service(),
  uiAppSettings: service(),

  /**
   * the fetched app settings
   *
   * @argument appsSettings
   * @type {Object}
   * @default null
   */
  appsSettings: null,

  /**
   * the fetched general settings
   *
   * @argument generalSettings
   * @type {Object}
   * @default null
   */
  generalSettings: null,

  /**
   * whether an error occurred while fetching apps settings
   *
   * @argument isAppsSettingsError
   * @type {Boolean}
   * @default false
   */
  isAppsSettingsError: false,

  /**
   * whether an error occurred while fetching general settings
   *
   * @argument isGeneralSettingsError
   * @type {Boolean}
   * @default false
   */
  isGeneralSettingsError: false,

  /**
   * the current organization slug
   *
   * @computed organizationSlug
   * @type {String}
   */
  organizationSlug: alias('currentUser.currentOrganization.id'),

  /**
   * the current language of the app
   *
   * @computed language
   * @type {String}
   */
  language: alias('uiAppSettings.currentLocale'),

  /**
   * if true, an error occured while fetch the settings
   *
   * @computed isError
   * @type {Boolean}
   */
  isError: or('isAppsSettingsError', 'isGeneralSettingsError'),

  /**
   * if true, settings are currently being fetched
   *
   * @computed isLoading
   * @type {Boolean}
   */
  isLoading: or('fetchAppsSettings.isRunning', 'fetchGeneralSettings.isRunning'),

  /**
   * whether the app has the professional plan
   *
   * @computed isProfessionalPlan
   * @type {Boolean}
   */
  isProfessionalPlan: alias('uiAppSettings.isProfessionalPlan'),

  /**
   * the link to the account communication settings
   *
   * @computed accountSettingsLink
   * @type {String}
   */
  accountSettingsLink: computed('currentUser.currentOrganization.id', {
    get() {
      return `${ENV.APP.accountHost}/${this.organizationSlug}/settings/communication`;
    }
  }),

  /**
   * fetches the apps settings
   *
   * @function fetchAppsSettings
   * @type {Task}
   */
  fetchAppsSettings: task(function* () {
    set(this, 'isAppsSettingsError', false);
    const url = `${ENV.APP.apiBaseHost}/${this.organizationSlug}`;

    try {
      let tasks = [];

      const requestOptions = {
        headers: Object.assign({}, ACCEPT_HEADER, {
          'Accept-Language': this.language
        })
      };
      const request = this.authenticatedFetch.fetch(`${url}/settings/apps`, requestOptions);

      tasks.push(request);
      tasks.push(timeout(400));

      const [response] = yield all(tasks);

      if (!response || !response.ok) {
        throw new Error('[SETTINGS] app settings', response);
      }

      const json = yield response.json();
      if (!json || !json.apps) {
        throw new Error('[SETTINGS] app settings');
      }

      const filteredApps = filterSettingsApps(json.apps);

      const appsSettings = this.appsSettings || {};
      set(appsSettings, this.language, filteredApps);

      set(this, 'appsSettings', appsSettings);
    } catch (error) {
      set(this, 'isAppsSettingsError', true);
    }
  }).drop(),

  /**
   * fetches the general settings
   *
   * @function fetchGeneralSettings
   * @type {Task}
   */
  fetchGeneralSettings: task(function* () {
    set(this, 'isGeneralSettingsError', false);
    const url = `${ENV.APP.apiBaseHost}/${this.organizationSlug}`;

    try {
      let tasks = [];

      const requestOptions = {
        headers: ACCEPT_HEADER
      };
      const request = this.authenticatedFetch.fetch(`${url}/settings/general`, requestOptions);

      tasks.push(request);
      tasks.push(timeout(400));

      const [response] = yield all(tasks);
      if (!response || !response.ok) {
        throw new Error('[SETTINGS] general settings', response);
      }

      const json = yield response.json();

      if (!json || !json.general) {
        throw new Error('[SETTINGS] general settings');
      }

      set(this, 'generalSettings', json.general);
    } catch (error) {
      set(this, 'isGeneralSettingsError', true);
    }
  }).drop()
});
