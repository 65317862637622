import Controller from '@ember/controller';
import { action, computed } from '@ember/object';
import { equal } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { all, task, timeout } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

export default class InstanceTemplatesIndex extends Controller {
  @service router;
  @service store;
  @service uiFilter;

  @tracked
  templates = null;

  @tracked
  isError = false;

  @tracked
  styles = null;

  @tracked
  activeStyle = null;

  @equal('templates.length', 0) _isEmpty;

  @computed('_isEmpty', 'isError')
  get showEmptyView() {
    return this._isEmpty && !this.isError;
  }

  @task(function* () {
    this.isError = false;
    try {
      let tasks = [];

      tasks.push(this.store.findAll('style'));
      tasks.push(timeout(400));

      let [styles] = yield all(tasks);
      this.styles = styles;
    } catch (e) {
      this.isError = true;
      throw new Error();
    }
  })
  fetchStyles;

  @action
  onDelete(id) {
    this.uiFilter.reset('templates');
    if (this.router.isActive('instance.templates.index.template.index', id)) {
      return this.router.transitionTo('instance.templates.index');
    }
  }
}
