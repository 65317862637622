import { Factory } from 'ember-cli-mirage';
import faker from 'faker';

export default Factory.extend({
  name: () => faker.lorem.word(),
  blockName: () => faker.lorem.word(),
  blockIdentifier: () =>
    faker.random.arrayElement(['header', 'footer', 'text', 'button', 'menu', 'cta']),
  content: () => [
    {
      id: 'contents',
      properties: [
        {
          id: 'title',
          name: 'Titel',
          type: 'string',
          value: 'Platzhalter Titel'
        },
        {
          id: 'text',
          name: 'Text',
          type: 'string',
          value: ''
        },
        {
          id: 'link',
          name: 'Link',
          type: 'link',
          value: {
            text: 'link',
            url: 'additive.eu'
          }
        }
      ]
    }
  ],
  style: () => [
    {
      id: 'layout',
      properties: [
        {
          id: 'alignment',
          name: 'Ausrichtung',
          type: 'enum',
          value: 'left',
          allowedValues: ['left', 'center', 'right']
        }
      ]
    },
    {
      id: 'padding',
      name: 'Abstände',
      properties: [
        {
          id: 'paddingTop',
          name: 'Abstand oben',
          type: 'number',
          value: 32
        },
        {
          id: 'paddingBottom',
          name: 'Abstand unter',
          type: 'number',
          value: 32
        }
      ]
    },
    {
      id: 'border',
      name: 'Rahmen',
      properties: [
        {
          id: 'borderRadius',
          name: 'Abrundung',
          type: 'number',
          value: 2,
          sync: true,
          syncedValue: 2
        }
      ]
    },
    {
      id: 'background',
      name: 'Hintergrund',
      properties: [
        {
          id: 'backgroundColor',
          name: 'Hintergrundfarbe',
          type: 'color',
          value: faker.random.arrayElement(['red', 'green', 'yellow', 'blue', 'violet']),
          sync: false,
          syncedValue: 'ambient'
        }
      ]
    },
    {
      id: 'corporate-design',
      name: 'CD',
      properties: [
        {
          id: 'typography',
          name: 'Schrift',
          type: 'typography',
          value: {
            fontFamily: 'primary.normal',
            fontSize: '14px',
            lineHeight: '20px',
            weight: 'normal'
          },
          sync: true,
          syncedValue: 'h1'
        },
        {
          id: 'typography',
          name: 'Schrift',
          type: 'typography',
          sync: true,
          syncedValue: 'h1'
        },
        {
          id: 'typographyColor',
          name: 'Farbe',
          type: 'typography_color',
          value: 'main',
          sync: true,
          syncedValue: 'ambient'
        }
      ]
    }
  ],

  afterCreate(model) {
    const variationPosition = Math.floor(Math.random() * 3);
    model.update({
      variationIdentifier: `${model.blockIdentifier}-${variationPosition}`,
      variationPosition: variationPosition
    });
  }
});
