import Application from '@ember/application';
import Resolver from 'ember-resolver';
import loadInitializers from 'ember-load-initializers';
import config from 'additive-newsletter/config/environment';

export default class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = Resolver;
  engines = {
    '@additiveApps/multimediaEngine': {
      dependencies: {
        services: ['authenticatedFetch', 'currentUser', 'intl', 'store', 'multimedia', 'router']
      }
    }
  };
}

loadInitializers(App, config.modulePrefix);
