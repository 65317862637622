export const CHART_COLORS = [
  { borderColor: '#4D76F1', backgroundColor: '#4D76F1', pointBorderColor: 'white' },
  { borderColor: '#f3a100', backgroundColor: '#f3a100', pointBorderColor: 'white' },
  { borderColor: '#f6374e', backgroundColor: '#f6374e', pointBorderColor: 'white' },
  { borderColor: '#8f9ea4', backgroundColor: '#8f9ea4', pointBorderColor: 'white' }
];

export const CHART_OPTIONS = {
  scales: {
    x: {
      ticks: {
        callback: function (tick, index) {
          return index % 2 ? '' : this.getLabelForValue(tick);
        }
      }
    }
  }
};
