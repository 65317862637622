import ApplicationSerializer from './application';
import { RestSerializer, Collection } from 'miragejs';

export default ApplicationSerializer.extend({
  serialize(object) {
    let json = RestSerializer.prototype.serialize.apply(this, arguments);
    const [rootKey] = Object.keys(json);

    let models = json[rootKey];
    if (!(object instanceof Collection)) {
      models = [models];
    }

    models = models.map((campaign) => {
      return Object.assign(campaign, {
        resend: normalizeBoolean(campaign.resend),
        trackClicks: normalizeBoolean(campaign.trackClicks),
        trackGoogleAnalytics: normalizeBoolean(campaign.trackGoogleAnalytics),
        trackOpenings: normalizeBoolean(campaign.trackOpenings)
      });
    });

    return {
      [rootKey]: models
    };
  }
});

function normalizeBoolean(input) {
  if (typeof input === 'boolean') {
    return input;
  }
  if (input === 1) return true;
  else if (input === 0) return false;
}
