import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class InstanceMultimediaRoute extends Route {
  @service multimedia;

  activate() {
    this.multimedia.setSelectionOptions({ isAddable: false });
  }

  deactivate() {
    this.multimedia.resetSelectionOptions();
  }
}
