import {
  validatePresence,
  validateFormat,
  validateNumber,
  validateLength,
  validateInclusion
} from 'ember-changeset-validations/validators';
import { validateDateDependency } from '@additive-apps/utils/validators/date';
import { multipleEmailsValidator } from 'additive-newsletter/validators/test-email-validator';

export const senderValidation = (intl) => {
  const presenceMessage = intl.t('errors.required');
  const emailMessage = intl.t('errors.email');

  return {
    subject: validatePresence({ presence: true, message: presenceMessage }),
    senderEmail: [
      validatePresence({ presence: true, message: presenceMessage }),
      validateFormat({ type: 'email', message: emailMessage })
    ],
    replyTo: validateFormat({ type: 'email', message: emailMessage, allowBlank: true })
  };
};

export const campaignValidation = (intl) => {
  const presenceMessage = intl.t('errors.required');
  const missingIntegerMessage = intl.t('errors.numberInteger');
  const positiveNumberMessage = intl.t('errors.numberPositive');
  const emptyNewsletter = intl.t('routes.campaigns.sendCampaign.emailContent.error');

  return {
    'addressBook.name': validatePresence({
      presence: true,
      message: intl.t('routes.campaigns.sendCampaign.recipient.error')
    }),
    subscriberCount: validateNumber({
      gt: 0,
      message: intl.t('routes.campaigns.sendCampaign.recipient.noSubscriber')
    }),
    languages: validateLength({
      min: 1,
      message: intl.t('routes.campaigns.sendCampaign.recipient.noLanguage')
    }),
    subject: validatePresence({
      presence: true,
      message: intl.t('routes.campaigns.sendCampaign.subject.error')
    }),
    senderEmail: validatePresence({ presence: true }),
    contentBlocks: [
      validatePresence({ presence: true, message: emptyNewsletter }),
      validateLength({ min: 1, message: emptyNewsletter })
    ],
    resendAfterDays: conditionalValidator(
      [
        validatePresence({ presence: true, message: presenceMessage }),
        validateNumber({ multipleOf: 1, message: missingIntegerMessage }),
        validateNumber({ gt: 0, positive: true, message: positiveNumberMessage })
      ],
      (key, newValue, oldValue, changes) => changes.resend
    ),
    resendSubject: conditionalValidator(
      validatePresence({ presence: true }),
      (key, newValue, oldValue, changes) => changes.resend
    ),
    resend: conditionalValidator(
      validateInclusion({
        in: [false],
        message: intl.t('routes.campaigns.sendCampaign.resend.error.trackOpeningsRequired')
      }),
      (key, newValue, oldValue, changes) => !changes.trackOpenings
    )
  };
};

export const testSendValidation = (intl) => {
  const presenceMessage = intl.t('errors.required');
  const emailMessage = intl.t('errors.email');

  return {
    emails: multipleEmailsValidator({
      presence: true,
      presenceMessage: presenceMessage,
      emailMessage: emailMessage
    })
  };
};

export const createFromModelValidation = (intl) => {
  const presenceMessage = intl.t('errors.required');

  return {
    name: [validatePresence({ presence: true, message: presenceMessage })]
  };
};

export const timeShiftedValidation = (intl) => {
  const invalidDateMessage = intl.t('errors.futureDate');
  return {
    sendAt: [
      validateDateDependency({
        type: 'after',
        on: 'today',
        message: invalidDateMessage
      })
    ]
  };
};

/**
 * executes a validation if the condition is true and returns true otherwise
 *
 * @function conditionalValidator
 * @param {Array|Validator} validator
 * @param {Function} condition
 */
const conditionalValidator = (validator, condition) => {
  const executeValidation = (validator) => {
    return function (key, newValue, oldValue, changes, content) {
      // check if condition is true
      if (condition.call(key, newValue, oldValue, changes, content)) {
        return validator(key, newValue, oldValue, changes, content);
      }
      return true;
    };
  };

  if (Array.isArray(validator)) {
    return validator.map(executeValidation);
  } else {
    return executeValidation(validator);
  }
};
export const recipientValidation = (intl) => {
  const presenceMessage = intl.t('errors.required');

  return {
    addressBook: validatePresence({ presence: true, message: presenceMessage }),
    languages: validatePresence({ presence: true, message: presenceMessage })
  };
};

export const resendValidation = (intl) => {
  const presenceMessage = intl.t('errors.required');
  const missingIntegerMessage = intl.t('errors.numberInteger');
  const positiveNumberMessage = intl.t('errors.numberPositive');
  const invalidTime = intl.t('errors.invalidTime');

  return {
    resendAfterDays: [
      validatePresence({ presence: true, message: presenceMessage }),
      validateNumber({ multipleOf: 1, message: missingIntegerMessage }),
      validateNumber({ gt: 0, positive: true, message: positiveNumberMessage })
    ],
    resendSubject: validatePresence({ presence: true, message: presenceMessage }),
    resendTime: [
      validateFormat({
        allowBlank: true,
        regex: /^(([0-1][0-9])|(2[0-3])):([0-5][0-9])$/,
        message: invalidTime
      })
    ]
  };
};

export const resendEditValidation = (intl, date = 'today') => {
  const presenceMessage = intl.t('errors.required');
  const invalidDateMessage =
    date === 'today' ? intl.t('errors.futureDate') : intl.t('errors.dateAfterSend');

  return {
    resendSubject: validatePresence({ presence: true, message: presenceMessage }),
    resendAt: [
      validateDateDependency({
        type: 'after',
        on: date,
        message: invalidDateMessage
      })
    ]
  };
};

export const googleAnalyticsValidation = (intl) => {
  const message = intl.t('errors.trackingSpecialChars');

  return {
    trackCampaignName: [
      validatePresence({ presence: true, message: intl.t('errors.required') }),
      validateFormat({ regex: /^[a-zA-Z0-9_-]*$/, message })
    ]
  };
};

export const createCampaignValidation = (intl) => {
  const message = intl.t('errors.required');

  return {
    name: validatePresence({ presence: true, message })
  };
};
