import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<UiModal @title={{this.dialogTitle}} @size=\"md\" @onClose={{this.close}} as |modal|>\n  <modal.header />\n  <modal.content as |content|>\n    <content.body {{did-insert this.setup}}>\n      <UiInput\n        @label={{t \"components.anlWidgets.detailDialog.labels.name\"}}\n        @value={{this.changeset.name}}\n        @isTouched={{this.isTouched}}\n        @errorMessage={{get this.changeset.error.name \"validation\"}}\n      />\n\n      <UiInput\n        @label={{t \"components.anlWidgets.detailDialog.labels.description\"}}\n        @value={{this.changeset.description}}\n        @isTouched={{this.isTouched}}\n        @textarea={{true}}\n      />\n    </content.body>\n  </modal.content>\n\n  <modal.footer>\n    <UiButton @label={{t \"global.actions.save\"}} @isSecondary={{true}} @onClick={{this.save}} />\n  </modal.footer>\n</UiModal>", {"contents":"<UiModal @title={{this.dialogTitle}} @size=\"md\" @onClose={{this.close}} as |modal|>\n  <modal.header />\n  <modal.content as |content|>\n    <content.body {{did-insert this.setup}}>\n      <UiInput\n        @label={{t \"components.anlWidgets.detailDialog.labels.name\"}}\n        @value={{this.changeset.name}}\n        @isTouched={{this.isTouched}}\n        @errorMessage={{get this.changeset.error.name \"validation\"}}\n      />\n\n      <UiInput\n        @label={{t \"components.anlWidgets.detailDialog.labels.description\"}}\n        @value={{this.changeset.description}}\n        @isTouched={{this.isTouched}}\n        @textarea={{true}}\n      />\n    </content.body>\n  </modal.content>\n\n  <modal.footer>\n    <UiButton @label={{t \"global.actions.save\"}} @isSecondary={{true}} @onClick={{this.save}} />\n  </modal.footer>\n</UiModal>","moduleName":"additive-newsletter/components/anl-styles/detail-dialog.hbs","parseOptions":{"srcName":"additive-newsletter/components/anl-styles/detail-dialog.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { arg } from 'ember-arg-types';
import { func, bool } from 'prop-types';

import Changeset from 'ember-changeset';
import cloneDeep from 'lodash.clonedeep';
import lookupValidator from 'ember-changeset-validations';
import { styleValidation } from 'additive-newsletter/validations/style';
import { getAdapterError } from '@additive-apps/utils/utils/errors';

/**
 * Dialog that is shown when a user wants to create or edit a style
 *
 * @module anl-styles
 * @class AnlStylesDetailDialog
 */
export default class AnlStylesDetailDialog extends Component {
  @service authenticatedFetch;
  @service intl;
  @service uiDialog;
  @service uiToast;
  @service uiFilter;
  @service store;

  /**
   * the style resource
   *
   * @argument style
   * @type {Object}
   * @default null
   */
  @tracked
  style = null;

  /**
   * the changeset of the style
   *
   * @argument changeset
   * @type {Object}
   * @default null
   */
  @tracked
  changeset = null;

  /**
   * whether the form is touched
   *
   * @argument isTouched
   * @type {Boolean}
   * @default false
   */
  @tracked
  isTouched = false;

  /**
   * whether the dialog is a edit dialog
   *
   * @argument isEditDialog
   * @type {Boolean}
   * @default false
   */
  @arg(bool)
  @tracked
  isEditDialog = false;

  get dialogTitle() {
    const intlText = this.isEditDialog ? 'edit' : 'create';
    return this.intl.t(`routes.styles.${intlText}`);
  }

  @action
  async save() {
    let changeset = null;

    if (!this.args.style) {
      this.style = this.store.createRecord('style');

      const validation = styleValidation(this.intl);
      const _changeset = new Changeset(this.style, lookupValidator(validation), validation);
      _changeset.set('name', this.changeset.get('name'));
      _changeset.set('description', this.changeset.get('description'));

      changeset = _changeset;
    } else {
      changeset = this.changeset;
    }
    const beforeChanges = cloneDeep(
      this.args.style ? this.args.style.get('data') : this.style.get('data')
    );

    try {
      await changeset.validate();

      if (!changeset.get('isValid')) {
        throw new Error();
      }

      this.isTouched = true;

      await changeset.save();

      this.onClose();

      this.uiToast.showToast({
        type: 'success',
        title: this.intl.t('global.toast.success.savedChanges')
      });
    } catch (e) {
      const error = getAdapterError(e);
      if (error === 'plan_allowed_styles_exceeded') {
        this.uiDialog.showError(
          this.intl.t('routes.styles.exceededError.title'),
          this.intl.t('routes.styles.exceededError.description')
        );
      } else if (error === 'validation.unique') {
        this.args.style && this.changeset.set('name', beforeChanges.name);
        this.args.style && this.args.style.set('name', beforeChanges.name);
        this.changeset.pushErrors('name', this.intl.t('errors.nameExists'));
      } else {
        this.uiToast.showToast({
          type: 'error',
          title: this.intl.t('global.toast.error.savedChanges')
        });
      }
      this.style.deleteRecord();
    }
  }

  /**
   * on close callback
   *
   * @function onClose
   */
  @arg(func)
  onClose = () => {};

  @action
  setup() {
    const validation = styleValidation(this.intl);
    this.changeset = new Changeset(
      this.style || this.args.style || { id: '', name: '', description: '' },
      lookupValidator(validation),
      validation
    );
  }

  @action
  close() {
    const changeset = this.changeset;

    if (changeset.get('isDirty')) {
      this.uiDialog.showDiscardChangesConfirm(() => {
        changeset.destroy();
        this.onClose();
      });
      return;
    }

    changeset.destroy();
    this.onClose();
  }
}
