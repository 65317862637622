import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { equal } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';

export default class Widgets extends Controller {
  @service authenticatedFetch;
  @service currentUser;
  @service router;

  @equal('router.currentRouteName', 'instance.styles.widgets.index') isWidgetsRoute;

  /**
   * whether the create dialog is open
   *
   * @argument isCreateDialog
   * @type {Boolean}
   * @default false
   */
  @tracked
  isCreateDialog = false;

  /**
   * whether the FAQ dialog is open
   *
   * @argument isFaqDialog
   * @type {Boolean}
   * @default false
   */
  @tracked
  isFaqDialog = false;

  /**
   * Array of keys for FAQs
   *
   * @property faqKeys
   * @type {Array}
   * @default null
   */
  @tracked
  faqKeys = null;

  /**
   * the new widget record
   *
   * @argument newWidget
   * @type {Object}
   * @default null
   */
  @tracked
  newWidget = null;

  constructor() {
    super(...arguments);

    this.faqKeys = ['styleBasics', 'styleUses', 'templateBasics', 'templateUses', 'dnaSymbol'];
  }

  get isViewer() {
    return this.currentUser.isViewer;
  }

  @action
  openCreateDialog() {
    this.isCreateDialog = true;
    this.newWidget = this.store.createRecord('widget', {
      name: null,
      description: null,
      addressBookId: null,
      groupId: null,
      type: 'inline'
    });
  }
}
