import { starter as starterOrganization } from '../organization';
import { partnerMember as partnerMemberUser } from '../user';
export const DEFAULT_ORG_ID = 'test-org';

export default (server, options = {}) => {
  const _options = Object.assign({ loadOrganization: true, loadUser: true }, options);
  server.create('organization', { id: DEFAULT_ORG_ID });
  server.create('user');
  server.create('user', { role: 'manager' });
  server.create('user', { role: 'admin' });
  server.create('user', { isAdditive: false, isPartner: false });
  server.create('user', { isAdditive: false, isPartner: false, role: 'manager' });
  server.create('user', { isAdditive: false, isPartner: false, role: 'admin' });

  _options.loadOrganization && starterOrganization(server);
  _options.loadUser && partnerMemberUser(server);
};
