import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"flex items-start mv1\" ...attributes>\n  <div class=\"status-container\">\n    <UiStatus @state={{if @errorMessage \"error\" (if @warningMessage \"warning\" \"success\")}} />\n  </div>\n  <div class=\"flex {{if (gt this._formattedMessage.length 1) 'flex-column'}}\">\n    <div>{{concat @title \": \"}}</div>\n    {{#if (gt this._formattedMessage.length 1)}}\n      <ul class=\"mt05\">\n        {{#each this._formattedMessage as |message|}}\n          <li>{{message}}</li>\n        {{/each}}\n      </ul>\n    {{else}}\n      <div class=\"pl05\">{{this._formattedMessage}}</div>\n    {{/if}}\n  </div>\n</div>", {"contents":"<div class=\"flex items-start mv1\" ...attributes>\n  <div class=\"status-container\">\n    <UiStatus @state={{if @errorMessage \"error\" (if @warningMessage \"warning\" \"success\")}} />\n  </div>\n  <div class=\"flex {{if (gt this._formattedMessage.length 1) 'flex-column'}}\">\n    <div>{{concat @title \": \"}}</div>\n    {{#if (gt this._formattedMessage.length 1)}}\n      <ul class=\"mt05\">\n        {{#each this._formattedMessage as |message|}}\n          <li>{{message}}</li>\n        {{/each}}\n      </ul>\n    {{else}}\n      <div class=\"pl05\">{{this._formattedMessage}}</div>\n    {{/if}}\n  </div>\n</div>","moduleName":"additive-newsletter/components/anl-campaigns/send-dialog/status-element.hbs","parseOptions":{"srcName":"additive-newsletter/components/anl-campaigns/send-dialog/status-element.hbs"}});
import Component from '@glimmer/component';
import { arg } from 'ember-arg-types';
import { array, string } from 'prop-types';

export default class HeaderListItem extends Component {
  /**
   * The title of the Status Element
   *
   * @argument title
   * @type {String}
   * @default null
   */
  @arg(string)
  title = null;

  /**
   * The error message of the HeaderListItem
   *
   * @argument errorMessage
   * @type {Array | String}
   * @default null
   */
  @arg(array | string)
  errorMessage = null;

  /**
   * The warning message of the HeaderListItem
   *
   * @argument warningMessage
   * @type {Array | String}
   * @default null
   */
  @arg(array | string)
  warningMessage = null;

  /**
   * The success message of the HeaderListItem
   *
   * @argument successMessage
   * @type {Array | String}
   * @default null
   */
  @arg(array | string)
  successMessage = null;

  get _formattedMessage() {
    return [this.errorMessage || this.warningMessage || this.successMessage].flat();
  }
}
