import ApplicationAdapter from './application';
import { inject as service } from '@ember/service';
import ConflictError from '@ember-data/adapter/error';

export default class GroupAdapter extends ApplicationAdapter {
  @service currentUser;

  handleResponse(status, _, payload) {
    if (status === 409) {
      return new ConflictError([
        {
          status,
          dependencies: payload.dependencies
        }
      ]);
    }

    return super.handleResponse(...arguments);
  }

  urlForMultiple(modelName, snapshot) {
    let url = this.buildURL();

    if (snapshot && snapshot.adapterOptions && snapshot.adapterOptions.addressBook) {
      const addressBook = snapshot.adapterOptions.addressBook;
      url += `/addressbooks/${addressBook}/groups`;
    }

    return url;
  }

  urlForSingle(id, modelName, snapshot) {
    return `${this.urlForMultiple(modelName, snapshot)}/${id}`;
  }

  urlForQuery(query) {
    if (query.addressBook) {
      const addressBook = query.addressBook;
      delete query.addressBook;
      return `${this.buildURL()}/addressbooks/${addressBook}/groups`;
    }

    return this.buildURL();
  }

  urlForFindRecord() {
    return this.urlForSingle(...arguments);
  }

  urlForCreateRecord() {
    return this.urlForMultiple(...arguments);
  }

  urlForUpdateRecord() {
    return this.urlForSingle(...arguments);
  }

  urlForDeleteRecord() {
    return this.urlForSingle(...arguments);
  }
}
