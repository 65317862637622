import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div {{did-insert this.onDidInsert}}></div>\n<AnlCampaigns::SendDialog::ButtonListItem\n  @title={{t \"routes.campaigns.sendCampaign.emailPreview.title\"}}\n  @description={{this.description}}\n  @buttonLabel={{this.buttonLabel}}\n  @statusState={{this.statusState}}\n  @onClickButton={{this.onClickButton}}\n  @isLoading={{@isLoading}}\n  @setIsLoading={{@setIsLoading}}\n/>\n\n{{#if this.isResultDialog}}\n  <AnlCampaigns::EmailPreviewAllDialog::EmailPreviewAllDialog\n    @previews={{this.previews}}\n    @campaign={{@campaign}}\n    @isReady={{this.isAllPreviewsComplete}}\n    @onClose={{fn (mut this.isResultDialog) false}}\n    @onClickReprocessAll={{perform this.reprocessTask \"\"}}\n    @onClickReprocess={{perform this.reprocessTask}}\n  />\n{{/if}}", {"contents":"<div {{did-insert this.onDidInsert}}></div>\n<AnlCampaigns::SendDialog::ButtonListItem\n  @title={{t \"routes.campaigns.sendCampaign.emailPreview.title\"}}\n  @description={{this.description}}\n  @buttonLabel={{this.buttonLabel}}\n  @statusState={{this.statusState}}\n  @onClickButton={{this.onClickButton}}\n  @isLoading={{@isLoading}}\n  @setIsLoading={{@setIsLoading}}\n/>\n\n{{#if this.isResultDialog}}\n  <AnlCampaigns::EmailPreviewAllDialog::EmailPreviewAllDialog\n    @previews={{this.previews}}\n    @campaign={{@campaign}}\n    @isReady={{this.isAllPreviewsComplete}}\n    @onClose={{fn (mut this.isResultDialog) false}}\n    @onClickReprocessAll={{perform this.reprocessTask \"\"}}\n    @onClickReprocess={{perform this.reprocessTask}}\n  />\n{{/if}}","moduleName":"additive-newsletter/components/anl-campaigns/send-dialog/email-preview-item.hbs","parseOptions":{"srcName":"additive-newsletter/components/anl-campaigns/send-dialog/email-preview-item.hbs"}});
import ENV from 'additive-newsletter/config/environment';

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { arg } from 'ember-arg-types';
import { func, object } from 'prop-types';

import { task } from 'ember-concurrency';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import { ACCEPT_HEADER } from 'additive-newsletter/utils/constants';

class Preview {
  constructor(obj) {
    obj && Object.assign(this, obj);
  }

  @tracked
  id;

  @tracked
  category;

  @tracked
  displayName;

  @tracked
  screenshot;

  @tracked
  status;

  @tracked
  thumbnail;
}

export default class DropdownListItem extends Component {
  @service authenticatedFetch;
  @service currentUser;
  @service store;
  @service intl;

  /**
   * the object containing the fetch result for the email previews
   *
   * @argument campaign
   * @type {Object}
   * @default null
   */
  @tracked
  previews = null;

  /**
   * the campaign model
   *
   * @argument campaign
   * @type {Object}
   * @default null
   */
  @arg(object)
  @tracked
  campaign = null;

  /**
   * Current state of the fetching process.
   * Can be "success", "error" or null
   *
   * @property isFetchError
   * @type {Boolean}
   * @default false
   */
  @tracked
  fetchState = null;

  /**
   * whether the result dialog is open
   *
   * @property isResultDialog
   * @type {Boolean}
   * @default false
   */
  @tracked
  isResultDialog = false;

  /**
   * interval that refetches the previews
   *
   * @property _refetchInterval
   * @type {Number}
   * @default null
   * @private
   */
  @tracked
  _refetchInterval = null;

  get description() {
    if (this.fetchState === 'error') return this.intl.t('global.actions.retry');
    if (this.previews) {
      if (this.isAllPreviewsComplete) {
        return this.intl.t('routes.campaigns.sendCampaign.emailPreview.message.previewsCompleted', {
          count: this.previewsCount
        });
      } else {
        return this.intl.t(
          'routes.campaigns.sendCampaign.emailPreview.message.previewsProcessing',
          { count: this.previewsCount }
        );
      }
    }
    if (this.isReprocessingOrTriggering)
      return this.intl.t('routes.campaigns.sendCampaign.emailPreview.message.running');
    return this.intl.t('routes.campaigns.sendCampaign.emailPreview.message.idle');
  }

  get buttonLabel() {
    if (this.fetchState === 'error') return this.intl.t('global.actions.retry');
    if (this.previews) return this.intl.t('global.actions.showResult');
    if (this.isReprocessingOrTriggering) return this.intl.t('global.actions.execute');
    return this.intl.t('global.actions.execute');
  }

  /**
   * the statusState of the item
   *
   * @property statusState
   * @type {String}
   * @default 'warning'
   */
  get statusState() {
    if (this.isReprocessingOrTriggering) return null; // If task is running, even if last fetch was "error", the status is neutral
    if (this.fetchState === 'error') return 'error';
    if (this.previews) return 'success';
    return null;
  }

  get isAllPreviewsComplete() {
    if (!this.previews) return false;

    const allPreviews = [
      ...this.previews.mobile,
      ...this.previews.application,
      ...this.previews.web
    ];

    return allPreviews.every((preview) => preview.status === 'Complete');
  }

  get previewsCount() {
    if (!this.previews) return 0;

    const allPreviews = [
      ...this.previews.mobile,
      ...this.previews.application,
      ...this.previews.web
    ];

    return allPreviews.length;
  }

  // Determines if a fetch triggered by a button-click is completed.
  get isReprocessingOrTriggering() {
    return this.triggerTask.isRunning || this.reprocessTask.isRunning;
  }

  /**
   * executes the preview reprocessing on the current campaign
   *
   * @function previews
   * @type {Task}
   */
  @task(function* (client_id = '') {
    yield* this.fetchPreviews({ urlEnd: 'reprocess', method: 'PUT', body: { client_id } });
    this._setupPeriodicRefetch();
  })
  reprocessTask;

  /**
   * executes the initial preview generation on the current campaign
   *
   * @function previews
   * @type {Task}
   */
  @task(function* () {
    yield* this.fetchPreviews({ urlEnd: '', method: 'POST' });
    this._setupPeriodicRefetch();
  })
  triggerTask;

  /**
   * fetches the results of the preview generation on the current campaign
   *
   * @function previews
   * @type {Task}
   */
  @task(function* () {
    yield* this.fetchPreviews({ urlEnd: '', method: 'GET' });
  })
  resultsTask;

  fetchPreviews = function* ({ method, body, urlEnd }) {
    try {
      const url = `${ENV.APP.apiBaseHost}/${this.currentUser.currentOrganization.id}/campaigns/${this.campaign.id}/email-on-acid/${urlEnd}`;

      const response = yield this.authenticatedFetch.fetch(
        url,
        {
          method,
          headers: ACCEPT_HEADER
        },
        body
      );

      if (!response) throw new Error('No response.');
      if (!response.ok) throw new Error('Erroneous response.');

      const jsonResponse = yield response.json();

      if (!jsonResponse) throw new Error('No JSON response.');

      if (jsonResponse.results) {
        const previews = jsonResponse.results;

        if (!this.previews) {
          // map preview objects to class
          Object.keys(previews).forEach((category) => {
            previews[category] = previews[category].map((preview) => new Preview(preview));
          });
          this.previews = previews;
        } else {
          Object.keys(previews).forEach((category) => {
            previews[category].forEach((preview, index) => {
              this.previews[category][index].status = preview.status;
              this.previews[category][index].screenshot = preview.screenshot;
              this.previews[category][index].thumbnail = preview.thumbnail;
            });
          });
        }
      } else if (jsonResponse.result) {
        const category = jsonResponse.result.category.toLowerCase();
        if (this.previews?.[category]) {
          const previewIndex = this.previews[category].findIndex(
            (preview) => preview.id === jsonResponse.result.id
          );

          this.previews[category][previewIndex].status = jsonResponse.result.status;
          this.previews[category][previewIndex].thumbnail = jsonResponse.result.thumbnail;
          this.previews[category][previewIndex].screenshot = jsonResponse.result.screenshot;
        }
      }

      this.fetchState = 'success';
    } catch (e) {
      this.previews = null;
      this.fetchState = 'error';

      throw e;
    }
  };

  willDestroy() {
    super.willDestroy(...arguments);

    if (this._refetchInterval) {
      clearInterval(this._refetchInterval);
    }
  }

  _setupPeriodicRefetch() {
    if (!this._refetchInterval) {
      this._refetchInterval = setInterval(() => {
        if (this.isAllPreviewsComplete) {
          clearInterval(this._refetchInterval);
          this._refetchInterval = null;
          return;
        }
        if (this.previews && this.isResultDialog) this.resultsTask.perform();
      }, 8000);
    }
  }

  @arg(func)
  setIsLoading = () => {};

  @action
  async onDidInsert() {
    // set is loading only on initial fetch
    this.setIsLoading(true);
    await this.resultsTask.perform();
    this.setIsLoading(false);

    this._setupPeriodicRefetch();
  }

  @action
  async onClickButton() {
    if (this.previews) {
      this.isResultDialog = true;
      return;
    }

    await this.triggerTask.perform();
  }
}
