/**
 * Route for Marketing Insights in report
 * @module routes: instance/reports/report
 */

/**
 * @param {*} self
 */
export const MarketingInsights = (self) => {
  self.get('/reports/:reportId/marketing-insights', () => {
    return {
      stats: {
        conversions: {
          type: 'single',
          current: {
            value: 1234,
            type: 'total'
          }
        },
        reservations: {
          type: 'single',
          current: {
            value: 1234,
            type: 'total'
          }
        },
        sessions: {
          type: 'single',
          current: {
            value: 1234,
            type: 'total'
          }
        },
        totalValueCreation: {
          type: 'single',
          current: {
            value: 1234,
            type: 'total'
          }
        }
      },
      url: 'https://staging.marketing-insights.additive-apps.eu/testhotel-post-com/analytics/R2VzYW10OjplbWFpbDo6YWRkaXRpdmUtbmV3c2xldHRlcjo6Z2FsbGVyeV9vdXRsb29rMjAxNl9i?start=2024-07-24&end=2024-08-19&groupingHierarchy=medium,source,campaign'
    };
  });
};
