import {
  validatePresence,
  validateFormat,
  validateNumber
} from 'ember-changeset-validations/validators';

export const createTemplateValidation = (intl) => {
  const message = intl.t('errors.required');

  return {
    name: validatePresence({ presence: true, message }),
    styleId: validatePresence({ presence: true, message })
  };
};
export const duplicateTemplateValidation = (intl) => {
  const message = intl.t('errors.required');

  return {
    name: validatePresence({ presence: true, message })
  };
};
export const senderValidation = (intl) => {
  const presenceMessage = intl.t('errors.required');
  const emailMessage = intl.t('errors.email');

  return {
    subject: validatePresence({ presence: true, message: presenceMessage }),
    sender: validatePresence({ presence: true, message: presenceMessage }),
    senderEmail: [
      validatePresence({ presence: true, message: presenceMessage }),
      validateFormat({ type: 'email', message: emailMessage })
    ],
    replyTo: validateFormat({ type: 'email', message: emailMessage, allowBlank: true })
  };
};
export const recipientValidation = (intl) => {
  const presenceMessage = intl.t('errors.required');

  return {
    addressBook: validatePresence({ presence: true, message: presenceMessage }),
    languages: validatePresence({ presence: true, message: presenceMessage })
  };
};
export const resendValidation = (intl) => {
  const presenceMessage = intl.t('errors.required');
  const missingIntegerMessage = intl.t('errors.numberInteger');
  const positiveNumberMessage = intl.t('errors.numberPositive');
  const invalidTime = intl.t('errors.invalidTime');

  return {
    resendAfterDays: [
      validatePresence({ presence: true, message: presenceMessage }),
      validateNumber({ multipleOf: 1, message: missingIntegerMessage }),
      validateNumber({ gt: 0, positive: true, message: positiveNumberMessage })
    ],
    resendSubject: validatePresence({ presence: true, message: presenceMessage }),
    resendTime: [
      validateFormat({
        allowBlank: true,
        regex: /^(([0-1][0-9])|(2[0-3])):([0-5][0-9])$/,
        message: invalidTime
      })
    ]
  };
};
