import Controller from '@ember/controller';
import { action } from '@ember/object';
import { computed } from '@ember/object';
import { or, equal } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

import {
  CAMPAIGN_STATES,
  PENDING_CAMPAIGN_STATES,
  FAILED_CAMPAIGN_STATES,
  STORE_EVENTS_KEY_DRAFT_CAMPAIGNS,
  STORE_EVENTS_KEY_PENDING_CAMPAIGNS,
  STORE_EVENTS_KEY_FAILED_CAMPAIGNS
} from 'additive-newsletter/utils/constants';

export default class InstanceCampaignsIndex extends Controller {
  @service store;
  @service uiStoreEvents;

  draftState = CAMPAIGN_STATES.DRAFT;
  failedStates = FAILED_CAMPAIGN_STATES;
  pendingStates = PENDING_CAMPAIGN_STATES;

  @tracked
  draftCampaigns = null;

  @tracked
  failedCampaigns = null;

  @tracked
  isDraftError = false;

  @tracked
  isPendingError = false;

  @tracked
  isFailedError = false;

  @tracked
  pendingCampaigns = null;

  _registeredKeys = [];

  @or(
    'fetchDraftCampaigns.isRunning',
    'fetchPendingCampaigns.isRunning',
    'fetchFailedCampaigns.isRunning'
  )
  isLoading;

  @equal('campaignsCount', 0) _isEmpty;

  @computed('_isEmpty', 'isDraftError', 'isPendingError', 'isFailedError')
  get showEmptyView() {
    return this._isEmpty && !(this.isPendingError || this.isDraftError || this.isFailedError);
  }

  @computed('draftCampaigns.length', 'failedCampaigns.length', 'pendingCampaigns.length')
  get campaignsCount() {
    const draftCampaignsCount = (this.draftCampaigns && this.draftCampaigns.length) || 0;
    const failedCampaignsCount = (this.failedCampaigns && this.failedCampaigns.length) || 0;
    const pendingCampaignsCount = (this.pendingCampaigns && this.pendingCampaigns.length) || 0;

    return draftCampaignsCount + failedCampaignsCount + pendingCampaignsCount;
  }

  @task(function* () {
    this.isDraftError = false;

    try {
      this.draftCampaigns = yield this.fetchCampaigns.perform(this.draftState, '-createdAt');
    } catch (e) {
      this.isDraftError = true;
    }
  })
  fetchDraftCampaigns;

  @task(function* () {
    this.isPendingError = false;

    try {
      this.pendingCampaigns = yield this.fetchCampaigns.perform(
        this.pendingStates.join(','),
        'sendAt'
      );
    } catch (e) {
      this.isPendingError = true;
    }
  })
  fetchPendingCampaigns;

  @task(function* () {
    this.isFailedError = false;

    try {
      this.failedCampaigns = yield this.fetchCampaigns.perform(
        this.failedStates.join(','),
        '-createdAt'
      );
    } catch (e) {
      this.isFailedError = true;
    }
  })
  fetchFailedCampaigns;

  @task(function* (status, sort) {
    let campaigns = null;
    campaigns = yield this.store.query('campaign', {
      'status[in]': status,
      sort,
      limit: -1
    });

    return campaigns;
  })
  fetchCampaigns;

  tearDown() {
    if (this._registeredKeys) {
      this._registeredKeys.forEach((key) => this.uiStoreEvents.unregister(key));
    }
  }

  /**
   * retries all failed tasks
   *
   * @function retryFetch
   */
  @action
  retryFetch() {
    if (this.isDraftError) {
      this.fetchDraftCampaigns.perform();
    }
    if (this.isFailedError) {
      this.fetchFailedCampaigns.perform();
    }
    if (this.isPendingError) {
      this.fetchPendingCampaigns.perform();
    }
  }

  @action
  setup() {
    this.uiStoreEvents.register(STORE_EVENTS_KEY_DRAFT_CAMPAIGNS, 'campaigns', () => {
      this.fetchDraftCampaigns.perform();
    });

    this.uiStoreEvents.register(STORE_EVENTS_KEY_PENDING_CAMPAIGNS, 'campaigns', () => {
      this.fetchPendingCampaigns.perform();
    });

    this.uiStoreEvents.register(STORE_EVENTS_KEY_FAILED_CAMPAIGNS, 'campaigns', () => {
      this.fetchFailedCampaigns.perform();
    });

    this._registeredKeys.push(
      STORE_EVENTS_KEY_DRAFT_CAMPAIGNS,
      STORE_EVENTS_KEY_PENDING_CAMPAIGNS,
      STORE_EVENTS_KEY_FAILED_CAMPAIGNS
    );

    this.fetchDraftCampaigns.perform();
    this.fetchFailedCampaigns.perform();
    this.fetchPendingCampaigns.perform();
  }
}
