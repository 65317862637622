import ENV from 'additive-newsletter/config/environment';

import Route from '@ember/routing/route';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default Route.extend({
  currentUser: service(),
  intl: service(),
  store: service(),
  uiDialog: service(),
  uiFilter: service(),

  organizationSlug: alias('currentUser.currentOrganization.id'),

  _currentAddressBookId: null,

  afterModel(model) {
    this._super(...arguments);

    // Abs has changed, reset filter to re-fetch
    if (!this._currentAddressBookId || this._currentAddressBookId !== model.id) {
      try {
        this.uiFilter.reset('address-book-subscribers');
      } catch (error) {
        // filter might not exist yet, if so do nothing
      }
    }

    this._currentAddressBookId = model.id;
  },

  model(params) {
    return this.store.findRecord('address-book', params.address_book_id);
  },

  setupController(controller, _, transition) {
    this._super(...arguments);

    const baseUrl = ENV.APP.apiBaseHost;
    const filtersUrl = `${baseUrl}/${this.organizationSlug}/subscribers/filters`;

    // Fetch integration settings
    controller.fetchSettings.perform();

    this.uiFilter.register('address-book-subscribers', 'address-book-subscriber', {
      dynamicFilters: {
        url: filtersUrl
      },
      sort: '-subscribedAt',
      request: (queryParams) => {
        return this.store.query(
          'address-book-subscriber',
          Object.assign({}, queryParams, {
            'addressBook.id': this._currentAddressBookId
          })
        );
      }
    });

    controller.filterKey = 'address-book-subscribers';

    if (
      transition?.from?.name === 'instance.dashboard' ||
      transition?.from?.name === 'instance.reports.index'
    ) {
      controller.activeAddressBookDrawerTab = 1;
    }
  },

  resetController(controller) {
    this._super(...arguments);

    this.uiFilter.reset('address-book-subscribers', false);
    this.uiFilter.unregister('address-book-subscribers');
    controller.filterKey = null;
  },

  actions: {
    willTransition(transition) {
      if (this.controller.isUploadingFile) {
        transition.abort();
        this.uiDialog.showDiscardChangesConfirm(
          () => {
            this.controller.fileUploadAbortController?.abort();
            this.controller.isUploadingFile = false;
            transition.retry();
          },
          this.intl.t('addressBooks.dialogs.abortUpload.title'),
          this.intl.t('addressBooks.dialogs.abortUpload.description'),
          this.intl.t('addressBooks.dialogs.abortUpload.continue'),
          this.intl.t('addressBooks.dialogs.abortUpload.cancel')
        );
        return;
      }
      return true;
    }
  }
});
