import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<UiModal\n  @title={{t \"components.anlAddressBook.saveFilterDialog.title\"}}\n  @size=\"md\"\n  @onClose={{this._onClose}}\n  as |modal|\n>\n  <modal.header />\n  <modal.content as |content|>\n    <content.body>\n      <p class=\"font-md pb2\">\n        {{t \"components.anlAddressBook.saveFilterDialog.description\"}}\n      </p>\n\n      <UiInput\n        @label={{t \"components.anlAddressBook.saveFilterDialog.labels.name\"}}\n        @value={{this._changeset.name}}\n        @errorMessage={{get this._changeset.error.name \"validation\"}}\n      />\n    </content.body>\n  </modal.content>\n\n  <modal.footer>\n    <UiButton\n      @label={{t \"global.actions.save\"}}\n      @isDisabled={{this.isSaveButtonDisabled}}\n      @isSecondary={{true}}\n      @onClick={{perform this._saveFilter}}\n    />\n  </modal.footer>\n</UiModal>", {"contents":"<UiModal\n  @title={{t \"components.anlAddressBook.saveFilterDialog.title\"}}\n  @size=\"md\"\n  @onClose={{this._onClose}}\n  as |modal|\n>\n  <modal.header />\n  <modal.content as |content|>\n    <content.body>\n      <p class=\"font-md pb2\">\n        {{t \"components.anlAddressBook.saveFilterDialog.description\"}}\n      </p>\n\n      <UiInput\n        @label={{t \"components.anlAddressBook.saveFilterDialog.labels.name\"}}\n        @value={{this._changeset.name}}\n        @errorMessage={{get this._changeset.error.name \"validation\"}}\n      />\n    </content.body>\n  </modal.content>\n\n  <modal.footer>\n    <UiButton\n      @label={{t \"global.actions.save\"}}\n      @isDisabled={{this.isSaveButtonDisabled}}\n      @isSecondary={{true}}\n      @onClick={{perform this._saveFilter}}\n    />\n  </modal.footer>\n</UiModal>","moduleName":"additive-newsletter/components/anl-address-book/save-filter-dialog.hbs","parseOptions":{"srcName":"additive-newsletter/components/anl-address-book/save-filter-dialog.hbs"}});
import ENV from 'additive-newsletter/config/environment';

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { arg } from 'ember-arg-types';
import { func, string } from 'prop-types';

import { all, timeout, task } from 'ember-concurrency';
import Changeset from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import { filterValidation } from 'additive-newsletter/validations/filter';

import { ACCEPT_HEADER } from 'additive-newsletter/utils/constants';

/**
 * Dialog to create a new filter or rename an existing one
 *
 * @module anl-address-book
 * @class AnlAddressBookSaveFilterDialog
 */
export default class AnlAddressBookSaveFilterDialog extends Component {
  @service authenticatedFetch;
  @service currentUser;
  @service intl;
  @service uiDialog;
  @service uiFilter;
  @service uiStoreEvents;
  @service uiToast;

  /**
   * the key of the filter
   *
   * @argument filterKey
   * @type {String}
   * @default null
   */
  @arg(string)
  @tracked
  filterKey = null;

  /**
   * the filter to save
   *
   * @property _filter
   * @type {Object}
   * @default null
   * @private
   */
  @tracked
  _filter = null;

  /**
   * whether a new filter is created
   *
   * @property _isNew
   * @type {Boolean}
   * @default false
   * @private
   */
  @tracked
  _isNew = false;

  @alias('currentUser.currentOrganization.id') _organizationSlug;

  get currentFilterValues() {
    if (!this.filterKey) {
      return null;
    }

    const currentQPFilter = this.uiFilter.getQPFilter(this.filterKey);
    const currentFilter = currentQPFilter && currentQPFilter.filter;
    let currentFilterValues = (currentFilter && currentFilter.getCustomFilters()) || {};
    const currentSearchTerm = (currentFilter && currentFilter.getSearchTerm()) || '';
    if (currentSearchTerm) {
      currentFilterValues = Object.assign({ q: currentSearchTerm }, currentFilterValues);
    }
    return currentFilterValues;
  }

  get isSaveButtonDisabled() {
    return !this._changeset || this._changeset.get('isPristine') || !this._changeset.get('name');
  }

  constructor() {
    super(...arguments);

    if (this.args.filter) {
      this._filter = this.args.filter;
    } else {
      this._isNew = true;
      this._filter = { name: '', filters: this.currentFilterValues };
    }

    const validation = filterValidation(this.intl);
    this._changeset = new Changeset(this._filter, lookupValidator(validation), validation);
  }

  /**
   * saves the filter
   *
   * @function _saveFilter
   * @type {Task}
   * @private
   */
  @task(function* () {
    const changeset = this._changeset;

    try {
      yield changeset.validate();
      if (!changeset.get('isValid')) {
        return;
      }

      // dont send request if no changes were made
      if (changeset.get('isPristine')) {
        return this.onClose();
      }

      yield changeset.execute();

      const isEdit = !!this._filter.id;

      let tasks = [];
      const url = `${ENV.APP.apiBaseHost}/${this._organizationSlug}/filters`;
      let request = null;
      if (isEdit) {
        request = this.authenticatedFetch.fetch(`${url}/${this._filter.id}`, {
          headers: ACCEPT_HEADER,
          method: 'PUT',
          body: JSON.stringify(this._filter)
        });
      } else {
        request = this.authenticatedFetch.fetch(url, {
          headers: ACCEPT_HEADER,
          method: 'POST',
          body: JSON.stringify(this._filter)
        });
      }

      tasks.push(request);
      tasks.push(timeout(400));

      const [response] = yield all(tasks);
      if (!response || !response.ok) {
        // handle validation error
        if (response && response.status === 422) {
          const errorJson = yield response.json();
          const errors = errorJson && errorJson.errors;
          if (errors && typeof errors === 'object') {
            const firstErrorKey = errors && Object.keys(errors)[0];
            throw new Error(errors[firstErrorKey]);
          }
        }

        throw new Error('[SAVE FILTER DIALOG] Error on save');
      }

      const { filter } = yield response.json();
      if (!filter) {
        throw new Error('[SAVE FILTER DIALOG] Error on save');
      }

      this.onSave(filter);
      this.uiStoreEvents.didChange('filter');

      this.uiToast.showToast({
        type: 'success',
        title: this.intl.t(
          `components.anlAddressBook.saveFilterDialog.toasts.success.${isEdit ? 'edit' : 'create'}`
        )
      });
      this.onClose();
    } catch (error) {
      if (error && error.message === 'filter_name_already_taken') {
        changeset.pushErrors('name', this.intl.t('errors.nameExists'));
        return;
      }
      this.uiDialog.showError();
    }
  })
  _saveFilter;

  /**
   * on close callback
   *
   * @function onClose
   */
  @arg(func)
  onClose = () => {};

  /**
   * on save callback
   *
   * @function onSave
   */
  @arg(func)
  onSave = () => {};

  /**
   * destroys the changeset and deletes the record in case it is new
   *
   * @function _onClose
   */
  @action
  _onClose() {
    const changeset = this._changeset;
    if (changeset.get('isDirty')) {
      this.uiDialog.showDiscardChangesConfirm(() => {
        changeset.destroy();
        this.onClose();
      });
      return;
    }

    this.onClose();
  }
}
