import Model, { attr } from '@ember-data/model';

import { equal } from '@ember/object/computed';

export default Model.extend({
  createdAt: attr('string'),
  name: attr('string'),
  subscriberCount: attr('number'),
  openRate: attr('number'),
  clickRate: attr('number'),
  pms: attr(),

  hasNoSubscribers: equal('subscriberCount', 0)
});
