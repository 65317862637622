import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import { format, parseISO } from 'date-fns';
import deLocale from 'date-fns/locale/de';

export default class AnlSubscriberFormatInfo extends Helper {
  @service intl;

  compute([key, value]) {
    switch (key) {
      case 'createdAt':
      case 'subscribedAt':
      case 'unsubscribedAt':
      case 'deactivatedAt':
      case 'updatedAt':
      case 'confirmedAt':
      case 'inactiveSince':
        return format(parseISO(value), 'dd.MM.yyyy HH:mm', { locale: deLocale });
      case 'source':
      case 'unsubscribeSource':
      case 'deactivationSource':
        return this.intl.t(`models.subscriber.${key}.${value}`);
      case 'confirmationType':
        return this.intl.t(`models.subscriber.confirmationType.${value}`);
      default:
        return value;
    }
  }
}
