import ApplicationAdapter from './application';
import { tracked } from '@glimmer/tracking';
import { ACCEPT_HEADER } from 'additive-newsletter/utils/constants';

export default class CampaignAdapter extends ApplicationAdapter {
  @tracked
  version = 2;

  get headers() {
    if (this.version !== 2) {
      return super.headers;
    }
    return Object.assign({}, super.headers, ACCEPT_HEADER);
  }

  query() {
    this.version = 2;

    return super.query(...arguments);
  }

  findAll() {
    this.version = 2;

    return super.findAll(...arguments);
  }

  // parameters: store, type, id
  findRecord() {
    this.version = 2;

    return super.findRecord(...arguments);
  }

  createRecord(store, type, snapshot) {
    this.version = snapshot.record.version;

    return super.createRecord(...arguments);
  }

  deleteRecord(store, type, snapshot) {
    this.version = snapshot.record.version;

    return super.deleteRecord(...arguments);
  }

  updateRecord(store, type, snapshot) {
    this.version = snapshot.record.version;

    return super.updateRecord(...arguments);
  }
}
