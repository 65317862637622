// eslint-disable-next-line
const URL_REGEX = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;

export function validateTrackingHosts(options = {}) {
  const message = (options && options.message) || false;

  return (key, value) => {
    let isValid = true;

    const hosts = value;

    hosts.forEach((host) => {
      if (!host.match(URL_REGEX)) {
        isValid = false;
      }
    });

    return isValid || message;
  };
}
