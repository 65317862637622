import Controller from '@ember/controller';
import { action } from '@ember/object';
import { alias } from '@ember/object/computed';
import { bind, scheduleOnce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { all, task, timeout } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

export default class InstanceStylesTemplatesController extends Controller {
  @service authenticatedFetch;
  @service anlCd;
  @service currentUser;
  @service intl;
  @service store;
  @service router;
  @service uiDialog;
  @service uiToast;

  @tracked
  isError = false;

  @tracked
  isMobile = false;

  @tracked
  styles = null;

  @tracked
  activeStyle = null;

  @tracked
  isEditDialog = false;

  @tracked
  isDuplicateDialog = false;

  @alias('fetchLayouts.isRunning') isLoading;

  constructor() {
    super(...arguments);

    this.resizeListener = bind(this, this._checkWindow);
    window.addEventListener('resize', this.resizeListener, false);

    scheduleOnce('afterRender', this, this.resizeListener);
  }

  willDestroy() {
    super.willDestroy(...arguments);

    window.removeEventListener('resize', this.resizeListener, false);
  }

  /**
   * Checks the current windowsize programmatically and sets the `isMobile`-prop
   * to true if the current window size is lower than `600px`.
   *
   * @function _checkWindow
   * @private
   * @return {void}
   */
  _checkWindow() {
    this.isMobile = !window.matchMedia('(min-width: 600px)').matches;
  }

  @task(function* () {
    this.isError = false;

    try {
      let tasks = [];

      tasks.push(this.store.findAll('style'));
      tasks.push(timeout(400));

      let [styles] = yield all(tasks);

      this.styles = styles;

      // load corporate design for preview
      if (this.styles.length > 0) {
        this.anlCd.fetchCorporateDesign.perform();
      }
    } catch (error) {
      this.isError = true;
    }
  })
  fetchLayouts;

  @action
  edit(style) {
    if (this.isMobile) {
      return this.uiDialog.showError(
        this.intl.t('errors.desktopOnly.title'),
        this.intl.t('errors.desktopOnly.layout')
      );
    }
    // TODO: add transition to to v2 route for new styles
    if (style.isLegacy) {
      return this.router.transitionTo('instance.styles.layouts.layout', style);
    }

    this.router.transitionTo('instance.styles.index.style.index', style.id);
  }

  @action
  onEdit(style) {
    this.activeStyle = style;
    this.isEditDialog = true;
  }

  @action
  duplicateStyle(style) {
    this.activeStyle = style;
    this.isDuplicateDialog = true;
  }
}
