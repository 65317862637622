import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  class=\"flex flex-column cpointer email-preview-thumbnail-container\"\n  role=\"button\"\n  {{on \"click\" @onClick}}\n>\n  {{#if (eq @preview.status \"Complete\")}}\n    <img\n      class=\"ba b--secondary-10 br1\"\n      src={{@preview.thumbnail}}\n      alt=\"Email Preview Thumbnail\"\n      referrerpolicy=\"no-referrer\"\n    />\n  {{else}}\n    <UiSkeleton @items={{1}} @cardHeight={{220}} @gap={{0}} />\n  {{/if}}\n  <div class=\"flex items-center justify-between mt2\">\n    <div>\n      {{@preview.displayName}}\n      <div class=\"secondary font-xs\">\n        {{@categoryName}}\n      </div>\n    </div>\n    {{#if (eq @preview.status \"Complete\")}}\n      <UiIconButton @icon=\"reload\" @isSecondary={{true}} @onClick={{this._onClickReprocess}} />\n    {{else}}\n      <UiLoading />\n    {{/if}}\n  </div>\n</div>", {"contents":"<div\n  class=\"flex flex-column cpointer email-preview-thumbnail-container\"\n  role=\"button\"\n  {{on \"click\" @onClick}}\n>\n  {{#if (eq @preview.status \"Complete\")}}\n    <img\n      class=\"ba b--secondary-10 br1\"\n      src={{@preview.thumbnail}}\n      alt=\"Email Preview Thumbnail\"\n      referrerpolicy=\"no-referrer\"\n    />\n  {{else}}\n    <UiSkeleton @items={{1}} @cardHeight={{220}} @gap={{0}} />\n  {{/if}}\n  <div class=\"flex items-center justify-between mt2\">\n    <div>\n      {{@preview.displayName}}\n      <div class=\"secondary font-xs\">\n        {{@categoryName}}\n      </div>\n    </div>\n    {{#if (eq @preview.status \"Complete\")}}\n      <UiIconButton @icon=\"reload\" @isSecondary={{true}} @onClick={{this._onClickReprocess}} />\n    {{else}}\n      <UiLoading />\n    {{/if}}\n  </div>\n</div>","moduleName":"additive-newsletter/components/anl-campaigns/send-dialog/email-preview-thumbnail.hbs","parseOptions":{"srcName":"additive-newsletter/components/anl-campaigns/send-dialog/email-preview-thumbnail.hbs"}});
import Component from '@glimmer/component';

import { arg } from 'ember-arg-types';
import { string, func } from 'prop-types';
import { htmlSafe } from '@ember/string';
import { action } from '@ember/object';

export default class EmailPreviewThumbnail extends Component {
  /**
   * The email preview.
   *
   * @argument preview
   * @type {Object}
   * @default null
   */
  @arg(string)
  preview = null;

  /**
   * The function executed when the thumbnail is clicked.
   *
   * @argument onClick
   * @type {Function}
   */
  @arg(func)
  onClick = () => {};

  /**
   * The function executed when the reprocess button is clicked
   *
   * @argument onClickReprocess
   * @type {Function}
   */
  @arg(func)
  onClickReprocess = () => {};

  get style() {
    const bgImage = `background-image: url("${this.args?.preview?.thumbnail}")`;
    return htmlSafe(bgImage);
  }

  @action
  _onClickReprocess(event) {
    event.stopPropagation();
    this.onClickReprocess();
  }
}
