import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action, computed } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class InstanceStylesIndexStyleController extends Controller {
  @service anlCd;
  @service aeSaveStack;
  @service currentUser;
  @service intl;
  @service router;

  @tracked
  siblingVariations = null;

  get buttonOptions() {
    const blocks = this.store.peekAll('style-block');
    const buttons = blocks.filterBy('blockIdentifier', 'button');
    return buttons.map((button) => ({
      value: button.variationIdentifier,
      label: button.name
    }));
  }

  get isReadOnly() {
    return this.currentUser?.isViewer;
  }

  @computed('model.style.styles.colors.{main,ambient,accent}')
  get colorOptions() {
    const { colors } = this.model.style.styles;

    return Object.keys(colors).map((key) => {
      const c = colors[key];
      const color = c.sync ? c.syncedColor : c.color;
      const contrastColor = c.sync ? c.syncedContrastColor : c.contrastColor;

      return { key, value: { color, contrastColor } };
    });
  }

  /**
   * format errors in the style object
   *
   * @computed styleErrors
   * @type {Object}
   */
  @computed('aeSaveStack.errors.[]', 'model.variation.variationIdentifier')
  get styleErrors() {
    const errors = {};
    const errArray = this.aeSaveStack.errors.filter((err) => {
      return (
        err.attribute === 'style' &&
        err.variation_identifier === this.model.variation.variationIdentifier &&
        err.block_identifier === this.model.variation.blockIdentifier
      );
    });

    errArray.forEach((obj) => {
      let error;
      if (this.intl.exists(`routes.styles.style.errors.${obj.error}`)) {
        error = this.intl.t(`routes.styles.style.errors.${obj.error}`);
      } else {
        error = this.intl.t(`routes.styles.style.errors.error`);
      }
      errors[obj.property_id] = { error };
    });

    return errors;
  }

  /**
   * error for this variation
   *
   * @computed variationError
   * @type {String}
   */
  @computed('aeSaveStack.errors.[]', 'model.variation.variationIdentifier')
  get variationError() {
    const errArray = this.aeSaveStack.errors.filter((err) => {
      return (
        err.variation_identifier === this.model.variation.variationIdentifier &&
        err.block_identifier === this.model.variation.blockIdentifier &&
        err.error
      );
    });

    if (errArray.length) {
      if (this.intl.exists(`routes.styles.style.errors.${errArray[0].error}`)) {
        return this.intl.t(`routes.styles.style.errors.${errArray[0].error}`);
      } else {
        return this.intl.t(`routes.styles.style.errors.error`);
      }
    }
    return null;
  }

  @action
  onUpdate() {
    this.aeSaveStack.pushChanges(this.model.variation);
  }

  @action
  toggleVariationActive() {
    this.model.variation.active = !this.model.variation.active;
    this.aeSaveStack.pushChanges(this.model.variation);
  }

  @action
  transitionToSibling(variation) {
    this.router.transitionTo(
      `instance.styles.index.style.block.variation`,
      variation.variationIdentifier
    );
  }
}
