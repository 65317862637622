import Controller from '@ember/controller';
import { action } from '@ember/object';
import { alias } from '@ember/object/computed';
import { task, all, timeout } from 'ember-concurrency';
import { bind } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class InstanceTemplatesIndexTemplate extends Controller {
  @service currentUser;
  @service intl;
  @service router;
  @service store;
  @service uiAppSettings;
  @service uiDialog;

  /**
   * the active model
   *
   * @argument model
   * @type {Model}
   * @default null
   */
  @tracked
  model = null;

  /**
   * whether an error occured
   *
   * @argument isError
   * @type {Boolean}
   * @default false
   */
  @tracked
  isError = false;

  /**
   * whether a mobile device is used
   *
   * @argument isMobile
   * @type {Boolean}
   * @default false
   */
  @tracked
  isMobile = false;

  /**
   * the callback executed on resize
   *
   * @argument resizeCallback
   * @type {Function}
   */
  @tracked
  resizeCallback = () => {};

  /**
   * the fetched template
   *
   * @property _template
   * @type {Object}
   * @default null
   * @private
   */
  @tracked
  _template = null;

  /**
   * whether the rename dialog is open
   *
   * @property _isEditDialog
   * @type {Boolean}
   * @default false
   */
  @tracked
  _isEditDialog = false;

  /**
   * whether the duplicate dialog is open
   *
   * @property _isDuplicateDialog
   * @type {Boolean}
   * @default false
   */
  @tracked
  _isDuplicateDialog = false;

  /**
   * whether the campaign create dialog is shown
   *
   * @property _isCreateFromModelDialog
   * @type {Boolean}
   * @default false
   */
  @tracked
  _isCreateFromModelDialog = false;

  @alias('fetchTemplate.isRunning') isLoading;

  get _isViewer() {
    return this.currentUser.isViewer;
  }

  setup() {
    this.resizeCallback = bind(this, this._checkWindow);
    window.addEventListener('resize', this.resizeCallback, false);
    this._checkWindow();
  }

  tearDown() {
    window.removeEventListener('resize', this.resizeCallback, false);
    this._isCreateFromModelDialog = false;
    this._isEditDialog = false;
    this._isDuplicateDialog = false;
  }

  /**
   * Checks the current windowsize programmatically and sets the `isMobile`-prop
   * to true if the current window size is lower than `600px`.
   *
   * @function _checkWindow
   * @private
   * @return {void}
   */
  _checkWindow() {
    this.isMobile = !window.matchMedia('(min-width: 600px)').matches;
  }

  /**
   * deletes the active template
   *
   * @function _deleteTemplate
   * @type {Task}
   */
  @task(function* () {
    try {
      yield this._template.destroyRecord();

      if (this.router.isActive('instance.templates.index.template.index', this._template.id)) {
        return this.router.transitionTo('instance.templates.index');
      }

      this.uiToast.showToast({
        type: 'success',
        title: this.intl.t('global.toast.success.savedChanges'),
        duration: 2000
      });
    } catch (e) {
      this.uiToast.showToast({
        type: 'error',
        title: this.intl.t('global.toast.error.savedChanges'),
        duration: 2000
      });
    }
  })
  _deleteTemplate;

  @task(function* (forceFetch) {
    this.isError = false;

    let template = this.store.peekRecord('template', this.model);
    if (!template || forceFetch) {
      try {
        const tasks = [];
        tasks.push(this.store.findRecord('template', this.model, { reload: true }));
        tasks.push(timeout(400));

        [template] = yield all(tasks);
        if (template?.language !== this.uiAppSettings.currentLocale) {
          this.uiAppSettings.setLocale(template.language);
        }
      } catch (e) {
        this.isError = true;
      }
    }

    this._template = template;
  })
  fetchTemplate;

  @action
  openCreateFromModelDialog() {
    if (!this._template.languages.length) {
      return this.uiDialog.showError(
        this.intl.t('components.anlCampaigns.createFromModelDialog.error.title'),
        this.intl.t('components.anlCampaigns.createFromModelDialog.error.description')
      );
    }
    this._isCreateFromModelDialog = true;
  }

  @action
  deleteModel() {
    const { intl } = this;
    this.uiDialog.showConfirm(
      intl.t(`routes.templates.template.dialogs.delete.title`),
      intl.t(`routes.templates.template.dialogs.delete.description`, {
        name: this._template.name
      }),
      () => this._deleteTemplate.perform(),
      intl.t('global.actions.delete'),
      true,
      true
    );
  }

  @action
  back() {
    if (this.router.isActive('instance.templates.index.template.index')) {
      return this.router.transitionTo('instance.templates.index');
    }
  }

  @action
  edit() {
    this.router.transitionTo('instance.templates.index.template.edit', this.model);
  }
}
