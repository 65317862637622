import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class InstanceStylesWidgetsRoute extends Route {
  @service uiFilter;

  setupController() {
    super.setupController(...arguments);

    this.uiFilter.register('widgets', 'widget', { paginated: false });
  }

  resetController() {
    this.uiFilter.unregister('widgets');
  }
}
