import ApplicationSerializer from './application';

export default ApplicationSerializer.extend({
  attrs: {
    addressBook: { serialize: false },
    confirmed: { serialize: false },
    deactivatedAt: { serialize: false },
    source: { serialize: false },
    subscribedAt: { serialize: false },
    unsubscribedAt: { serialize: false }
  },

  serialize(snapshot, options) {
    let json = this._super(snapshot, options);

    if (!json.customSalutation) {
      delete json.customSalutation;
    }

    if (!snapshot.id) {
      // POST-Request
      json.subscriberId = snapshot.record.subscriberId;
    } else {
      // Every request with existing model (PUT, GET, DELETE...)
      delete json.subscriberId;
    }

    return json;
  },

  normalizeResponse(store, primaryModelClass, payload) {
    const addressBookSubscribers = payload.addressBookSubscribers
      ? payload.addressBookSubscribers
      : [payload.addressBookSubscriber];

    addressBookSubscribers.forEach((addressBookSubscriber) => {
      if (addressBookSubscriber.salutation === 'custom') {
        addressBookSubscriber.customSalutation = addressBookSubscriber.salutationText;
      }
    });

    return this._super(...arguments);
  },

  keyForRelationship(key) {
    if (key === 'subscriber') {
      return 'subscriberId';
    }

    return key;
  }
});
