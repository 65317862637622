import Route from '@ember/routing/route';
import ApplicationRouteMixin from '@additive-apps/auth/mixins/application-auth-route-mixin';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

export default class ApplicationRoute extends Route.extend(ApplicationRouteMixin) {
  @service intl;
  @service uiPaths;
  @service uiToast;
  @service uiLocale;

  routeAfterAuthentication = 'instance';

  intervalId = null;

  isToastActive = false;

  @(task(function* () {
    try {
      // check if the browser supports serviceWorker at all
      if ('serviceWorker' in navigator) {
        const registration = yield navigator.serviceWorker.getRegistration();

        // detect serviceWorker update available and wait for it to become installed
        registration.addEventListener('updatefound', () => {
          if (registration.installing) {
            // wait until the new serviceWorker is actually installed (ready to take over)
            registration.installing.addEventListener('statechange', () => {
              // if there's an existing controller (previous serviceWorker), show the update toast and clear the interval
              if (registration.waiting && navigator.serviceWorker.controller) {
                this.showUpdateToast();
              }
            });
          }
        });
      }
    } catch (e) {
      // don't display anything if this task fails, because it runs in the background
    }
  }).drop())
  updateServiceWorker;

  setupUpdateCheck() {
    const task = this.updateServiceWorker;
    // sets interval that checks for new SW registrations and updates those registrations
    // updating the registration will then trigger the installing and waiting events
    if (!this.intervalId) {
      this.intervalId = setInterval(function () {
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.ready.then((registration) => {
            // eslint-disable-next-line
            registration.update();
            task.perform();
          });
        }
      }, 120000);
    }
  }

  showUpdateToast() {
    if (!this.isToastActive) {
      clearInterval(this.intervalId);
      const toast = this.uiToast.showToast({
        type: 'warning',
        title: this.intl.t('global.toast.update.title'),
        description: this.intl.t('global.toast.update.description'),
        actionLabel: this.intl.t('global.toast.update.actionLabel'),
        duration: -1,
        icon: 'announcement',
        onClick: () => {
          this.uiToast.destroyToast(toast);
          this.isToastActive = false;
          this.applyUpdate();
        },
        onAbort: () => {
          this.uiToast.destroyToast(toast);
          this.isToastActive = false;
        }
      });
      this.isToastActive = true;
    }
  }

  applyUpdate() {
    // refresh the page; this causes the new serviceWorker to be applied.
    window.location.reload();
  }

  beforeModel() {
    this.uiLocale.setup();
    // update to api.<baseHost>.tech host format
    this.uiPaths.setApiRootKey('');
  }

  setupController() {
    super.setupController(...arguments);

    // checks if serviceWorker update is available once
    this.updateServiceWorker.perform();

    // sets up an interval of 120sec, where it checks again
    this.setupUpdateCheck();
  }
}
