import ENV from 'additive-newsletter/config/environment';

import Route from '@ember/routing/route';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import AuthRouteMixin from '@additive-apps/auth/mixins/auth-route-mixin';

import Userback from '@userback/widget';
import { ACCEPT_HEADER } from 'additive-newsletter/utils/constants';

export default class InstanceRoute extends Route.extend(AuthRouteMixin) {
  @service authenticatedFetch;
  @service currentUser;
  @service session;
  @service store;
  @service uiAppSettings;
  @service uiLocale;

  async model(params) {
    const organization = await this.store.findRecord('organization', params.instance_id);
    set(this, 'currentUser.currentOrganization', organization);

    let tasks = [];
    tasks.push(this.currentUser.loadUser());
    tasks.push(this.uiAppSettings.loadLanguages(ACCEPT_HEADER));
    await Promise.all(tasks);

    if (this.currentUser.isAdditiveUser || this.currentUser.isPartnerUser) {
      Userback(
        this.currentUser.isAdditiveUser
          ? ENV.APP.userbackAccessToken
          : ENV.APP.userbackAccessTokenPartner,
        {
          email: this.currentUser.user.email,
          name: this.currentUser.user.fullName,
          categories: 'Newsletter'
        }
      );
    }

    await this.uiLocale.setLocale(
      this.currentUser?.user?.language || this.currentUser?.currentOrganization?.language || 'de'
    );

    return organization;
  }

  @action
  loading() {
    return !this.currentUser.currentOrganization;
  }
}
