import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.isBadge}}\n  <UiBadge @state={{get-campaign-status this.status}} @size=\"sm\">\n    <UiStatus @state={{get-campaign-status this.status}} data-test-anl-campaigns-status={{get-campaign-status this.status}} />\n    <div class=\"pr1\">\n      {{this._statusText}}\n    </div>\n  </UiBadge>\n{{else}}\n  {{this._statusText}}\n{{/if}}", {"contents":"{{#if this.isBadge}}\n  <UiBadge @state={{get-campaign-status this.status}} @size=\"sm\">\n    <UiStatus @state={{get-campaign-status this.status}} data-test-anl-campaigns-status={{get-campaign-status this.status}} />\n    <div class=\"pr1\">\n      {{this._statusText}}\n    </div>\n  </UiBadge>\n{{else}}\n  {{this._statusText}}\n{{/if}}","moduleName":"additive-newsletter/components/anl-campaigns/status.hbs","parseOptions":{"srcName":"additive-newsletter/components/anl-campaigns/status.hbs"}});
import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';

import {
  ABORTED_CAMPAIGN_STATES,
  PENDING_CAMPAIGN_STATES,
  CAMPAIGN_STATES
} from 'additive-newsletter/utils/constants';

/**
 * This component displays the status of a campaign as colored text
 *
 * @module anl-campaigns
 * @class anl-campaigns/status
 */
export default Component.extend({
  intl: service(),

  classNameBindings: ['_color'],

  /**
   * the status of the campaign
   *
   * @argument status
   * @type {Number}
   */
  status: undefined,

  /**
   * whether it is shown as badge
   *
   * @argument isBadge
   * @type {Boolean}
   */
  isBadge: false,

  /**
   * computed status color
   *
   * @computed _color
   * @type {String}
   * @private
   */
  _color: computed('status', {
    get() {
      if (ABORTED_CAMPAIGN_STATES.includes(this.status)) {
        return 'red';
      }

      if (PENDING_CAMPAIGN_STATES.includes(this.status)) {
        return 'secondary';
      }

      if (this.status === CAMPAIGN_STATES.DRAFT) {
        return 'yellow';
      }

      return 'green';
    }
  }).readOnly(),

  /**
   * computed status text
   *
   * @computed _statusText
   * @type {String}
   * @private
   */
  _statusText: computed('status', {
    get() {
      if (isEmpty(this.status)) {
        return '';
      }

      return this.intl.t(`routes.campaigns.status.${this.status}`);
    }
  }).readOnly()
});
