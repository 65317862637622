import Model, { attr } from '@ember-data/model';
import { A } from '@ember/array';

export default Model.extend({
  key: attr('string'),
  order: attr('number'),
  properties: attr(),
  settings: attr(),

  contentBlockVariations: attr({
    defaultValue() {
      return A();
    }
  })
});
