import { Factory } from 'ember-cli-mirage';

export default Factory.extend({
  totalRows: () => 2,
  columns: () => {
    return [
      {
        content: 'ahoibrause@duka.it, mimi@email.com',
        property: 'email',
        id: 'A'
      }
    ];
  }
});
