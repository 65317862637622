import RESTSerializer from '@ember-data/serializer/rest';

import { SUPPORTED_LANGUAGES } from 'additive-newsletter/utils/constants/languages';

export default RESTSerializer.extend({
  isNewSerializerAPI: true,

  modelNameFromPayloadKey(payloadKey, ...args) {
    if (payloadKey === 'dynamicGroups' || payloadKey === 'staticGroups') {
      payloadKey = 'groups';
    }
    return this._super(payloadKey, ...args);
  },

  serialize() {
    let json = this._super(...arguments);
    if (json.resendTime) {
      json.resendTime = `${timeToUTC(json.resendTime)}:00`;
    }
    return json;
  },

  normalizeResponse(store, primaryModelClass, payload) {
    const templates = payload.templates ? payload.templates : [payload.template];

    // transform resendTime property
    templates.forEach((template) => {
      // sort available languages
      template.availableLanguages = SUPPORTED_LANGUAGES.filter(
        (language) => template.availableLanguages.indexOf(language) != -1
      );

      if (template.resendTime) {
        template.resendTime = timeFromUTC(template.resendTime.slice(0, 5));
      }
    });
    return this._super(...arguments);
  },

  serializeIntoHash(data, type, record, options) {
    Object.assign(data, this.serialize(record, options));
  }
});

/**
 * returns the UTC time string from an hh:mm formatted string
 *
 * @function timeToUTC
 * @param {String} time
 * @returns {String}
 */
function timeToUTC(time) {
  const date = new Date();
  const utcOffset = 24 - Math.floor(date.getTimezoneOffset() / 60) * -1;

  const timeParts = time.split(':');
  let hours = (parseInt(timeParts[0]) + utcOffset) % 24;
  hours = `0${hours}`.slice(-2);

  return `${hours}:${timeParts[1]}`;
}

/**
 * returns the current time string from an hh:mm formatted UTC time sting
 *
 * @function timeFromUTC
 * @param {String} time
 * @returns {String}
 */
function timeFromUTC(time) {
  const date = new Date();
  const utcOffset = 24 + Math.floor(date.getTimezoneOffset() / 60) * -1;

  const timeParts = time.split(':');
  let hours = (parseInt(timeParts[0]) + utcOffset) % 24;
  hours = `0${hours}`.slice(-2);

  return `${hours}:${timeParts[1]}`;
}
