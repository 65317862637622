import Controller from '@ember/controller';

import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

import { nextTick } from '@additive-apps/ui/utils/dom-util';
import { encodeSortString } from '@additive-apps/ui/utils/sort-util';

export default class InstanceAddressBooksAddressBookSubscribersController extends Controller {
  @service intl;
  @service router;
  @service uiFilter;

  /**
   * the selection object
   *
   * @property selection
   * @type {Object}
   */
  @tracked
  selection = null;

  @tracked
  sortState = null;

  /**
   * computed boolean whether the detail route is closed
   *
   * @computed isDetailClosed
   * @type {Boolean}
   */
  get isDetailClosed() {
    return (
      !this.router.isActive('instance.address-books.address-book.subscribers.subscriber.edit') &&
      !this.router.isActive('instance.address-books.address-book.subscribers.subscriber.index')
    );
  }

  @action
  openDetail(id) {
    this.router.transitionTo('instance.address-books.address-book.subscribers.subscriber', id);
  }

  @action
  setSelection(selection) {
    nextTick(() => {
      this.selection = selection;
    });
  }

  @action
  onSort(_, column, direction) {
    const { key } = column;

    this.uiFilter.filter(
      'address-book-subscribers',
      'sort',
      encodeSortString(key, direction),
      false
    );
    this.sortState = { key, direction };
  }
}
