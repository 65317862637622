import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class InstanceDashboardRoute extends Route {
  @service intl;

  resetController(controller) {
    // Reset period select
    controller.period = {
      name: this.intl.t(`dashboard.periodSelection.last_12_months`),
      value: 'past_twelve_months'
    };

    super.resetController(...arguments);
  }
}
