import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<table class=\"mt3 font-sm fit-content-table\">\n  <tbody>\n    <AnlCampaigns::SendDialog::HeaderListItem\n      @title={{@title}}\n      @actionColumnLabel={{@actionColumnLabel}}\n      @isLoading={{@isLoading}}\n    />\n    {{yield}}\n  </tbody>\n</table>", {"contents":"<table class=\"mt3 font-sm fit-content-table\">\n  <tbody>\n    <AnlCampaigns::SendDialog::HeaderListItem\n      @title={{@title}}\n      @actionColumnLabel={{@actionColumnLabel}}\n      @isLoading={{@isLoading}}\n    />\n    {{yield}}\n  </tbody>\n</table>","moduleName":"additive-newsletter/components/anl-campaigns/send-dialog/send-dialog-table.hbs","parseOptions":{"srcName":"additive-newsletter/components/anl-campaigns/send-dialog/send-dialog-table.hbs"}});
import Component from '@glimmer/component';
import { arg } from 'ember-arg-types';
import { string } from 'prop-types';

export default class SendDialogTable extends Component {
  /**
   * The title of the table
   *
   * @argument title
   * @type {String}
   * @default null
   */
  @arg(string)
  title = null;

  /**
   * The label of the action column
   *
   * @argument actionColumnLabel
   * @type {String}
   * @default null
   */
  @arg(string)
  actionColumnLabel = null;
}
