import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { SENT_CAMPAIGN_STATES, ABORTED_CAMPAIGN_STATES } from 'additive-newsletter/utils/constants';

export default Route.extend({
  store: service(),
  uiFilter: service(),

  setupController() {
    this.uiFilter.register('sent-campaigns', 'campaign', {
      paginated: true,
      queryParams: {
        'status[in]': {
          defaultValue: [...SENT_CAMPAIGN_STATES, ...ABORTED_CAMPAIGN_STATES].join(',')
        },
        sort: {
          defaultValue: '-sendAt'
        }
      }
    });
  },

  resetController() {
    this.uiFilter.unregister('sent-campaigns');
  }
});
