/**
 * Routes for the report charts
 * @module routes: instance/reports/report
 */

/**
 * @param {*} self
 */
export const GenderChart = (self) => {
  self.get('/reports/:id/gender-chart', () => {
    return {
      genderChart: {
        type: 'single',
        datasets: [
          { label: 'openedUniqueTotal', format: 'number', data: [170, 1223, 2304] },
          { label: 'clickedUniqueTotal', format: 'number', data: [250, 1620, 1880] }
        ],
        labels: ['noGender', 'male', 'female']
      }
    };
  });
};

/**
 * @param {*} self
 */
export const ClientChart = (self) => {
  self.get('/reports/:id/mail-client-chart', () => {
    return {
      mailClientChart: {
        type: 'single',
        datasets: [
          { label: 'open', format: 'number', data: [7260, 2100, 736, 594, 499, 440, 401] }
        ],
        labels: [
          'Mobile Safari',
          'Chrome Mobile 79.0',
          'Chrome 79.0',
          'Microsoft Outlook 16.0',
          'Internet Explorer 11.0',
          'Microsoft Outlook 15.0',
          'Microsoft Outlook 14.0'
        ]
      }
    };
  });
};

/**
 * @param {*} self
 */
export const MobileChart = (self) => {
  self.get('/reports/:id/device-chart', () => {
    return {
      deviceChart: {
        type: 'single',
        datasets: [{ label: 'open', format: 'number', data: [52.7, 47.3] }],
        labels: ['mobile', 'desktop']
      }
    };
  });
};
