import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task, all, timeout } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { PENDING_CAMPAIGN_STATES } from 'additive-newsletter/utils/constants';

export default class InstanceReportsReportController extends Controller {
  @service router;

  /**
   * the report model
   *
   * @argument report
   * @type {Object}
   */
  @tracked
  report = null;

  /**
   * whether an error has occured
   *
   * @argument isError
   * @type {Boolean}
   */
  @tracked isError = false;

  /**
   * the id of the report to be fetched
   *
   * @argument reportId
   * @type {String}
   */
  @tracked reportId = null;

  /**
   * the route of the campaign, depending on whether its status is pending or not
   *
   * @property campaignRoute
   * @type {String}
   */
  get campaignRoute() {
    if (PENDING_CAMPAIGN_STATES.includes(this.report.campaignStatus)) {
      return 'instance.campaigns.index.campaign.index';
    }

    return 'instance.campaigns.sent.campaign';
  }

  /**
   * fetches a report by id
   *
   * @function fetchReport
   * @type {Task}
   */
  @task(function* (id) {
    this.isError = false;
    try {
      const tasks = [];
      tasks.push(this.store.findRecord('report', id));
      tasks.push(timeout(400));

      const [report] = yield all(tasks);

      this.report = report;
    } catch (e) {
      this.isError = true;
    }
  })
  fetchReport;

  @action
  back() {
    return this.router.transitionTo('instance.reports');
  }
}
