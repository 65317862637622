import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class InstanceStylesIndexStyleBlockIndexController extends Controller {
  @service aeSaveStack;
  @service currentUser;

  @action
  toggleVariationActive(variation) {
    variation.active = !variation.active;
    this.aeSaveStack.pushChanges(variation);
  }
}
