import Route from '@ember/routing/route';

export default Route.extend({
  renderTemplate() {
    this.render('instance/address-books/address-book/subscribers/subscriber/edit', {
      into: 'instance/address-books/address-book/subscribers/subscriber',
      outlet: 'edit'
    });
  },

  model() {
    return this._super(...arguments);
  }
});
