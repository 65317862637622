/**
 * removes properties from app blocks which can not be edited
 *
 * @function removeUnnecessaryProperties
 * @param {Object} properties
 * @return {Object} reducedProperties
 */
export const removeUnnecessaryProperties = (properties) => {
  const unnecessaryProperties = [
    'locale',
    'offerSlug',
    'information',
    'price',
    'multipleOffersMessage',
    'offerPeriod',
    'offerStart',
    'offerEnd',
    'stays',
    'couponType',
    'activationCode',
    'discountedPrice',
    'discountPercentage',
    'authorName',
    'authorRole',
    'topicName',
    'authorImage',
    'publishedAt'
  ];

  unnecessaryProperties.forEach((property) => {
    delete properties[property];
  });

  return properties;
};
