import { Factory } from 'ember-cli-mirage';
import faker from 'faker';

export default Factory.extend({
  id: () => faker.lorem.word(),
  status: () => 'Pending',
  displayName: () => faker.lorem.word(),
  thumbnail: () => 'https://loremflickr.com/210/707',
  screenshot: () => 'https://loremflickr.com/210/707'
});
