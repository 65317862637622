import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  class=\"w-100 h-100\"\n  {{did-insert (perform this.renderBlock)}}\n  {{did-update (perform this.renderBlock) this.styleBlock.id this.blocks @update}}\n>\n  {{#if this.isError}}\n    <div class=\"w-100 text-center font-sm red\">\n      {{t \"routes.styles.style.preview.error\"}}\n    </div>\n  {{else}}\n    <iframe\n      id=\"preview-frame\"\n      title=\"iframe\"\n      srcdoc={{this._previewHtml}}\n      class=\"w-100 h-100\"\n    ></iframe>\n  {{/if}}\n</div>", {"contents":"<div\n  class=\"w-100 h-100\"\n  {{did-insert (perform this.renderBlock)}}\n  {{did-update (perform this.renderBlock) this.styleBlock.id this.blocks @update}}\n>\n  {{#if this.isError}}\n    <div class=\"w-100 text-center font-sm red\">\n      {{t \"routes.styles.style.preview.error\"}}\n    </div>\n  {{else}}\n    <iframe\n      id=\"preview-frame\"\n      title=\"iframe\"\n      srcdoc={{this._previewHtml}}\n      class=\"w-100 h-100\"\n    ></iframe>\n  {{/if}}\n</div>","moduleName":"additive-newsletter/components/anl-style-preview.hbs","parseOptions":{"srcName":"additive-newsletter/components/anl-style-preview.hbs"}});
import ENV from 'additive-newsletter/config/environment';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { underscore } from '@ember/string';

import { object, array } from 'prop-types';
import { arg } from 'ember-arg-types';
import { task, timeout, waitForProperty } from 'ember-concurrency';
import cloneDeep from 'lodash.clonedeep';

import {
  formatBlock,
  getColors,
  getTypography
} from 'additive-newsletter/utils/block-template-utils';
import merge from 'lodash.merge';

export default class AnlStylePreviewComponent extends Component {
  @service anlCd;
  @service authenticatedFetch;
  @service store;

  @arg(object)
  @tracked
  styleBlock = null;

  @arg(array)
  @tracked
  blocks = null;

  @arg(object)
  @tracked
  style = null;

  @arg(object)
  @tracked
  styleBlocks = null;

  @tracked
  previewEl = null;

  @tracked
  isError = false;

  /**
   * the corporate design of the organization
   *
   * @argument corporateDesign
   * @type {Object}
   * @default null
   */
  @arg(object)
  @tracked
  corporateDesign = null;

  /**
   * the fetched preview HTML
   *
   * @property _previewHtml
   * @type {String}
   * @default null
   * @private
   */
  @tracked
  _previewHtml = null;

  get colors() {
    return getColors(merge({}, this.style.styles.colors));
  }

  get typography() {
    return getTypography(merge({}, this.style.styles.typography), this.colors, this.anlCd);
  }

  @(task(function* () {
    this.isError = false;

    if (!this.blocks?.length && !this.styleBlock) {
      return;
    }

    // helper function to change all object keys to snake_case
    function snakeCasePropertyKeys(object, result = {}) {
      if (Array.isArray(object)) {
        result = [];
        object.forEach((entry) => {
          result.push(snakeCasePropertyKeys(entry, {}));
        });
        return result;
      } else if (typeof object === 'object') {
        Object.keys(object).forEach((key) => {
          result[underscore(key)] =
            object[key] && typeof object[key] === 'object'
              ? snakeCasePropertyKeys(object[key], result[underscore(key)])
              : object[key];
        });
      } else {
        return object;
      }
      return result;
    }

    function toKeyValue(property, anlCd) {
      const newObject = {};
      property.forEach((styleGroup) => {
        styleGroup.properties.forEach((property) => {
          if (property.sync) {
            if (property.type === 'typography') {
              newObject[property.id] = anlCd.corporateDesignTypography[property.syncedValue];
              newObject[property.id]['weight'] =
                anlCd.corporateDesignTypography[property.syncedValue]['weight'] || 400;
            } else {
              newObject[property.id] = property.syncedValue;
            }
          } else {
            newObject[property.id] = property.value;
          }
        });
      });
      return newObject;
    }

    // debounce rerender
    yield timeout(400);

    yield waitForProperty(this.anlCd, 'corporateDesignColors');

    const corporateDesign = {
      colors: this.anlCd.corporateDesignColors,
      typography: this.anlCd.corporateDesignTypography,
      fonts: this.anlCd.fonts
    };

    try {
      if (!this.contentElement) {
        const container = document.getElementById('preview-frame');
        if (!container) return;
        this.previewEl = container;
      }

      const blocks = this.blocks?.length
        ? this.blocks.map((b) =>
            formatBlock(
              b.serialize({ includeId: true }),
              this.typography,
              this.colors,
              this.anlCd,
              this.style
            )
          )
        : [
            formatBlock(
              this.styleBlock.serialize({ includeId: true }),
              this.typography,
              this.colors,
              this.anlCd
            )
          ];

      const activeStyleBlocks = this.store
        .peekAll('style-block')
        .map((styleBlock) => {
          if (!styleBlock.active) {
            return null;
          }

          const blockData = styleBlock.toJSON();
          blockData.style = toKeyValue(blockData.style, this.anlCd);
          if (blockData.content) {
            blockData.content = toKeyValue(blockData.content, this.anlCd);
          }

          return blockData;
        })
        .filter((block) => block);

      const renderRequestBody = {
        blocks,
        corporateDesign,
        layout: 'index',
        style: Object.assign({}, cloneDeep(this.style?.styles), { blocks: activeStyleBlocks })
      };

      if (renderRequestBody.style?.globals?.bulletPoint?.value) {
        renderRequestBody.style.globals.bulletPoint =
          renderRequestBody.style.globals.bulletPoint.value;
      }
      const snakeCasedRequestBody = {};
      snakeCasePropertyKeys(renderRequestBody, snakeCasedRequestBody);

      const response = yield fetch(`${ENV.APP.renderHost}/layout`, {
        method: 'POST',
        body: JSON.stringify(snakeCasedRequestBody)
      });

      if (!response.ok) {
        throw new Error('Response not ok');
      }

      const html = yield response.text();

      // disable interaction on preview
      const parser = new DOMParser();
      const parsedHtml = parser.parseFromString(html, 'text/html');
      const headTag = parsedHtml?.documentElement.getElementsByTagName('head');
      if (headTag.length > 0) {
        const styleTag = document.createElement('style');
        headTag[0].appendChild(styleTag);
        styleTag.appendChild(
          document.createTextNode(`
            body {
              position: relative;
            }

            body:after {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              z-index: 1;
              pointer-events: all;
            };
          `)
        );
      }

      this._previewHtml = parsedHtml.documentElement.innerHTML;
    } catch (e) {
      this.isError = true;
      throw new Error(e);
    }
  }).restartable())
  renderBlock;
}
