import Model, { attr, belongsTo } from '@ember-data/model';
import { A } from '@ember/array';
import { computed, set } from '@ember/object';
import { equal, or } from '@ember/object/computed';
import cloneDeep from 'lodash.clonedeep';

import { CAMPAIGN_STATES } from 'additive-newsletter/utils/constants';

export default Model.extend({
  name: attr('string'),
  html: attr('string'),

  subject: attr('string'),
  previewText: attr('string'),
  senderEmail: attr('string'),
  senderName: attr('string'),
  styleId: attr('string'),
  styleName: attr('string'),
  replyTo: attr('string'),
  trackCampaignName: attr('string'),

  // resend props
  resendAfterDays: attr('string'),
  resendTime: attr('string'),
  resendSubject: attr('string'),
  resendAt: attr('date'),
  resendSubscriberCount: attr('number'),
  resentAt: attr('date'),

  subscriberCount: attr('number'),
  excludeCount: attr('number', { defaultValue: 0 }),
  openRate: attr('number'),
  clickRate: attr('number'),
  addressBookName: attr('string'),
  addressBookId: attr('string'),
  addressBook: belongsTo('addressBook', { async: false }),
  language: attr('string'),
  version: attr('number'),
  groups: attr({
    defaultValue() {
      return A();
    }
  }),
  filterId: attr('number'),
  excludeFilterId: attr('number'),
  excludeGroups: attr({
    defaultValue() {
      return A();
    }
  }),

  isTimeShifted: attr('boolean'),
  trackClicks: attr('boolean', { defaultValue: true }),
  trackGoogleAnalytics: attr('boolean', { defaultValue: true }),
  trackOpenings: attr('boolean', { defaultValue: true }),
  resend: attr('boolean'),
  importAlreadyExists: attr('boolean'),

  createdAt: attr('date'),
  updatedAt: attr('date'),

  sendAt: attr('date'),
  sentAt: attr('date'),
  sendingAbortedAt: attr('date'),

  previewUrl: attr('string'),
  reportId: attr('string'),
  status: attr('number'),

  languages: attr({
    defaultValue() {
      return A();
    }
  }),

  groupNames: attr({
    defaultValue() {
      return A();
    }
  }),

  contentBlocks: attr({
    defaultValue() {
      return A();
    }
  }),

  /* used for rollback of content blocks */
  _oldContentBlocks: A(),

  isStatusDraft: equal('status', CAMPAIGN_STATES.DRAFT),
  isStatusScheduled: equal('status', CAMPAIGN_STATES.SCHEDULED),
  isStatusSending: equal('status', CAMPAIGN_STATES.SENDING),
  isStatusSent: equal('status', CAMPAIGN_STATES.SENT),
  isStatusResending: equal('status', CAMPAIGN_STATES.RESENDING),
  isStatusResent: equal('status', CAMPAIGN_STATES.RESENT),
  isStatusSendingAborted: equal('status', CAMPAIGN_STATES.SENDING_ABORTED),
  isStatusSendingFailedNoSubscribers: equal(
    'status',
    CAMPAIGN_STATES.SENDING_FAILED_NO_SUBSCRIBERS
  ),
  isStatusSendingFailedNoValidEmail: equal('status', CAMPAIGN_STATES.SENDING_FAILED_NO_VALID_EMAIL),
  isStatusResendingAborted: equal('status', CAMPAIGN_STATES.RESENDING_ABORTED),
  isSendingFailed: or('isStatusSendingFailedNoSubscribers', 'isStatusSendingFailedNoValidEmail'),
  isDeletable: or(
    'isStatusDraft',
    'isStatusSent',
    'isStatusSendingAborted',
    'isStatusResent',
    'isSendingFailed'
  ),

  /**
   * whether the content blocks have been edited
   *
   * @computed hasDirtyContentBlockAttributes
   * @type {Boolean}
   */
  hasDirtyContentBlockAttributes: computed(
    '_oldContentBlocks.[]',
    'contentBlocks.{[],@each.properties}',
    {
      get() {
        const contentBlocks = this.contentBlocks;
        const oldContentBlocks = this._oldContentBlocks;

        if (contentBlocks.length !== oldContentBlocks.length) {
          return true;
        }

        for (let i = 0; i < contentBlocks.length; i++) {
          const contentBlock = contentBlocks[i];
          const oldContentBlock = oldContentBlocks[i];

          if (JSON.stringify(contentBlock) !== JSON.stringify(oldContentBlock)) {
            return true;
          }
        }

        return false;
      }
    }
  ).readOnly(),

  rollbackAttributes() {
    this._super(...arguments);

    const oldContentBlocks = this._oldContentBlocks;

    set(this, 'contentBlocks', cloneDeep(oldContentBlocks));
    this._updateOldContentBlocks();
  },

  didLoad() {
    this._super(...arguments);

    this._updateOldContentBlocks();
  },

  didUpdate() {
    this._super(...arguments);

    this._updateOldContentBlocks();
  },

  _updateOldContentBlocks() {
    set(this, '_oldContentBlocks', cloneDeep(this.contentBlocks));
  }
});
