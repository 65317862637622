import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class InstanceAddressBooksAddressBookGroupsNewController extends Controller {
  @service router;

  /**
   * model containing the current address book and group
   *
   * @argument model
   * @type {Model}
   * @default null
   */
  model = null;

  /**
   * the current address book
   *
   * @computed addressBook
   * @type {Object}
   */
  get addressBook() {
    return this.model?.addressBook;
  }

  /**
   * the current group
   *
   * @computed group
   * @type {Object}
   */
  get group() {
    return this.model?.group;
  }

  @action
  onClose() {
    if (this.group && this.group.id) {
      return this.router.transitionTo(
        'instance.address-books.address-book.groups.group',
        this.group.id
      );
    }

    return this.router.transitionTo('instance.address-books.address-book.groups');
  }
}
