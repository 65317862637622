import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import merge from 'lodash.merge';

import { NL_COLOR_VARIABLES } from 'additive-newsletter/utils/constants';

export default class InstanceStylesIndexStyleIndexController extends Controller {
  @service anlCd;
  @service authenticatedFetch;
  @service intl;
  @service currentUser;
  @service aeSaveStack;

  @tracked
  activeTab = this.tabs[0];

  colorVariables = NL_COLOR_VARIABLES;

  @tracked
  tabs = [
    { title: this.intl.t('configurator.general.title'), name: 'general' },
    { title: this.intl.t('configurator.colors.title'), name: 'colors' },
    {
      title: this.intl.t('configurator.typography.title'),
      name: 'typography'
    }
  ];

  get organizationSlug() {
    return this.currentUser?.currentOrganization?.id;
  }

  get _model() {
    if (!this.model || !this.anlCd.corporateDesignColors) {
      return null;
    }

    Object.keys(this.model.styles.colors).forEach((key) => {
      const syncedColor = this.model.styles.colors[key].syncedValue;

      this.model.styles.colors[key].syncedColor =
        this.anlCd.corporateDesignColors[syncedColor].color;
      this.model.styles.colors[key].syncedContrastColor =
        this.anlCd.corporateDesignColors[syncedColor].contrastColor;
    });

    return this.model;
  }

  @action
  async onUpdate(key, value) {
    if (value && typeof value === 'object') {
      const oldValue = this.model.get(`styles.${key}`);
      if (oldValue && typeof oldValue === 'object') {
        value = merge({}, oldValue, value);
      }
    }

    // create nested object from key with dot notation
    const nestedObject = {};
    key.split('.').reduce((aggregator, part, index, parts) => {
      aggregator[part] = index === parts.length - 1 ? value : {};
      return aggregator[part];
    }, nestedObject);

    this.model.set(`styles`, merge({}, this.model.styles, nestedObject));

    const currentStyleBlockChanges = [];
    this.aeSaveStack._stack.forEach((changedModel) => {
      if (changedModel.constructor?.modelName !== 'style-block') {
        return;
      }

      currentStyleBlockChanges.push(changedModel.serialize({ includeId: true }));
    });

    currentStyleBlockChanges.forEach((currentStyleBlockChange) => {
      const storeModel = this.store.peekRecord('style-block', currentStyleBlockChange.id);

      const newStyle = [];
      currentStyleBlockChange.style.forEach((styleGroup) => {
        const style = {
          id: styleGroup.id,
          name: styleGroup.name,
          properties: []
        };
        styleGroup.properties.forEach((styleProperty) => {
          const changes = { value: styleProperty.value };
          if (Object.keys(styleProperty).indexOf('sync') > -1) {
            changes.sync = styleProperty.sync;
          }

          style.properties.push(Object.assign({}, styleProperty, changes));
        });

        newStyle.push(style);
      });

      storeModel.set('style', newStyle);
    });

    await this.model.save({
      adapterOptions: {
        isPreview: true
      }
    });

    this.aeSaveStack.pushChanges(this.model);
  }
}
