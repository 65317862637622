import ApplicationAdapter from 'additive-newsletter/adapters/application';

import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';

export default class AddressBookSubscriberAdapter extends ApplicationAdapter {
  @service uiAppSettings;

  urlForMultiple(modelName, snapshot, useGroup = true) {
    let url = this.buildURL(...arguments);

    const addressBookId = snapshot.adapterOptions.addressBookId;
    const groupId = snapshot.adapterOptions.groupId;

    if (isPresent(addressBookId)) {
      url = this.buildURL('address-book', addressBookId);

      // group endpoint is only used for GET-requests
      if (useGroup && isPresent(groupId)) {
        url += `/groups/${groupId}/subscribers`;
      } else {
        url += '/subscribers';
      }
    }

    return url;
  }

  urlForSingle(id, modelName, snapshot) {
    return `${this.urlForMultiple(modelName, snapshot, false)}/${id}`;
  }

  urlForQuery(query) {
    let url = this.buildURL();

    let addressBookId = query['addressBook.id'];
    let groupId = query['group.id'];

    delete query['addressBook.id'];
    delete query['group.id'];

    if (isPresent(addressBookId) || isPresent(groupId)) {
      url = this.buildURL('addressBook', addressBookId);

      if (isPresent(groupId)) {
        url += `/groups/${groupId}`;
      }

      url += '/subscribers';
    }

    return url;
  }

  urlForCreateRecord() {
    return this.urlForMultiple(...arguments, false);
  }

  urlForUpdateRecord() {
    return this.urlForSingle(...arguments);
  }

  urlForFindRecord() {
    return this.urlForSingle(...arguments);
  }

  urlForDeleteRecord() {
    return this.urlForSingle(...arguments);
  }
}
