import Route from '@ember/routing/route';
import { set } from '@ember/object';

export default Route.extend({
  setupController(controller) {
    this._super(...arguments);

    controller.fetchIntegrationSettings.perform();
  },

  resetController(controller) {
    set(controller, 'isPmsIntegrationDialog', false);
    set(controller, 'isEnquiryIntegrationDialog', false);
    set(controller, 'isFacebookLeadIntegrationDialog', false);
    set(controller, 'isOnPageLeadIntegrationDialog', false);
    set(controller, 'isTikTokLeadIntegrationDialog', false);
  }
});
