import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<UiModal @title={{this._title}} @size=\"md\" @onClose={{this._onClose}} as |modal|>\n  <modal.header />\n  <modal.content as |content|>\n    <content.body>\n      <UiInput\n        @label={{t \"components.anlAddressBook.groups.edit.label.name\"}}\n        @value={{this._changeset.name}}\n        @errorMessage={{get this._changeset.error.name \"validation\"}}\n      />\n    </content.body>\n  </modal.content>\n\n  <modal.footer>\n    <UiButton\n      @label={{t \"global.actions.save\"}}\n      @isSecondary={{true}}\n      @onClick={{perform this._saveGroup}}\n    />\n  </modal.footer>\n</UiModal>", {"contents":"<UiModal @title={{this._title}} @size=\"md\" @onClose={{this._onClose}} as |modal|>\n  <modal.header />\n  <modal.content as |content|>\n    <content.body>\n      <UiInput\n        @label={{t \"components.anlAddressBook.groups.edit.label.name\"}}\n        @value={{this._changeset.name}}\n        @errorMessage={{get this._changeset.error.name \"validation\"}}\n      />\n    </content.body>\n  </modal.content>\n\n  <modal.footer>\n    <UiButton\n      @label={{t \"global.actions.save\"}}\n      @isSecondary={{true}}\n      @onClick={{perform this._saveGroup}}\n    />\n  </modal.footer>\n</UiModal>","moduleName":"additive-newsletter/components/anl-address-book/groups/edit.hbs","parseOptions":{"srcName":"additive-newsletter/components/anl-address-book/groups/edit.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import { arg } from 'ember-arg-types';
import { bool, func, object } from 'prop-types';
import { task } from 'ember-concurrency';
import Changeset from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';

import { getAdapterError } from '@additive-apps/utils/utils/errors';

import { groupValidation } from 'additive-newsletter/validations/group';

/**
 * Handles create and edit of groups
 *
 * @module anl-address-book/groups
 * @class AnlAddressBookGroupsEdit
 */
export default class AnlAddressBookGroupsEdit extends Component {
  @service intl;
  @service router;
  @service store;
  @service uiDialog;
  @service uiToast;

  /**
   * the addressbook the group belongs to
   *
   * @argument addressBook
   * @type {Model}
   * @default null
   */
  @arg(object)
  addressBook = null;

  /**
   * the group model
   *
   * @argument group
   * @type {Model}
   * @default null
   */
  @arg(object)
  group = null;

  /**
   * whether this component is used in the create route
   *
   * @argument isCreate
   * @type {Boolean}
   * @default false
   */
  @arg(bool)
  isCreate = false;

  /**
   * changeset for the group to be edited
   *
   * @argument _changeset
   * @type {Object}
   * @default null
   * @private
   */
  @tracked
  _changeset = null;

  /**
   * computed adapter options
   *
   * @computed _adapterOptions
   * @type {Object}
   */
  get _adapterOptions() {
    return {
      adapterOptions: {
        addressBook: this.addressBook?.id
      }
    };
  }

  get _title() {
    if (this.isCreate) {
      return this.intl.t('components.anlAddressBook.groups.edit.create');
    }

    return this.intl.t('components.anlAddressBook.groups.edit.edit');
  }

  /**
   * saves changes on group
   *
   * @function _saveGroup
   * @type {Task}
   */
  @task(function* () {
    try {
      const changeset = this._changeset;
      yield changeset.validate();
      if (changeset.get('isValid')) {
        yield changeset.save(this._adapterOptions);
        this._changeset = null;

        this.uiToast.showToast({
          type: 'success',
          title: this.intl.t('global.toast.success.savedChanges')
        });

        if (!this.router.isActive('instance.address-books.address-book.groups.group')) {
          this.router.transitionTo('instance.address-books.address-book.groups');
        }
        this.onClose();
      }
    } catch (e) {
      const error = getAdapterError(e);
      if (error === 'This value is already used.') {
        this._changeset.pushErrors('name', this.intl.t('errors.nameExists'));
        return;
      }

      this.uiToast.showToast({
        type: 'error',
        title: this.intl.t('global.toast.error.savedChanges')
      });
    }
  })
  _saveGroup;

  constructor() {
    super(...arguments);

    const validation = groupValidation(this.intl);
    this._changeset = new Changeset(this.group, lookupValidator(validation), validation);
  }

  @arg(func)
  onClose = () => {};

  @action
  _onClose() {
    if (this._changeset && this._changeset.get('isDirty')) {
      this.uiDialog.showDiscardChangesConfirm(() => {
        this._changeset.rollback();
        this.group.rollbackAttributes();
        this._changeset = null;
        this.onClose();
      });
      return;
    }

    this._changeset = null;
    this.onClose();
  }
}
