import ENV from 'additive-newsletter/config/environment';

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { set } from '@ember/object';
import AuthRouteMixin from '@additive-apps/auth/mixins/auth-route-mixin';

export default class IndexRoute extends Route.extend(AuthRouteMixin) {
  @service authenticatedFetch;
  @service uiAppSettings;
  @service currentUser;
  @service session;
  @service store;
  @service uiLocale;

  async model() {
    super.model(...arguments);

    const currentOrganizationId = this.currentUser?.currentOrganization?.id;

    if (currentOrganizationId) {
      return this.transitionTo('instance', currentOrganizationId);
    }

    const has_app = ENV.APP.appId;

    const organizations = await this.store.query('organization', { has_app });
    const firstOrganization = organizations.get('firstObject');
    set(this, 'currentUser.currentOrganization', firstOrganization);

    // pass the first organization id to run the model hook of the instance route
    this.transitionTo('instance', firstOrganization.id);
  }
}
