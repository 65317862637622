import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"flex flex-column font-sm ph2\">\n  <span class=\"mb05\">\n    {{this.value}}\n  </span>\n  <span class=\"secondary\">\n    {{this.description}}\n  </span>\n</div>", {"contents":"<div class=\"flex flex-column font-sm ph2\">\n  <span class=\"mb05\">\n    {{this.value}}\n  </span>\n  <span class=\"secondary\">\n    {{this.description}}\n  </span>\n</div>","moduleName":"additive-newsletter/components/anl-list/info-item.hbs","parseOptions":{"srcName":"additive-newsletter/components/anl-list/info-item.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { arg } from 'ember-arg-types';

/**
 * @module anl-list
 * @class AnlListInfoItem
 */
export default class AnlListInfoItem extends Component {
  @arg
  @tracked
  value = null;

  @arg
  @tracked
  description = null;
}
