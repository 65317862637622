import { validatePresence, validateFormat } from 'ember-changeset-validations/validators';
import validateIf from '@additive-apps/utils/validators/validate-if';

export default (intl) => {
  const presenceMessage = intl.t('errors.required');
  const emailMessage = intl.t('errors.email');

  return {
    email: [
      validatePresence({ presence: true, message: presenceMessage }),
      validateFormat({ type: 'email', message: emailMessage })
    ],
    language: validatePresence({ presence: true, message: presenceMessage }),
    // conditionally validate only if salutation is custom
    customSalutation: validateIf({
      if: { salutation: 'custom' },
      validate: validatePresence({ presence: true, message: presenceMessage }),
      firstErrorMessage: true
    })
  };
};
