import ENV from 'additive-newsletter/config/environment';

import Controller from '@ember/controller';
import { action } from '@ember/object';
import { bind } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';

import { ACCEPT_HEADER, CAMPAIGN_STATES } from 'additive-newsletter/utils/constants';
import { abortCampaign, retryCampaign } from 'additive-newsletter/utils/campaign-utils';

export default class InstanceCampaignsIndexCampaignController extends Controller {
  @service authenticatedFetch;
  @service currentUser;
  @service uiFilter;
  @service uiDialog;
  @service uiState;
  @service uiStoreEvents;
  @service uiToast;
  @service router;
  @service store;
  @service intl;

  /**
   * the current campaign model
   *
   * @property model
   * @type {Model}
   * @default null
   */
  @tracked
  model = null;

  /**
   * whether a mobile device is used
   *
   * @argument isMobile
   * @type {Boolean}
   * @default false
   */
  @tracked
  isMobile = false;

  /**
   * the callback function for the window resize event listener
   *
   * @property resizeListener
   * @type {Function}
   */
  @tracked
  resizeListener = () => {};

  @tracked
  _isCreateFromModelDialog = false;

  @tracked
  _isDuplicateDialog = false;

  @tracked
  _showSendTestDialog = false;

  get _apiBaseUrl() {
    return `${ENV.APP.apiBaseHost}/${this.currentUser.currentOrganization.id}/campaigns/${this.model.id}`;
  }

  get isDraft() {
    return this.model.status === CAMPAIGN_STATES.DRAFT;
  }

  get isDesktop() {
    return !this.isMobile;
  }

  get isViewer() {
    return this.currentUser.isViewer;
  }

  get hasEditView() {
    return this.isDraft && this.isDesktop && this.isV2;
  }

  get isEditEnabled() {
    return this.isDraft && this.isDesktop && this.isV2 && !this.isViewer;
  }

  get isDisabled() {
    return this.isDraft;
  }

  get isV2() {
    return this.model.version === 2;
  }

  get isReadOnly() {
    return !this.isEditEnabled;
  }

  /**
   * deletes the active campaign or template
   *
   * @function _deleteModel
   * @type {Task}
   */
  @task(function* () {
    try {
      yield this.model.destroyRecord();

      this.uiFilter.reset('templates');

      this.uiToast.showToast({
        type: 'success',
        title: this.intl.t('global.toast.success.savedChanges'),
        duration: 2000
      });
      this.back();
    } catch (e) {
      this.uiToast.showToast({
        type: 'error',
        title: this.intl.t('global.toast.error.savedChanges'),
        duration: 2000
      });
    }
  })
  _deleteModel;

  setup(model) {
    if (model) {
      this.model = model;
    }

    this.resizeListener = bind(this, this._checkWindow);
    window.addEventListener('resize', this.resizeListener, false);
    this._checkWindow();
  }

  tearDown() {
    window.removeEventListener('resize', this.resizeListener, false);
  }

  /**
   * Checks the current windowsize programmatically and sets the `isMobile`-prop
   * to true if the current window size is lower than `600px`.
   *
   * @function _checkWindow
   * @private
   * @return {void}
   */
  _checkWindow() {
    this.isMobile = !window.matchMedia('(min-width: 600px)').matches;
  }

  @action
  openCreateFromModelDialog() {
    if (!this.model.languages.length) {
      return this.uiDialog.showError(
        this.intl.t('components.anlCampaigns.createFromModelDialog.error.title'),
        this.intl.t('components.anlCampaigns.createFromModelDialog.error.description')
      );
    }
    this._isCreateFromModelDialog = true;
  }

  @action
  edit() {
    this.router.transitionTo('instance.campaigns.index.campaign.edit', this.model.id);
  }

  @action
  back() {
    if (this.router.isActive('instance.campaigns.index.campaign.index')) {
      return this.router.transitionTo('instance.campaigns.index');
    }
    return this.router.transitionTo('instance.campaigns.sent');
  }

  @action
  toggleDetail() {
    this.uiState.getState('campaign-detail').toggle();
  }

  /**
   * Retries the active campaign
   */
  @task(function* () {
    try {
      const response = yield this.authenticatedFetch.fetch(
        `${ENV.APP.apiBaseHost}/${this.currentUser.currentOrganization.id}/campaigns/${this.model.id}/retry`,
        {
          method: 'POST',
          headers: ACCEPT_HEADER
        }
      );

      if (!response || !response.ok) {
        throw new Error();
      }

      const json = yield response.json();
      this.store.pushPayload('campaign', json);

      this.uiStoreEvents.didChange('campaigns');

      this.uiToast.showToast({
        type: 'success',
        title: this.intl.t('global.toast.success.savedChanges')
      });
    } catch (e) {
      this.uiToast.showToast({
        type: 'error',
        title: this.intl.t('global.toast.error.savedChanges')
      });
    }
  })
  retryCampaign;

  @action
  deleteModel() {
    const { intl } = this;
    this.uiDialog.showConfirm(
      intl.t(`routes.campaigns.campaign.dialogs.delete.title`),
      intl.t(`routes.campaigns.campaign.dialogs.delete.description`, {
        name: this.model.name
      }),
      () => this._deleteModel.perform(),
      intl.t('global.actions.delete'),
      true,
      true
    );
  }

  @action
  _abortCampaign() {
    const { intl } = this;

    this.uiDialog.showConfirm(
      intl.t('routes.campaigns.campaign.dialogs.abort.title'),
      intl.t('routes.campaigns.campaign.dialogs.abort.description'),
      () => abortCampaign.task.perform(this),
      intl.t('routes.campaigns.campaign.dialogs.abort.title'),
      true,
      true
    );
  }

  @action
  _retryCampaign() {
    const { intl } = this;

    this.uiDialog.showConfirm(
      intl.t('routes.campaigns.campaign.dialogs.retry.title'),
      intl.t('routes.campaigns.campaign.dialogs.retry.description'),
      () => retryCampaign.task.perform(this),
      intl.t('global.actions.retry'),
      true,
      true
    );
  }

  @action
  onFailedCampaignRetry() {
    this.uiDialog.showConfirm(
      this.intl.t('routes.campaigns.campaign.dialogs.retry.title'),
      this.intl.t('routes.campaigns.campaign.dialogs.retry.description'),
      () => retryCampaign.task.perform(this),
      this.intl.t('global.actions.retry'),
      true,
      true
    );
  }

  @action
  openPreviewUrl() {
    window && window.open(this.model.previewUrl, '_blank');
  }

  @action
  openV2Documentation() {
    window
      .open(
        'https://knowledge.additive.eu/de/knowledge/wie-wechsle-ich-auf-die-neue-newsletter-vorlage',
        '_blank'
      )
      .focus();
  }
}
