import ENV from 'additive-newsletter/config/environment';

import Controller from '@ember/controller';
import { set } from '@ember/object';
import { alias, not } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { camelize } from '@ember/string';
import { task, timeout, all } from 'ember-concurrency';
import Changeset from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';

import cloneDeep from 'lodash.clonedeep';

import { legalSettingsValidation } from 'additive-newsletter/validations/legal-settings';

import { ACCEPT_HEADER } from 'additive-newsletter/utils/constants';

export default Controller.extend({
  authenticatedFetch: service(),
  currentUser: service(),
  intl: service(),
  uiAppSettings: service(),
  uiToast: service(),

  /**
   * whether an error occurred during fetching of data
   *
   * @argument isError
   * @type {Boolean}
   * @default false
   */
  isError: false,

  /**
   * whether the data is being fetched
   *
   * @argument isLoading
   * @type {Boolean}
   */
  isLoading: alias('fetchLegalSettings.isRunning'),

  /**
   * supported languages
   *
   * @argument langugages
   * @type {Array}
   */
  languages: alias('uiAppSettings.languages.contentLanguages'),

  /**
   * the current organization id
   *
   * @argument organizationId
   * @type {String}
   */
  organizationId: alias('currentUser.currentOrganization.id'),

  /**
   * whether settings are read-only
   *
   * @argument isReadOnly
   * @type {Boolean}
   */
  isReadOnly: not('currentUser.isAdmin'),

  /**
   * fetches the legal settings
   *
   * @function fetchLegalSettings
   * @param {String} locale value for accept-language header field
   * @type {Task}
   */
  fetchLegalSettings: task(function* (locale) {
    set(this, 'isError', false);

    const url = `${ENV.APP.apiBaseHost}/${this.organizationId}`;

    try {
      let tasks = [];

      const requestOptions = {
        headers: Object.assign({}, ACCEPT_HEADER, { 'Accept-Language': locale || 'de' })
      };

      const request = yield this.authenticatedFetch.fetch(`${url}/settings/legals`, requestOptions);

      tasks.push(request);
      tasks.push(timeout(250));

      const [response] = yield all(tasks);

      if (!response || !response.ok) {
        throw new Error('[SETTINGS] legal', response);
      }

      const json = yield response.json();
      if (!json || !json.legal) {
        throw new Error('[SETTINGS] legal');
      }

      const { disclaimerLink, privacyLink, language, availableLanguages } = json.legal;

      // set current language and all published languages
      set(this, 'currentLanguage', language);
      set(this, 'availableLanguages', availableLanguages);

      set(this, 'legalResource', {
        privacyLink,
        disclaimerLink
      });
      this.createChangeset();
    } catch (error) {
      set(this, 'isError', true);
    }
  }),

  /**
   * update legal settings
   *
   * @function updateLegals
   * @type {Task}
   */
  updateLegals: task(function* (changeset) {
    yield changeset.validate();
    if (!changeset.get('isValid')) {
      return;
    }

    const url = `${ENV.APP.apiBaseHost}/${this.organizationId}`;
    const changesetSnapshot = changeset.snapshot();

    yield changeset.save();
    const data = yield changeset.get('data');

    // We only want to send the value and sync. Otherwise we get an API error.
    const legalsPayload = {
      disclaimerLink: {
        value: data.disclaimerLink.value,
        sync: data.disclaimerLink.sync
      },
      privacyLink: {
        value: data.privacyLink.value,
        sync: data.privacyLink.sync
      }
    };

    const requestOptions = {
      headers: Object.assign({}, ACCEPT_HEADER, {
        'Accept-Language': this.currentLanguage || 'de'
      }),
      method: 'PUT',
      body: JSON.stringify(legalsPayload)
    };
    const request = yield this.authenticatedFetch.fetch(`${url}/settings/legals`, requestOptions);

    let tasks = [];

    tasks.push(request);
    tasks.push(timeout(250));

    const [response] = yield all(tasks);

    if (!response || !response.ok) {
      if (response.status === 422) {
        const error = yield response.json();

        if (error && error.errors) {
          const firstErrorKey = Object.keys(error.errors)[0];
          const errorKeyParts = firstErrorKey.split('.');

          // apply snapshot of unsaved changes
          this.changeset.restore(changesetSnapshot);
          this.changeset.pushErrors(
            errorKeyParts.map((keyPart) => camelize(keyPart)).join('.'),
            this.intl.t('errors.invalidURL')
          );
        }
      }

      throw new Error('[SETTINGS - UPDATE] legal', response);
    }

    set(this, 'legalResource', changeset.get('data'));

    this.uiToast.showToast({
      title: this.intl.t('global.toast.success.savedChanges'),
      type: 'success'
    });
  }),

  /**
   * activate/deactivate sync
   *
   * @function toggleSync
   * @param {String} name name of changed setting
   * @type {Task}
   */
  toggleSync: task(function* (name) {
    try {
      yield timeout(250);
      const synced = this.changeset.get(`${name}.sync`);
      this.changeset.set(`${name}.sync`, !synced);
      yield this.updateLegals.perform(this.changeset);
      this.createChangeset();
    } catch (error) {
      this.uiToast.showToast({
        title: this.intl.t('global.toast.error.savedChanges'),
        type: 'error'
      });
    }
  }),

  /**
   * deactivate sync and update legals
   *
   * @function changeLegals
   * @param {String} name name of changed setting
   * @type {Task}
   */
  changeLegals: task(function* (name) {
    try {
      yield timeout(250);
      this.changeset.set(`${name}.sync`, false);
      yield this.updateLegals.perform(this.changeset);
    } catch (error) {
      this.uiToast.showToast({
        title: this.intl.t('global.toast.error.savedChanges'),
        type: 'error'
      });

      // push error down to ui-control-panel
      throw new Error(error);
    }
  }),

  createChangeset() {
    const validation = legalSettingsValidation(this.intl);
    const changeset = new Changeset(
      cloneDeep(this.legalResource),
      lookupValidator(validation),
      validation
    );

    set(this, 'changeset', changeset);
  },

  actions: {
    changeLanguage(lang) {
      this.fetchLegalSettings.perform(lang);
    },
    onClose() {
      this.createChangeset();
    }
  }
});
