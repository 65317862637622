import Route from '@ember/routing/route';

export default class InstanceStylesIndexStyleBlockRoute extends Route {
  async model(params) {
    let blocks = this.store.peekAll('style-block');
    if (!blocks?.length) {
      const style = this.modelFor('instance.styles.index.style');
      blocks = await this.store.findAll('style-block', {
        adapterOptions: { style: style.id }
      });
    }

    const blockVariations = blocks
      .filterBy('blockIdentifier', params.block_id)
      .sortBy('variationPosition');
    const block = {
      name: blockVariations[0].blockName,
      identifier: blockVariations[0].blockIdentifier
    };

    return { block, variations: blockVariations };
  }
}
