import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task, all, timeout } from 'ember-concurrency';

import { ACCEPT_HEADER } from 'additive-newsletter/utils/constants';

export default class AddressBookSubscriberController extends Controller {
  @service authenticatedFetch;
  @service currentUser;
  @service router;
  @service store;
  @service uiPaths;
  @service uiState;
  @service uiToast;
  @service uiDialog;
  @service intl;

  /**
   * the fetched address book subscriber
   *
   * @argument addressBookSubscriber
   * @type {Object}
   * @default null
   */
  @tracked
  addressBookSubscriber = null;

  /**
   * the fetched address books
   *
   * @argument addressBooks
   * @type {Array}
   * @default null
   */
  @tracked
  addressBooks = null;

  /**
   * whether an error occured while fetching the address book subscriber
   *
   * @argument isError
   * @type {Boolean}
   * @default false
   */
  @tracked
  isError = false;

  /**
   * Whether info-sidebar is open
   *
   * @property isInfoSidebarOpen
   * @type {Boolean}
   * @default false
   */
  @tracked
  isInfoSidebarOpen = false;

  /**
   * whether the action dialog is open
   *
   * @argument isActionDialog
   * @type {Boolean}
   * @default false
   */
  @tracked
  isActionDialog = false;

  /**
   * the action type of the dialog
   * - move
   * - copy
   * - group
   *
   * @argument actionType
   * @type {String}
   * @default ''
   */
  @tracked
  actionType = '';

  get _showAddressBookActions() {
    return this.addressBooks.length > 1;
  }

  get _isViewer() {
    return this.currentUser.isViewer;
  }

  /**
   * fetch all address books
   *
   * @function fetchAddressBooks
   * @type {Task}
   */
  @task(function* () {
    try {
      let addressBooks = this.store.peekAll('address-book');
      if (!addressBooks) {
        addressBooks = yield this.store.findAll('address-book', { reload: true });
      }

      this.addressBooks = addressBooks;
    } catch (e) {
      this.uiToast.showToast({
        type: 'error',
        title: this.intl.t('global.toast.error.savedChanges')
      });
    }
  })
  fetchAddressBooks;

  /**
   * fetches the subscriber
   *
   * @function fetchSubscriber
   * @type {Task}
   */
  @task(function* () {
    this.isError = false;
    try {
      const { addressBook, subscriberId } = this.model;

      let subscriber = this.store.peekRecord('address-book-subscriber', subscriberId);

      // fetch subscriber only if record is not in store
      if (!subscriber) {
        subscriber = this.store.findRecord('address-book-subscriber', subscriberId, {
          adapterOptions: {
            addressBookId: addressBook.id
          }
        });
      }

      // let task run for at least 450ms
      const [fetchedSubscriber] = yield all([subscriber, timeout(450)]);
      this.addressBookSubscriber = fetchedSubscriber;
    } catch (e) {
      this.isError = true;
    }
  })
  fetchSubscriber;

  /**
   * unsubscribes the given addressbook subscriber
   *
   * @function unsubscribe
   * @type {Task}
   */
  @task(function* () {
    try {
      const { addressBook, subscriberId } = this.model;

      const baseUrl = this.uiPaths.pathsByRouteName('instance').api().url;
      const response = yield this.authenticatedFetch.fetch(
        `${baseUrl}/addressbooks/${addressBook.id}/subscribers/${subscriberId}/unsubscribe`,
        { headers: ACCEPT_HEADER, method: 'PUT' }
      );

      // update subscriber in the list
      const payload = yield response.json();
      this.store.pushPayload(payload);

      this.uiToast.showToast({
        type: 'success',
        title: this.intl.t('global.toast.success.savedChanges')
      });
    } catch (e) {
      this.uiToast.showToast({
        type: 'error',
        title: this.intl.t('global.toast.error.savedChanges')
      });
    }
  })
  unsubscribe;

  @action
  back() {
    this.router.transitionTo('instance.address-books.address-book', this.model.addressBook.id);
  }

  @action
  openEdit() {
    this.router.transitionTo('instance.address-books.address-book.subscribers.subscriber.edit');
  }

  @action
  showUnsubscribeDialog() {
    const { intl } = this;
    this.uiDialog.showConfirm(
      intl.t('addressBooks.dialogs.unsubscribe.title'),
      intl.t('addressBooks.dialogs.unsubscribe.description'),
      () => this.unsubscribe.perform(),
      intl.t('global.actions.unsubscribe'),
      true
    );
  }
}
