import { validatePresence } from 'ember-changeset-validations/validators';

export const widgetValidation = (intl) => {
  const presenceMessage = intl.t('errors.required');

  return {
    name: validatePresence({ presence: true, message: presenceMessage }),
    addressBookId: validatePresence({ presence: true, message: presenceMessage })
  };
};
