import RESTSerializer from '@ember-data/serializer/rest';

export default RESTSerializer.extend({
  isNewSerializerAPI: true,
  attrs: {
    addressBookName: { serialize: false },
    groupNames: { serialize: false },
    clickRate: { serialize: false },
    openRate: { serialize: false },
    status: { serialize: false },
    subscriberCount: { serialize: false },
    resendSubscriberCount: { serialize: false },
    isTimeShifted: { serialize: false },
    createdAt: { serialize: false },
    updatedAt: { serialize: false },
    sendAt: { serialize: false },
    sentAt: { serialize: false },
    sendingAbortedAt: { serialize: false },
    resendAt: { serialize: false },
    resentAt: { serialize: false },
    resendingAbortedAt: { serialize: false },
    layout: { serialize: false },
    layoutId: { serialize: false },
    reportId: { serialize: false },
    instances: { serialize: false },
    excludeCount: { serialize: false },
    previewUrl: { serialize: false }
  },
  modelNameFromPayloadKey(payloadKey, ...args) {
    if (payloadKey === 'dynamicGroups' || payloadKey === 'staticGroups') {
      payloadKey = 'groups';
    }

    return this._super(payloadKey, ...args);
  },
  serialize() {
    let json = this._super(...arguments);
    if (json.resendTime) {
      json.resendTime = `${timeToUTC(json.resendTime)}:00`;
    }

    if (json.version !== 2) {
      delete json.styleId;
      delete json.styleName;
      delete json.version;
      delete json.language;
      delete json.addressBookId;

      if (Array.isArray(json.groups)) {
        json.groups = json.groups.map((group) => {
          if (typeof group === 'object') {
            return group.id;
          }
          return group;
        });
      }

      // turn boolean into binary value
      Object.keys(json).forEach((key) => {
        if (typeof json[key] === 'boolean') {
          json[key] = json[key] ? 1 : 0;
        }
      });
    }
    return json;
  },
  normalizeResponse(store, primaryModelClass, payload) {
    const campaigns = payload.campaigns ? payload.campaigns : [payload.campaign];

    // transform resendTime property
    campaigns.forEach((campaign) => {
      if (campaign.resendTime) {
        campaign.resendTime = timeFromUTC(campaign.resendTime.slice(0, 5));
      }
    });

    return this._super(...arguments);
  },
  serializeIntoHash(data, type, record, options) {
    if (record.record.version === 2) {
      Object.assign(data, this.serialize(record, options));
    } else {
      this._super(...arguments);
    }
  }
});

/**
 * returns the UTC time string from an hh:mm formatted string
 *
 * @function timeToUTC
 * @param {String} time
 * @returns {String}
 */
function timeToUTC(time) {
  const date = new Date();
  const utcOffset = 24 - Math.floor(date.getTimezoneOffset() / 60) * -1;

  const timeParts = time.split(':');
  let hours = (parseInt(timeParts[0]) + utcOffset) % 24;
  hours = `0${hours}`.slice(-2);

  return `${hours}:${timeParts[1]}`;
}

/**
 * returns the current time string from an hh:mm formatted UTC time sting
 *
 * @function timeFromUTC
 * @param {String} time
 * @returns {String}
 */
function timeFromUTC(time) {
  const date = new Date();
  const utcOffset = 24 + Math.floor(date.getTimezoneOffset() / 60) * -1;

  const timeParts = time.split(':');
  let hours = (parseInt(timeParts[0]) + utcOffset) % 24;
  hours = `0${hours}`.slice(-2);

  return `${hours}:${timeParts[1]}`;
}
