import Route from '@ember/routing/route';
import FullWidthMixin from '@additive-apps/ui/mixins/ui-full-width-view';
import { inject as service } from '@ember/service';

export default class InstanceTemplatesTemplateEditRoute extends Route.extend(FullWidthMixin) {
  @service store;

  renderTemplate() {
    this.render('instance/templates/index/template/edit', {
      into: 'application'
    });
  }

  model() {
    const modelId = this.modelFor('instance.templates.index.template');
    const template = this.store.peekRecord('template', modelId);

    return template || this.store.findRecord('template', modelId);
  }

  resetController() {
    super.resetController(...arguments);

    this.store.unloadAll('style-block');
    this.store.unloadAll('template-block');
  }
}
