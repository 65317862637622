export const Templates = (server) => {
  server.get(`/templates`, (schema) => {
    return schema.templates.all();
  });
  server.get(`/templates/:id`, (schema, { params: { id } }) => {
    return schema.templates.find(id);
  });
  server.get(`/templates/:id/blocks`, (schema, { params: { id } }) => {
    return schema.templateBlocks.where({ templateId: id });
  });
};
