export const sansSerifFonts = [
  {
    name: 'Helvetica',
    value: 'Helvetica',
    group: 'sansserif',
    key: ''
  },
  {
    name: 'Lucida Grande',
    value: 'Lucida Grande',
    group: 'sansserif',
    key: ''
  },
  {
    name: 'Geneva',
    value: 'Geneva',
    group: 'sansserif',
    key: ''
  },
  {
    name: 'Arial',
    value: 'Arial',
    group: 'sansserif',
    key: ''
  },
  {
    name: 'Verdana',
    value: 'Verdana',
    group: 'sansserif',
    key: ''
  },
  {
    name: 'Lucida Sans',
    value: 'Lucida Sans',
    group: 'sansserif',
    key: ''
  },
  {
    name: 'Lucida',
    value: 'Lucida',
    group: 'sansserif',
    key: ''
  },
  {
    name: 'Trebuchet MS',
    value: 'Trebuchet MS',
    group: 'sansserif',
    key: ''
  },
  {
    name: 'Tahoma',
    value: 'Tahoma',
    group: 'sansserif',
    key: ''
  },
  {
    name: 'Century Gothic',
    value: 'Century Gothic',
    group: 'sansserif',
    key: ''
  },
  {
    name: 'Arial Black',
    value: 'Arial Black',
    group: 'sansserif',
    key: ''
  }
];

export const serifFonts = [
  {
    name: 'Palatino',
    value: 'Palatino',
    group: 'serif',
    key: ''
  },
  {
    name: 'Palatino Linotype',
    value: 'Palatino Linotype',
    group: 'serif',
    key: ''
  },
  {
    name: 'Georgia',
    value: 'Georgia',
    group: 'serif',
    key: ''
  },
  {
    name: 'Times New Roman',
    value: 'Times New Roman',
    group: 'serif',
    key: ''
  },
  {
    name: 'Times',
    value: 'Times',
    group: 'serif',
    key: ''
  },
  {
    name: 'Courier',
    value: 'Courier',
    group: 'serif',
    key: ''
  }
];

export const sansSerifFontsFallbacks =
  '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif';

export const serifFontsFallbacks =
  '"Book Antiqua",Palatino,"Palatino Linotype","Palatino LT STD",Georgia,serif';

export const newsletterSerifFallbacks = ['Palatino', 'Georgia', 'Times', 'serif'];
export const newsletterSansSerifFallbacks = ['Helvetica', 'Verdana', 'Arial', 'sans-serif'];

// supported font variants
export const FONT_TYPES = ['normal', 'light', 'bold'];

/**
 * Util that extracts all custom font variants from a given font-object,
 * merges them with all available serif and sans-serif fonts, with their fallbacks
 * and transforms them into an array which are supported by our select-component
 *
 *
 * The input param should always have the correct format as defined by the api.
 *
 * ```js
 * fonts: {
 *  key: {
 *    light: {
 *      fontFamily: 'dummy font light',
 *      ...
 *    }
 *    ...
 *  }
 * }
 * ```
 * Available font variants are currently `light`, `normal` and `bold`
 * @function getFontOptions
 * @param {Object} fonts
 * @return {Array} fontOptions
 */
export const getFontOptions = (fonts) => {
  const customFonts = [];

  Object.keys(fonts || {}).forEach((key) => {
    FONT_TYPES.forEach((type) => {
      if (fonts[key] && fonts[key][type]) {
        const isFirstOfCustom = customFonts.length === 0;
        customFonts.push({
          // the value displayed in the select is the name of the font family
          name: fonts[key][type].fontFamily,
          // the internal value is the key.type. e.g.: `primary.normal`
          value: `${key}.${type}`,
          url: fonts[key][type].url,
          serif: fonts[key].serif,
          isCustom: true,
          isFirstOfCustom
        });
      }
    });
  });

  const fontOptions = [
    ...customFonts,
    { name: 'Standard', value: sansSerifFontsFallbacks, serif: false, isFirstOfGroup: true },
    ...sansSerifFonts,
    { name: 'Standard', value: serifFontsFallbacks, serif: true, isFirstOfGroup: true },
    ...serifFonts
  ];

  return fontOptions;
};

export default { sansSerifFonts };
