import ENV from 'additive-newsletter/config/environment';

import { CAMPAIGN_STATES } from 'additive-newsletter/utils/constants';
import { Response } from 'miragejs';

export const Detail = (server) => {
  server.get('/campaigns/:id/validate', () => {
    return new Response(204);
  });
  server.get('/campaigns/:id', (schema, { params: { id } }) => {
    return schema.campaigns.find(id);
  });
  server.post('/campaigns/:id/retry', (schema, { params: { id } }) => {
    const campaign = schema.campaigns.find(id);
    campaign.update({ status: CAMPAIGN_STATES.DRAFT });
    return campaign;
  });
  server.put('/campaigns/:id', (schema, request) => {
    const requestBody = JSON.parse(request.requestBody);
    const campaign = schema.campaigns.find(request.params.id);
    requestBody.campaign ? delete requestBody.campaign.addressBook : delete requestBody.addressBook;
    campaign.update(requestBody.campaign || requestBody);
    return campaign;
  });
  server.post('/campaigns/:id/exception-list/upload', () => {
    return server.create('exception-list');
  });

  server.get('/campaigns/:id/email-on-acid/', (schema) => {
    return {
      results: {
        application: schema.emailPreviews.all().models,
        mobile: schema.emailPreviews.all().models,
        web: schema.emailPreviews.all().models
      }
    };
  });

  server.post('/campaigns/:id/email-on-acid/', (schema) => {
    return {
      results: {
        application: schema.emailPreviews.all().models,
        mobile: schema.emailPreviews.all().models,
        web: schema.emailPreviews.all().models
      }
    };
  });

  server.put('/campaigns/:id/email-on-acid/reprocess', (schema) => {
    return {
      results: {
        application: schema.emailPreviews.all().models,
        mobile: schema.emailPreviews.all().models,
        web: schema.emailPreviews.all().models
      }
    };
  });

  server.get('/campaigns/:id/subject-suggestions/', () => {
    const allSubjects = [
      'Unlock Exclusive Deals Today! 🛍️',
      'Boost Your Productivity 🚀: Top Tips Inside!',
      'Unlock Essential Marketing Insights ✨🔓',
      'Unlock New Tips for Success! 🚀',
      '🔥 Hot Tips to Elevate Your Business!',
      'Discover the Secrets to Success! 🔑',
      'Unleash Your Potential with These Strategies! 🌟',
      'Maximize Your Impact with Proven Techniques 💡',
      'Achieve Your Goals with These Expert Tips 🎯',
      'Innovate and Thrive with Fresh Ideas 💡'
    ];

    // Randomly choose a subset of subjects (e.g., 3 to 5 subjects)
    const subsetSize = Math.floor(Math.random() * (5 - 3 + 1)) + 3; // Randomly choose between 3 and 5 subjects
    const shuffledSubjects = allSubjects.sort(() => 0.5 - Math.random()); // Shuffle the array
    const subjects = shuffledSubjects.slice(0, subsetSize); // Select a subset

    return {
      subjects
    };
  });

  server.get(`${ENV.APP.publicHost}/:org/campaigns/:id/preview`, () => {
    return '<html></html>';
  });
};

export const List = (server) => {
  server.get('/campaigns', (schema, { queryParams }) => {
    if (queryParams['status[in]']) {
      const statusArray = queryParams['status[in]'].split(',');
      return schema.campaigns.all().filter((campaign) => {
        if (statusArray.indexOf(`${campaign.status}`) >= 0) {
          return true;
        }

        return false;
      });
    }

    return schema.campaigns.all();
  });
};
