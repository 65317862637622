import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.value}}\n  <div class=\"flex w-50 pt3 pb1 primary\">\n    <div class=\"icon mr2\">\n      {{svg-jar this.icon}}\n    </div>\n    <div class=\"font-sm flex-grow-1\">\n      <div class=\"secondary\">\n        {{this.label}}\n      </div>\n      <div class=\"primary\" data-test-anl-abs-info-value={{this.value}}>\n        {{this.value}}\n        {{#if this.secondaryValue}}\n          <div class=\"secondary\">\n            {{this.secondaryValue}}\n          </div>\n        {{/if}}\n      </div>\n    </div>\n  </div>\n{{/if}}", {"contents":"{{#if this.value}}\n  <div class=\"flex w-50 pt3 pb1 primary\">\n    <div class=\"icon mr2\">\n      {{svg-jar this.icon}}\n    </div>\n    <div class=\"font-sm flex-grow-1\">\n      <div class=\"secondary\">\n        {{this.label}}\n      </div>\n      <div class=\"primary\" data-test-anl-abs-info-value={{this.value}}>\n        {{this.value}}\n        {{#if this.secondaryValue}}\n          <div class=\"secondary\">\n            {{this.secondaryValue}}\n          </div>\n        {{/if}}\n      </div>\n    </div>\n  </div>\n{{/if}}","moduleName":"additive-newsletter/components/anl-address-book/subscriber/info-item.hbs","parseOptions":{"srcName":"additive-newsletter/components/anl-address-book/subscriber/info-item.hbs"}});
import Component from '@ember/component';

/**
 * The info item displayed in the subcriber detail
 *
 * @module anl-address-book/subscriber
 * @class anl-address-book/subscriber/info-item
 */
export default Component.extend({
  tagName: '',

  /**
   * the value of the item
   *
   * @argument value
   * @type {String}
   */
  value: null,

  /**
   * the second value of the item
   *
   * @argument secondaryValue
   * @type {String}
   */
  secondaryValue: null,

  /**
   * the label of the item
   *
   * @argument label
   * @type {String}
   */
  label: null,

  /**
   * the name of the icon
   *
   * @argument icon
   * @type {String}
   */
  icon: null
});
