import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AnlCampaigns::SendDialog::ToggleListItem\n  @title={{t \"routes.campaigns.sendCampaign.timeShifted.title\"}}\n  @isLoading={{@isLoading}}\n  @statusState={{undefined}}\n  @value={{@isToggled}}\n  @onChange={{@onChangeToggleValue}}\n  @uiSwitchBubbles={{false}}\n/>\n{{#if @isToggled}}\n  <tr>\n    <td colspan=\"3\" class=\"ph3 pv2\">\n      <UiDatetimePicker\n        @classNames=\"w-100 w-50-sm cpointer\"\n        @initialDate={{@initialDate}}\n        @minDate={{@minDate}}\n        @dateErrorMessage={{@dateErrorMessage}}\n        @onValidStateChange={{@onValidStateChange}}\n        @onChange={{@onChangeSendAt}}\n      />\n    </td>\n  </tr>\n{{/if}}", {"contents":"<AnlCampaigns::SendDialog::ToggleListItem\n  @title={{t \"routes.campaigns.sendCampaign.timeShifted.title\"}}\n  @isLoading={{@isLoading}}\n  @statusState={{undefined}}\n  @value={{@isToggled}}\n  @onChange={{@onChangeToggleValue}}\n  @uiSwitchBubbles={{false}}\n/>\n{{#if @isToggled}}\n  <tr>\n    <td colspan=\"3\" class=\"ph3 pv2\">\n      <UiDatetimePicker\n        @classNames=\"w-100 w-50-sm cpointer\"\n        @initialDate={{@initialDate}}\n        @minDate={{@minDate}}\n        @dateErrorMessage={{@dateErrorMessage}}\n        @onValidStateChange={{@onValidStateChange}}\n        @onChange={{@onChangeSendAt}}\n      />\n    </td>\n  </tr>\n{{/if}}","moduleName":"additive-newsletter/components/anl-campaigns/send-dialog/publish-item.hbs","parseOptions":{"srcName":"additive-newsletter/components/anl-campaigns/send-dialog/publish-item.hbs"}});
import Component from '@glimmer/component';
import { arg } from 'ember-arg-types';
import { string, boolean, func } from 'prop-types';

export default class PublishItem extends Component {
  /**
   * The state of the UiStatus component.
   *
   * @argument isToggled
   * @type {Boolean}
   * @default null
   */
  @arg(boolean)
  isToggled;

  /**
   * The state of the UiStatus component.
   *
   * @argument onChangeToggleValue
   * @type {Boolean}
   * @default null
   */
  @arg(boolean)
  onChangeToggleValue;

  /**
   * the initial value of the date time picker
   *
   * @argument initialDate
   * @type {String}
   * @default null
   */
  @arg(string)
  initialDate = null;

  /**
   * the min value of the date time picker
   *
   * @argument initialDate
   * @type {String}
   * @default null
   */
  @arg(string)
  minDate;

  /**
   * the error message of the date input
   *
   * @argument dateErrorMessage
   * @type {String}
   * @default null
   */
  @arg(string)
  dateErrorMessage = null;

  /**
   * callback triggered when valid state changes
   *
   * @function onValidStateChange
   * @param {Boolean} isValid Whether changeset is valid
   */
  @arg(func)
  onValidStateChange;

  /**
   * the callback for the date change
   *
   * @function onChange
   * @param {Date} date
   */
  @arg(func)
  onChangeSendAt;
}
