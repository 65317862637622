import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this._isLoading}}\n  <UiSkeleton @items={{1}} @itemsSmartphone={{1}} @cardHeight={{360}} />\n{{else if this._isEmpty}}\n  <div class=\"font-sm secondary text-center pv2\">\n    {{t \"components.anl-report.target-groups.empty\"}}\n  </div>\n{{else}}\n  <UiChart\n    @classNames=\"pt1\"\n    @data={{this._data}}\n    @hasTooltip={{false}}\n    @type=\"horizontalBar\"\n    @collapsedItemCount={{10}}\n  />\n\n  <div class=\"flex flex-column flex-row-sm pl2\">\n    {{#each this._data.datasets as |item|}}\n      <UiChartLegend @item={{item}} />\n    {{/each}}\n  </div>\n{{/if}}", {"contents":"{{#if this._isLoading}}\n  <UiSkeleton @items={{1}} @itemsSmartphone={{1}} @cardHeight={{360}} />\n{{else if this._isEmpty}}\n  <div class=\"font-sm secondary text-center pv2\">\n    {{t \"components.anl-report.target-groups.empty\"}}\n  </div>\n{{else}}\n  <UiChart\n    @classNames=\"pt1\"\n    @data={{this._data}}\n    @hasTooltip={{false}}\n    @type=\"horizontalBar\"\n    @collapsedItemCount={{10}}\n  />\n\n  <div class=\"flex flex-column flex-row-sm pl2\">\n    {{#each this._data.datasets as |item|}}\n      <UiChartLegend @item={{item}} />\n    {{/each}}\n  </div>\n{{/if}}","moduleName":"additive-newsletter/components/anl-report/target-group-chart.hbs","parseOptions":{"srcName":"additive-newsletter/components/anl-report/target-group-chart.hbs"}});
import Component from '@glimmer/component';
import { alias, empty } from '@ember/object/computed';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { arg } from 'ember-arg-types';
import { string } from 'prop-types';

/**
 * This component chart for the given target group data
 *
 * @class AnlReportTragetGroupChart
 */
export default class AnlReportTragetGroupChart extends Component {
  /**
   * the chart data
   *
   * @argument _data
   * @type {Object}
   * @private
   */
  @tracked
  _data = null;

  /**
   * the type of the target group
   *
   * @argument type
   * @type {String}
   */
  @arg(string)
  @tracked
  type = null;

  /**
   * fetches the datasets for the type
   *
   * @type {Task}
   * @function fetchChartData
   */
  @arg
  onFetchChartData = () => {};

  /**
   * whether the data is empty
   *
   * @argument _isEmpty
   * @type {Boolean}
   * @private
   */
  @empty('_data') _isEmpty;

  /**
   * whether the data is being fetched
   *
   * @argument _isLoading
   * @type {Boolean}
   * @private
   */
  @alias('onFetchChartData.isRunning') _isLoading;

  constructor() {
    super(...arguments);
    this._setup.perform();
  }

  @task(function* () {
    if (!this.type) return;

    this._data = yield this.onFetchChartData?.perform(this.type);
  })
  _setup;
}
