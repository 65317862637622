import Route from '@ember/routing/route';
import FullWidthMixin from '@additive-apps/ui/mixins/ui-full-width-view';

import { inject as service } from '@ember/service';

export default Route.extend(FullWidthMixin, {
  intl: service(),
  multimedia: service(),

  renderTemplate() {
    this.render('instance/styles/widgets/widget', {
      into: 'application'
    });
  },

  model(params) {
    return this.store.findRecord('widget', params.widget_id);
  },

  afterModel(model) {
    if (model.isLegacy) {
      this.transitionTo('instance.styles.widgets');
    } else {
      this._super(...arguments);
    }
  },

  resetController(controller) {
    controller.changeset = null;
    controller.model = null;
    controller.activeTab = null;
  }
});
