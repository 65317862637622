import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<UiControlPanelGroup\n  @class=\"mt2\"\n  @title={{t \"components.anlSettings.generalSettings.salutation.title\"}}\n  as |Group|\n>\n  <Group.panel\n    @label={{t \"components.anlSettings.generalSettings.salutation.title\"}}\n    @value={{t \"components.anlSettings.generalSettings.salutation.description\"}}\n    @isReadOnly={{true}}\n    as |panel|\n  >\n    <panel.right>\n      <div class=\"flex items-start\">\n        <UiIconButton @class=\"dib\" @icon=\"new-window\" @onClick={{this.openAccountSettings}} />\n      </div>\n    </panel.right>\n  </Group.panel>\n</UiControlPanelGroup>", {"contents":"<UiControlPanelGroup\n  @class=\"mt2\"\n  @title={{t \"components.anlSettings.generalSettings.salutation.title\"}}\n  as |Group|\n>\n  <Group.panel\n    @label={{t \"components.anlSettings.generalSettings.salutation.title\"}}\n    @value={{t \"components.anlSettings.generalSettings.salutation.description\"}}\n    @isReadOnly={{true}}\n    as |panel|\n  >\n    <panel.right>\n      <div class=\"flex items-start\">\n        <UiIconButton @class=\"dib\" @icon=\"new-window\" @onClick={{this.openAccountSettings}} />\n      </div>\n    </panel.right>\n  </Group.panel>\n</UiControlPanelGroup>","moduleName":"additive-newsletter/components/anl-settings/general-settings/salutation.hbs","parseOptions":{"srcName":"additive-newsletter/components/anl-settings/general-settings/salutation.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';

import { arg } from 'ember-arg-types';
import { string } from 'prop-types';

export default class Salutation extends Component {
  @arg(string)
  accountSettingsLink = null;

  @action
  openAccountSettings() {
    window.open(this.accountSettingsLink, '_blank');
  }
}
