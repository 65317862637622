export const Dashboard = (server) => {
  server.get('/dashboard/marketing-insights', () => {
    return {
      stats: {
        conversions: {
          type: 'single',
          current: {
            value: 4008,
            type: 'total'
          }
        },
        reservations: {
          type: 'single',
          current: {
            value: 137,
            type: 'total'
          }
        },
        sessions: {
          type: 'single',
          current: {
            value: 55607,
            type: 'total'
          }
        },
        totalValueCreation: {
          type: 'single',
          current: {
            value: 10000000,
            type: 'total'
          }
        }
      }
    };
  });
};
