import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default Route.extend({
  store: service(),

  renderTemplate() {
    this.render('instance/address-books/address-book/groups/group/subscribers/subscriber', {
      into: 'instance/address-books/address-book/groups/group',
      outlet: 'detail'
    });
  },

  model(params) {
    const groupModel = this.modelFor('instance.address-books.address-book.groups.group');

    return {
      addressBookId: groupModel.addressBook.id,
      groupId: groupModel.groupId,
      subscriberId: params.subscriber_id
    };
  },

  setupController(controller, model) {
    this._super(controller, model);

    controller.fetchSubscriber.perform();
  }
});
