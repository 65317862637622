/**
 * Sets placeholder font and other styles at given iframe
 *
 * @function setIframeStyles
 * @param {HTMLElement} iframe
 */
export const setIframeStyles = (iframe) => {
  const doc = iframe.contentDocument;

  let style = doc.createElement('style');
  style.innerHTML = `
    @font-face {
      font-family: "Redacted";
      src: url("https://cdn-apps.s3.amazonaws.com/newsletter/layout/fonts/redacted-regular.woff2") format("woff2"),
          url("https://cdn-apps.s3.amazonaws.com/newsletter/layout/fonts/redacted-regular.woff") format("woff");
    }
    .newsletter-text-placeholder {
      font-family: "Redacted" !important;
      color: #c9c9c9;
      letter-spacing: -0.4px;
    }
    a[href^="{# "], a[href^="{# "] span {display: inline-block;pointer-events:none;}
  `;
  doc.head.appendChild(style);
};
