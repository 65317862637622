import format from 'date-fns/format';
import deLocale from 'date-fns/locale/de';

/**
 * returns a formatted date time string
 *
 * @function formatDateTime
 * @param {string} dateTime
 * @param {Object} intl
 * @return {String} formattedString
 */
export const formatDateTime = (dateTime, intl) => {
  if (!dateTime || !intl) {
    return null;
  }

  const dateObject = new Date(dateTime);
  const date = format(dateObject, 'dd.MM.yyyy', { locale: deLocale });
  const time = format(dateObject, 'HH:mm', { locale: deLocale });

  return intl.t('global.utils.formatDatetime', {
    date: date,
    time: time
  });
};
