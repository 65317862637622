export default {
  time: {
    hhmm: {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    },
    hhmmss: {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: false
    }
  },
  date: {
    ddmmyyyy: {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }
  },
  number: {
    EUR: {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }
  }
};
