import AdapterError from '@ember-data/adapter/error';

import ApplicationAdapter from 'additive-newsletter/adapters/application';

export default class TemplateAdapter extends ApplicationAdapter {
  handleResponse(status, _, payload) {
    if (status === 403) {
      return new AdapterError([
        {
          status: status,
          code: payload.code,
          detail: payload.message
        }
      ]);
    }

    return super.handleResponse(...arguments);
  }
}
