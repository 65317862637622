import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AnlCampaigns::SendDialog::ButtonListItem\n  @title={{t \"routes.campaigns.sendCampaign.testSend.title\"}}\n  @description={{this.description}}\n  @buttonLabel={{this.buttonLabel}}\n  @onClickButton={{action (mut this.isSendDialog) true}}\n  @statusState={{this.statusState}}\n  @isLoading={{@isLoading}} as |item|\n>\n  {{#if this.isSendDialog}}\n    <item.extra>\n      <AnlCampaigns::TestSendDialog\n        @campaign={{@campaign}}\n        @onClose={{fn (mut this.isSendDialog) false}}\n        @onError={{pipe (fn (mut this.isSendDialog) false) (fn (mut this.statusState) \"error\")}}\n        @onSend={{pipe (fn (mut this.isSendDialog) false) (fn (mut this.statusState) \"success\")}}\n      />\n    </item.extra>\n  {{/if}}\n</AnlCampaigns::SendDialog::ButtonListItem>", {"contents":"<AnlCampaigns::SendDialog::ButtonListItem\n  @title={{t \"routes.campaigns.sendCampaign.testSend.title\"}}\n  @description={{this.description}}\n  @buttonLabel={{this.buttonLabel}}\n  @onClickButton={{action (mut this.isSendDialog) true}}\n  @statusState={{this.statusState}}\n  @isLoading={{@isLoading}} as |item|\n>\n  {{#if this.isSendDialog}}\n    <item.extra>\n      <AnlCampaigns::TestSendDialog\n        @campaign={{@campaign}}\n        @onClose={{fn (mut this.isSendDialog) false}}\n        @onError={{pipe (fn (mut this.isSendDialog) false) (fn (mut this.statusState) \"error\")}}\n        @onSend={{pipe (fn (mut this.isSendDialog) false) (fn (mut this.statusState) \"success\")}}\n      />\n    </item.extra>\n  {{/if}}\n</AnlCampaigns::SendDialog::ButtonListItem>","moduleName":"additive-newsletter/components/anl-campaigns/send-dialog/test-item.hbs","parseOptions":{"srcName":"additive-newsletter/components/anl-campaigns/send-dialog/test-item.hbs"}});
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class TestItem extends Component {
  @service intl;

  /**
   * the statusState of the item
   *
   * @property statusState
   * @type {String}
   * @default 'warning'
   */
  @tracked
  statusState = null;

  /**
   * whether the send dialog is open
   *
   * @property isSendDialog
   * @type {Boolean}
   * @default false
   */
  @tracked
  isSendDialog = false;

  get description() {
    // TODO: Internationalize

    if (!this.statusState) {
      return this.intl.t('routes.campaigns.sendCampaign.testSend.message.idle');
    } else if (this.statusState === 'success') {
      return this.intl.t('routes.campaigns.sendCampaign.testSend.message.success');
    } else {
      return this.intl.t('routes.campaigns.sendCampaign.testSend.message.error');
    }
  }

  get buttonLabel() {
    return this.intl.t(this.statusState ? 'global.actions.repeat' : 'global.actions.execute');
  }
}
