import ApplicationSerializer from './application';

export default ApplicationSerializer.extend({
  attrs: {
    subscriberCount: { serialize: false },
    addressBook: { serialize: false }
  },

  serialize() {
    let json = this._super(...arguments);

    delete json.type;

    return json;
  }
});
