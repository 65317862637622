import { validateFormat } from 'ember-changeset-validations/validators';

import validateIf from '@additive-apps/utils/validators/validate-if';

export const appsSettingsContentValidation = (intl) => {
  return {
    legacyUrl: validateIf({
      if: { useLandingPage: false },
      validate: validateFormat({
        type: 'url',
        message: intl.t('errors.invalidURL'),
        allowBlank: true
      }),
      firstErrorMessage: true
    })
  };
};

export const appsSettingsJournalValidation = (intl) => {
  return {
    legacyUrl: validateIf({
      if: { useLandingPage: false },
      validate: validateFormat({
        type: 'url',
        message: intl.t('errors.invalidURL'),
        allowBlank: true
      }),
      firstErrorMessage: true
    })
  };
};

export const appsSettingsVoucherValidation = (intl) => {
  return {
    legacyUrl: validateIf({
      if: { useLandingPage: false },
      validate: validateFormat({
        type: 'url',
        message: intl.t('errors.invalidURL'),
        allowBlank: true
      }),
      firstErrorMessage: true
    })
  };
};
