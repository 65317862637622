import { validateNumber, validateFormat } from 'ember-changeset-validations/validators';
import { validateTrackingHosts } from '../validators/tracking-hosts';

export const generalSettingsSenderValidation = (intl) => {
  return {
    'senderEmail.value': validateFormat({
      type: 'email',
      message: intl.t('errors.email'),
      allowBlank: true
    })
  };
};

export const generalSettingsHostValidation = (intl) => {
  return {
    trackingHosts: validateTrackingHosts({
      message: intl.t('errors.invalidURL')
    })
  };
};

export const generalDeactivateInactiveValidation = (intl) => {
  return {
    daysUntilDeactivation: validateNumber({
      gte: 7,
      positive: true,
      message: intl.t('errors.equalOrGreaterThan', { value: 7 })
    }),
    minimumMailings: validateNumber({
      gte: 8,
      positive: true,
      message: intl.t('errors.equalOrGreaterThan', { value: 8 })
    }),
    monthsSinceFirstMailing: validateNumber({
      gte: 10,
      positive: true,
      message: intl.t('errors.equalOrGreaterThan', { value: 10 })
    })
  };
};
