import ApplicationSerializer from './application';

import cloneDeep from 'lodash.clonedeep';

export default class StyleSerializer extends ApplicationSerializer {
  normalizeResponse(store, primaryModelClass, payload) {
    // handle preview request
    if (payload.styleBlocks) {
      return super.normalizeResponse(...arguments);
    }

    const styles = payload.styles ? payload.styles : [payload.style];

    // change empty link from array to object
    styles.forEach((style) => {
      if (!style?.styles?.globals) {
        return;
      }

      const bulletPoint = {
        value: null
      };

      if (style.styles.globals.bulletPoint) {
        bulletPoint.value = style.styles.globals.bulletPoint;
      }

      style.styles.globals.bulletPoint = bulletPoint;
    });

    return super.normalizeResponse(...arguments);
  }

  serialize() {
    let json = super.serialize(...arguments);

    const jsonCopy = cloneDeep(json);
    if (
      jsonCopy?.styles?.globals?.bulletPoint &&
      Object.prototype.hasOwnProperty.call(jsonCopy.styles.globals.bulletPoint, 'value')
    ) {
      jsonCopy.styles.globals.bulletPoint = jsonCopy.styles.globals.bulletPoint.value;
    }

    return jsonCopy;
  }
}
