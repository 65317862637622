import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<UiSelectable as |selectable|>\n  <UiInput\n    @classNames={{this.classNames}}\n    @label={{this.label}}\n    @placeholder={{this.placeholder}}\n    @autocomplete=\"off\"\n    @value={{this.value}}\n    @onChange={{fn this.onInputChange selectable.toggleDropdown selectable.isOpen}}\n    @onFocus={{fn this.onInputChange selectable.toggleDropdown selectable.isOpen}}\n  />\n  <selectable.content>\n    {{#each this.suggestedValues as |value|}}\n      <UiSelectOption\n        @isActive={{false}}\n        @classNames=\"primary ph2 pv1 font-sm flex items-center\"\n        @onClick={{fn this.setValue value selectable.toggleDropdown}}\n      >\n        {{value}}\n      </UiSelectOption>\n    {{/each}}\n  </selectable.content>\n</UiSelectable>", {"contents":"<UiSelectable as |selectable|>\n  <UiInput\n    @classNames={{this.classNames}}\n    @label={{this.label}}\n    @placeholder={{this.placeholder}}\n    @autocomplete=\"off\"\n    @value={{this.value}}\n    @onChange={{fn this.onInputChange selectable.toggleDropdown selectable.isOpen}}\n    @onFocus={{fn this.onInputChange selectable.toggleDropdown selectable.isOpen}}\n  />\n  <selectable.content>\n    {{#each this.suggestedValues as |value|}}\n      <UiSelectOption\n        @isActive={{false}}\n        @classNames=\"primary ph2 pv1 font-sm flex items-center\"\n        @onClick={{fn this.setValue value selectable.toggleDropdown}}\n      >\n        {{value}}\n      </UiSelectOption>\n    {{/each}}\n  </selectable.content>\n</UiSelectable>","moduleName":"additive-newsletter/components/searchable-input.hbs","parseOptions":{"srcName":"additive-newsletter/components/searchable-input.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { arg } from 'ember-arg-types';
import { array, func, string } from 'prop-types';

export default class SearchableInputComponent extends Component {
  /**
   * The value of the input
   *
   * @argument value
   * @type {String}
   * @default null
   */
  @tracked
  value = null;

  /**
   * The suggested values
   *
   * @argument suggestedValues
   * @type {Array}
   * @default []
   */
  @tracked
  suggestedValues = [];

  /**
   * The searchable values
   *
   * @argument searchableValues
   * @type {Array}
   * @default []
   */
  @arg(array)
  @tracked
  searchableValues = [];

  /**
   * The class names of the input
   *
   * @argument classNames
   * @type {String}
   * @default null
   */
  @arg(string)
  classNames = null;

  /**
   * The label of the input
   *
   * @argument label
   * @type {String}
   * @default null
   */
  @arg(string)
  label = null;

  /**
   * The placeholder of the input
   *
   * @argument placeholder
   * @type {String}
   * @default null
   */
  @arg(string)
  placeholder = null;

  /**
   * the onchange callback function
   *
   * @argument onChange
   * @type {Function}
   */
  @arg(func)
  onChange = () => {};

  /**
   * suggest values based on user input
   *
   * @function suggestValues
   * @param {String} value
   */
  @action
  suggestValues(value) {
    const matchedValues = this.searchableValues.filter((searchableValue) => {
      const regex = new RegExp(`^${value}`, 'i');
      const startsWithSearchValue = !this.value || regex.test(searchableValue);

      return startsWithSearchValue;
    });

    this.suggestedValues = matchedValues;

    return matchedValues;
  }

  /**
   * set the input value and close the dropdown.
   *
   * @function onInputChange
   * @param {String} value
   * @param {Function} toggleDropdown
   */
  @action
  setValue(value, toggleDropdown) {
    this.value = value;
    this.onChange(value);
    toggleDropdown();
  }

  /**
   * toggles the dropdown when the input value changes
   *
   * @function onInputChange
   * @param {Function} toggleDropdown
   * @param {Boolean} isOpen
   * @param {String} value
   */
  @action
  onInputChange(toggleDropdown, isOpen, value) {
    this.onChange(value);

    if (this.searchableValues.length === 0) {
      return;
    }

    if (value.length === 0) {
      this.suggestedValues = [];
      if (isOpen) {
        toggleDropdown();
      }
      return;
    }

    const matchedValues = this.suggestValues(value);

    if (isOpen && matchedValues.length === 0) {
      toggleDropdown();
    } else if (!isOpen && matchedValues.length > 0) {
      toggleDropdown();
    }
  }
}
