import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class InstanceTemplatesIndexRoute extends Route {
  @service uiFilter;

  resetController() {
    super.resetController(...arguments);
    this.uiFilter.unregister('templates');
  }

  setupController(controller) {
    super.setupController(...arguments);

    controller.fetchStyles.perform();

    this.uiFilter.register('templates', 'template', { paginated: false });
  }
}
