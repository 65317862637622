import Route from '@ember/routing/route';

export default class InstanceStylesIndexStyleBlockVariationRoute extends Route {
  model(params) {
    const model = this.modelFor('instance.styles.index.style.block');
    const style = this.modelFor('instance.styles.index.style');
    const variation = model.variations.findBy('variationIdentifier', params.variation_id);

    return { block: model.block, variation, style };
  }

  setupController(controller) {
    super.setupController(...arguments);

    const { variations } = this.modelFor('instance.styles.index.style.block');
    controller.siblingVariations = variations;
  }
}
