import EmberRouter from '@ember/routing/router';
import config from 'additive-newsletter/config/environment';
import additiveAuthRouter from '@additive-apps/auth/router';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function () {
  this.route('auth', function () {
    additiveAuthRouter(this);
  });

  this.route('instance', { path: ':instance_id' }, function () {
    this.route('dashboard', { path: '/' });
    this.route('address-books', function () {
      this.route('address-book', { path: ':address_book_id' }, function () {
        this.route('subscribers', function () {
          this.route('subscriber', { path: ':subscriber_id' }, function () {
            this.route('edit');
          });
          this.route('new');
        });
        this.route('groups', function () {
          this.route('group', { path: ':group_id' }, function () {
            this.route('subscribers', function () {
              this.route('subscriber', { path: ':subscriber_id' }, function () {
                this.route('edit');
              });
              this.route('new');
            });
          });
          this.route('new');
        });
      });
    });
    this.route('campaigns', function () {
      this.route('index', { path: '/' }, function () {
        this.route('campaign', { path: ':campaign_id' }, function () {
          this.route('edit', function () {
            this.mount('@additive-apps/multimedia-engine', {
              as: 'additive-multimedia-engine',
              path: 'mm'
            });
          });
        });
      });
      this.route('sent', function () {
        this.route('campaign', { path: ':campaign_id' });
      });
    });
    this.route('reports', function () {
      this.route('report', { path: ':report_id' }, function () {});
    });
    this.route('settings', function () {
      this.route('integrations');
      this.route('legal');
      this.route('texts');
    });
    this.route('auditlog');
    this.route('templates', function () {
      this.route('index', { path: '/' }, function () {
        this.route('template', { path: ':template_id' }, function () {
          this.route('edit', function () {
            this.mount('@additive-apps/multimedia-engine', {
              as: 'additive-multimedia-engine',
              path: 'mm'
            });
          });
        });
      });
    });

    this.route('styles', function () {
      this.route('widgets', function () {
        this.route('widget', { path: ':widget_id' });
      });
      this.route('index', { path: '/' }, function () {
        this.route('style', { path: ':style_id' }, function () {
          this.route('index', { path: '/' }, function () {
            this.mount('@additive-apps/multimedia-engine', {
              as: 'additive-multimedia-engine',
              path: 'mm'
            });
          });

          this.route('block', { path: ':block_id' }, function () {
            this.route('variation', { path: ':variation_id' }, function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
          });
        });
      });
    });

    this.route('multimedia', function () {
      this.route('index', { path: '/' }, function () {
        this.mount('@additive-apps/multimedia-engine', {
          as: 'additive-multimedia-engine',
          path: 'mm'
        });
      });
    });
  });

  this.route('page-not-found', { path: 'not-found' });
  this.route('page-not-found', { path: '*wildcard' });
});
