export { Integrations } from './integrations';
export const Legals = (server) => {
  server.get(`/settings/legals`, () => {
    return {
      legal: {
        availableLanguages: ['de', 'en', 'it', 'fr', 'nl'],
        disclaimerLink: {
          sync: true,
          syncedValue: 'http://www.testhotel-post.com/impressum.html'
        },
        language: 'de',
        privacyLink: {
          sync: true,
          syncedValue: 'http://www.testhotel-post.com/datenschutz.html'
        }
      }
    };
  });
};

export const Salutations = (server) => {
  server.get(`/settings/salutations`, () => {
    return {
      salutations: {
        formal: {
          f: 'Sehr geehrte Frau {#NACHNAME#}',
          m: 'Sehr geehrter Herr {#NACHNAME#}',
          language: 'de',
          availableLanguages: ['de', 'en', 'it', 'fr']
        },
        informal: {
          f: 'Liebe {#VORNAME#}',
          m: 'Lieber {#VORNAME#}',
          language: 'de',
          availableLanguages: ['de', 'en', 'it', 'fr', 'nl']
        },
        family: {
          value: 'Liebe Familie {#NACHNAME#}',
          language: 'de',
          availableLanguages: ['de', 'en', 'it', 'fr']
        },
        fallback: {
          value: 'Guten Tag',
          language: 'de',
          availableLanguages: ['de', 'en', 'it', 'fr', 'nl']
        },
        prefer: 'formal'
      }
    };
  });
};
