import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.showDropzone}}\n  <div class=\"anl-dropzone-container mw-100 ba br1\"></div>\n{{/if}}", {"contents":"{{#if this.showDropzone}}\n  <div class=\"anl-dropzone-container mw-100 ba br1\"></div>\n{{/if}}","moduleName":"additive-newsletter/components/anl-campaign-configurator-v2/dropzone.hbs","parseOptions":{"srcName":"additive-newsletter/components/anl-campaign-configurator-v2/dropzone.hbs"}});
import Component from '@ember/component';
import { computed } from '@ember/object';
import { bind } from '@ember/runloop';

/**
 * The dropzone for the content blocks
 *
 * @module anl-campaign-configurator
 * @class anl-campaign-configurator/dropzone
 */
export default Component.extend({
  classNames: ['anl-dropzone', 'pv3 ma-auto'],
  classNameBindings: ['_isHovered:anl-dropzone__hovered', 'showDropzone:anl-dropzone__visible'],

  /**
   * the id of the currently hovered dropzone
   *
   * @argument hoveredDropzoneId
   * @type {String}
   * @default null
   */
  hoveredDropzoneId: null,

  /**
   * whether the component is visible
   *
   * @argument visible
   * @type {Boolean}
   * @default false
   */
  showDropzone: false,

  /**
   * whether this component is hovered or not
   *
   * @computed _isHovered
   * @type {Boolean}
   * @default false
   * @private
   */
  _isHovered: computed('hoveredDropzoneId', 'element.id', {
    get() {
      return this.hoveredDropzoneId === (this.element && this.element.id);
    }
  }),

  didInsertElement() {
    this._super(...arguments);

    this._onDragOver = bind(this, this._onDragOver);

    if (this.element) {
      this.element.addEventListener('dragover', this._onDragOver, false);
    }
  },

  willDestroyElement() {
    this._super(...arguments);

    if (this.element) {
      this.element.removeEventListener('dragover', this._onDragOver, false);
    }
  },

  _onDragOver(event) {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }
});
