import Transform from '@ember-data/serializer/transform';

/**
 * Transform 1 and 0 to the proper bool-value, to match
 * the legacy apis behaviour
 *
 * @class legacy-bool
 */
export default Transform.extend({
  deserialize(serialized) {
    return serialized;
  },

  serialize(deserialized) {
    return deserialized === true ? 1 : 0;
  }
});
