/* TODO: move me into an addon */

export const partnerMember = (server) => {
  server.get(`/me`, (schema) => {
    return schema.users.findBy({
      isPartner: true,
      role: 'member'
    });
  });
};

export const partnerManager = (server) => {
  server.get(`/me`, (schema) => {
    return schema.users.findBy({ isPartner: true, role: 'manager' });
  });
};

export const partnerAdmin = (server) => {
  server.get(`/me`, (schema) => {
    return schema.users.findBy({ isPartner: true, role: 'admin' });
  });
};

export const member = (server) => {
  server.get(`/me`, (schema) => {
    return schema.users.findBy({ isAdditive: false, isPartner: false, role: 'member' });
  });
};

export const manager = (server) => {
  server.get(`/me`, (schema) => {
    return schema.users.findBy({ isAdditive: false, isPartner: false, role: 'manager' });
  });
};

export const admin = (server) => {
  server.get(`/me`, (schema) => {
    return schema.users.findBy({ isAdditive: false, isPartner: false, role: 'admin' });
  });
};
