import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default Route.extend({
  uiFilter: service(),

  setupController() {
    this.uiFilter.register('address-books', 'address-book');
  },

  resetController() {
    this.uiFilter.unregister('address-books');
  }
});
