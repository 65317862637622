import ApplicationSerializer from 'additive-newsletter/mirage/serializers/application';
import { RestSerializer, Collection } from 'miragejs';

export default ApplicationSerializer.extend({
  serialize(object) {
    let json = RestSerializer.prototype.serialize.apply(this, arguments);

    const [rootKey] = Object.keys(json);

    let models = json[rootKey];
    if (!(object instanceof Collection)) {
      models = [models];
    }

    /* Include subscriber props*/
    models = models.map((abs) => {
      const subscriber = this.schema.subscribers.find(abs.subscriber);
      return Object.assign(abs, {
        /* Add whatever subscriber-prop is needed in abs */
        email: subscriber.email,
        note: subscriber.note,
        subscriberId: subscriber.id
      });
    });

    return {
      [rootKey]: models
    };
  }
});
