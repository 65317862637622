export const AddressBooks = (server) => {
  server.get('/addressbooks', (schema, { queryParams: { page = 1, per_page = 40 } }) => {
    const addressBooks = schema.addressBooks.all();
    const models = addressBooks.models;

    return {
      addressBooks: models.slice((page - 1) * per_page, page * per_page),
      meta: {
        currentPage: Number(page),
        from: (page - 1) * per_page + 1,
        lastPage: 1 + Math.floor(models.length / per_page),
        perPage: per_page,
        to: page * per_page,
        total: models.length
      }
    };
  });

  server.get('/addressbooks/:id', (schema, { params: { id } }) => {
    return schema.addressBooks.find(id);
  });

  server.get('/addressbooks/recipients/info', () => {
    return {
      deactivationSettings: {
        months: 12,
        mails: 10
      },
      totalSubscribers: 5000000,
      inactiveSubscribers: 5000000,
      deactivatedInactivitySubscribers: 500000,
      deactivatedSoftBounceSubscribers: 500000,
      deactivatedHardBounceSubscribers: 500000,
      unsubscribedSubscribers: 500000
    };
  });

  server.get('/addressbooks/recipients/info/:id', () => {
    return {
      deactivationSettings: {
        months: 12,
        mails: 10
      },
      totalSubscribers: 5000000,
      inactiveSubscribers: 5000000,
      deactivatedInactivitySubscribers: 500000,
      deactivatedSoftBounceSubscribers: 500000,
      deactivatedHardBounceSubscribers: 500000,
      unsubscribedSubscribers: 500000
    };
  });

  server.post('/addressbooks/:id/upload', () => {
    return {
      id: '5f438d716b020e0700276869',
      totalRows: 3,
      columns: [
        {
          id: 'A',
          property: 'email',
          title: 'E-Mail-Adresse',
          content: 'test1@additive.eu, test2@additive.eu, test3@additive.eu'
        },
        { id: 'B', property: 'givenName', title: 'Vorname', content: 'Test, Test, Test' },
        { id: 'C', property: 'familyName', title: 'Nachname', content: '1, 2, 3' },
        { id: 'D', property: 'gender', title: 'Geschlecht', content: 'm, m, f' },
        { id: 'E', property: 'birthday', title: 'Geburtstag' },
        { id: 'F', property: 'language', title: 'Sprache', content: 'de, en, de' },
        { id: 'G', property: 'country', title: 'Land', content: 'DE, IT' },
        { id: 'H', property: 'companyName', title: 'Unternehmen', content: 'ADDITIVE' }
      ]
    };
  });

  server.get('/imports', () => {
    return {
      imports: [
        {
          id: '40e890d9-f945-4a09-83e5-dda6e6190f5b',
          sourceIsPms: false,
          sourceName: 'Erster Import',
          type: 'guests',
          fileName: 'guests.csv',
          rowCount: 99,
          affectedCount: 99,
          startedAt: '2021-05-19T08:12:57+00:00',
          finishedAt: '2021-05-19T08:12:58+00:00',
          isRowCountCorrect: true
        },
        {
          id: '40e890d9-f945-4a09-83e5-dda6e6190f5b',
          sourceIsPms: true,
          type: 'guests',
          fileName: 'guests.csv',
          rowCount: 100,
          affectedCount: 100,
          startedAt: '2021-05-19T08:12:57+00:00',
          finishedAt: '2021-05-19T08:12:58+00:00',
          isRowCountCorrect: true
        },
        {
          id: '40e890d9-f945-4a09-83e5-dda6e6190f5b',
          sourceIsPms: false,
          type: 'guests',
          fileName: 'guests.csv',
          rowCount: 10,
          startedAt: '2021-05-19T08:12:57+00:00',
          isRowCountCorrect: true
        }
      ],
      meta: {
        currentPage: 1,
        from: 1,
        lastPage: 1,
        perPage: 10,
        to: 1,
        total: 3
      }
    };
  });
};
