import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AnlCampaigns::SendDialog::ListItem\n  @title={{@title}}\n  @description={{@description}}\n  @statusState={{@statusState}}\n  @height={{56}}\n  @isLoading={{@isLoading}}\n  @class=\"toggle-list-item\"\n  ...attributes as |item|\n>\n  <item.action>\n    <UiSwitch\n      @class=\"ma0\"\n      @value={{@value}}\n      @onChange={{@onChange}}\n      @bubbles={{@uiSwitchBubbles}}\n    />\n  </item.action>\n</AnlCampaigns::SendDialog::ListItem>", {"contents":"<AnlCampaigns::SendDialog::ListItem\n  @title={{@title}}\n  @description={{@description}}\n  @statusState={{@statusState}}\n  @height={{56}}\n  @isLoading={{@isLoading}}\n  @class=\"toggle-list-item\"\n  ...attributes as |item|\n>\n  <item.action>\n    <UiSwitch\n      @class=\"ma0\"\n      @value={{@value}}\n      @onChange={{@onChange}}\n      @bubbles={{@uiSwitchBubbles}}\n    />\n  </item.action>\n</AnlCampaigns::SendDialog::ListItem>","moduleName":"additive-newsletter/components/anl-campaigns/send-dialog/toggle-list-item.hbs","parseOptions":{"srcName":"additive-newsletter/components/anl-campaigns/send-dialog/toggle-list-item.hbs"}});
import Component from '@glimmer/component';
import { arg } from 'ember-arg-types';
import { string, boolean, func } from 'prop-types';

export default class ToggleListItem extends Component {
  /**
   * The title of the ListItem
   *
   * @argument title
   * @type {String}
   * @default null
   */
  @arg(string)
  title = null;

  /**
   * The description of the ListItem
   *
   * @argument description
   * @type {String}
   * @default null
   */
  @arg(string)
  description = null;

  /**
   * The state of the UiStatus component.
   *
   * @argument statusState
   * @type {String}
   * @default null
   */
  @arg(string)
  statusState = null;

  /**
   * The current boolean value of the switch.
   *
   * @argument value
   * @type {Boolean}
   * @default null
   */
  @arg(boolean)
  value = null;

  /**
   * The callback function when the switch is toggled.
   *
   * @argument onChange
   * @type {Function}
   */
  @arg(func)
  onChange = () => {};

  /**
   * Whether or not the UiSwitch bubbles.
   *
   * @argument uiSwitchBubbles
   * @type {Boolean}
   * @default null
   */
  @arg(boolean)
  uiSwitchBubbles = null;
}
