import Controller from '@ember/controller';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class InstanceAddressBooksIndexController extends Controller {
  @service currentUser;
  @service uiAppSettings;
  @service uiFilter;
  @service uiState;

  /**
   * the currently edited model passed to the dialog sub-components
   *
   * @argument activeModel
   * @type {DS.Model}
   */
  @tracked
  activeModel = null;

  /**
   * whether the dialog to create/rename an address-book is open
   *
   * @argument isAddressBookDialog
   * @type {Boolean}
   */
  @tracked
  isAddressBookDialog = false;

  @alias('currentUser.currentOrganization.id') organizationSlug;

  /**
   * whether the app has the professional plan
   *
   * @computed isProfessionalPlan
   * @type {Boolean}
   */
  @alias('uiAppSettings.isProfessionalPlan') isProfessionalPlan;

  @alias('uiFilter.isLoading') isLoading;
  @alias('addressBookFilter.isEmpty') isEmpty;

  get addressBookFilter() {
    const addressBookFilter = this.uiFilter.getQPFilter('address-books');
    return addressBookFilter && addressBookFilter.filter;
  }

  get showAddAddressBookButton() {
    return !this.isLoading && !this.isViewer && (this.isProfessionalPlan || this.isEmpty);
  }

  get isViewer() {
    return this.currentUser.isViewer;
  }

  @action
  toggleDetail() {
    this.uiState.getState('address-book-navdrawer').toggle();
  }
}
