import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default class InstanceCampaignsIndexCampaignEditController extends Controller {
  @service currentUser;

  /**
   * the current campaign model
   *
   * @property model
   * @type {Model}
   * @default null
   */
  model = null;
}
