import Service from '@ember/service';
import ENV from 'additive-newsletter/config/environment';
import { inject as service } from '@ember/service';
import { task, timeout, all } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { alias } from '@ember/object/computed';

import { ACCEPT_HEADER } from 'additive-newsletter/utils/constants';
import { getFontOptions } from 'additive-newsletter/utils/constants/fonts';
import { getColorOptions } from 'additive-newsletter/utils/constants/widget-default-values';

export default class AnlCdService extends Service {
  @service authenticatedFetch;
  @service currentUser;

  @tracked
  fonts = null;

  @tracked
  fontOptions = null;

  @tracked
  colorOptions = null;

  @tracked
  corporateDesignColors = null;

  @tracked
  corporateDesignTypography = null;

  @alias('currentUser.currentOrganization.id') organizationSlug;

  @task(function* () {
    let tasks = [];
    const request = this.authenticatedFetch.fetch(
      `${ENV.APP.apiBaseHost}/${this.organizationSlug}/corporate-design`,
      {
        headers: ACCEPT_HEADER
      }
    );

    tasks.push(request);
    tasks.push(timeout(500));

    const [response] = yield all(tasks);
    if (!response || !response.ok) {
      return;
    }
    const { corporateDesign } = yield response.json();
    this.corporateDesignTypography = corporateDesign.typography;
    this.corporateDesignColors = corporateDesign.colors;
    this.fonts = corporateDesign.fonts;

    this.fontOptions = getFontOptions(corporateDesign.fonts);
    this.colorOptions = getColorOptions(corporateDesign.colors);
  })
  fetchCorporateDesign;
}
