import RESTSerializer from '@ember-data/serializer/rest';

import cloneDeep from 'lodash.clonedeep';

export default class StyleBlockSerializer extends RESTSerializer {
  serialize() {
    let json = super.serialize(...arguments);

    const jsonCopy = cloneDeep(json);

    jsonCopy.style.forEach((styleGroup, styleGroupKey) => {
      styleGroup.properties?.forEach((property, propertyKey) => {
        if (property.type === 'number') {
          if (property.value) {
            jsonCopy.style[styleGroupKey].properties[propertyKey].value = parseInt(property.value);
          }

          if (property.syncedValue) {
            jsonCopy.style[styleGroupKey].properties[propertyKey].syncedValue = parseInt(
              property.syncedValue
            );
          }
        }
      });
    });

    return jsonCopy;
  }
}
