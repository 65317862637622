export const Subscribers = (server) => {
  server.get('/subscribers/filters', () => {
    return {
      filters: [
        {
          key: 'language',
          type: 'multi',
          label: 'Language',
          groupKey: 'default',
          groupLabel: 'default',
          values: [
            {
              label: 'English',
              value: 'en'
            },
            {
              label: 'Italian',
              value: 'it'
            },
            {
              label: 'German',
              value: 'de'
            },
            {
              label: 'Dutch',
              value: 'nl'
            },
            {
              label: 'French',
              value: 'fr'
            }
          ]
        },
        {
          key: 'gender',
          type: 'single',
          label: 'Gender',
          groupKey: 'default',
          groupLabel: 'default',
          values: [
            {
              label: 'Ignore',
              value: null
            },
            {
              label: 'Male',
              value: 'm'
            },
            {
              label: 'Female',
              value: 'f'
            },
            {
              label: 'None',
              value: 'none'
            }
          ]
        },
        {
          key: 'confirmed',
          type: 'single',
          label: 'Verification Status',
          groupKey: 'default',
          groupLabel: 'default',
          values: [
            {
              label: 'Ignore',
              value: null
            },
            {
              label: 'Unverified',
              value: false
            },
            {
              label: 'Verified',
              value: true
            },
            {
              label: 'Verified by',
              type: 'group'
            },
            {
              label: 'Double Opt In',
              value: 'double_opt_in'
            },
            {
              label: 'Facebook Lead',
              value: 'facebook_lead'
            },
            {
              label: 'On Page Lead',
              value: 'on_page_lead'
            },
            {
              label: 'TikTok Lead',
              value: 'tiktok_lead'
            },
            {
              label: 'Referral Lead',
              value: 'referral_marketing_lead'
            },
            {
              label: 'Prevented Deactivation',
              value: 'prevented_deactivation'
            },
            {
              label: 'Withdrawn Deactivation',
              value: 'withdrawn_deactivation'
            }
          ]
        },
        {
          key: 'state',
          type: 'single',
          label: 'Status',
          groupKey: 'default',
          groupLabel: 'default',
          values: [
            {
              label: 'Ignore',
              value: null
            },
            {
              label: 'Subscribed',
              value: 'subscribed'
            },
            {
              label: 'Unsubscribed',
              value: 'unsubscribed'
            },
            {
              label: 'Deactivated',
              value: 'deactivated'
            },
            {
              label: 'Reason for Deactivation',
              type: 'group'
            },
            {
              label: 'Soft Bounce',
              value: 'soft_bounce'
            },
            {
              label: 'Hard Bounce',
              value: 'hard_bounce'
            },
            {
              label: 'Spam',
              value: 'spam_complaint'
            },
            {
              label: 'System',
              value: 'system'
            },
            {
              label: 'PMS Locked',
              value: 'locked_by_pms'
            },
            {
              label: 'PMS Anonymized',
              value: 'anonymized_by_pms'
            },
            {
              label: 'Inactive',
              value: 'inactive'
            }
          ]
        },
        {
          key: 'country',
          type: 'multi',
          label: 'Region / Country',
          groupKey: 'default',
          groupLabel: 'default',
          values: [
            {
              label: 'Region',
              type: 'group'
            },
            {
              label: 'Benelux',
              value: 'benelux'
            },
            {
              label: 'EU',
              value: 'eu'
            },
            {
              label: 'Europe',
              value: 'europe'
            },
            {
              label: 'DACHL',
              value: 'dachl'
            },
            {
              label: 'South Tyrol',
              value: 'south_tyrol'
            },
            {
              label: 'Outside Europe',
              value: 'international'
            },
            {
              label: 'Country',
              type: 'group'
            },
            {
              label: 'Afghanistan',
              value: 'AF'
            },
            {
              label: '\u00c5land Islands',
              value: 'AX'
            },
            {
              label: 'Albania',
              value: 'AL'
            },
            {
              label: 'Algeria',
              value: 'DZ'
            },
            {
              label: 'American Samoa',
              value: 'AS'
            },
            {
              label: 'Andorra',
              value: 'AD'
            },
            {
              label: 'Angola',
              value: 'AO'
            },
            {
              label: 'Anguilla',
              value: 'AI'
            },
            {
              label: 'Antarctica',
              value: 'AQ'
            },
            {
              label: 'Antigua and Barbuda',
              value: 'AG'
            },
            {
              label: 'Argentina',
              value: 'AR'
            },
            {
              label: 'Armenia',
              value: 'AM'
            },
            {
              label: 'Aruba',
              value: 'AW'
            },
            {
              label: 'Australia',
              value: 'AU'
            },
            {
              label: 'Austria',
              value: 'AT'
            },
            {
              label: 'Azerbaijan',
              value: 'AZ'
            },
            {
              label: 'Bahamas',
              value: 'BS'
            },
            {
              label: 'Bahrain',
              value: 'BH'
            },
            {
              label: 'Bangladesh',
              value: 'BD'
            },
            {
              label: 'Barbados',
              value: 'BB'
            },
            {
              label: 'Belarus',
              value: 'BY'
            },
            {
              label: 'Belgium',
              value: 'BE'
            },
            {
              label: 'Belize',
              value: 'BZ'
            },
            {
              label: 'Benin',
              value: 'BJ'
            },
            {
              label: 'Bermuda',
              value: 'BM'
            },
            {
              label: 'Bhutan',
              value: 'BT'
            },
            {
              label: 'Bolivia, Plurinational State of',
              value: 'BO'
            },
            {
              label: 'Bosnia and Herzegovina',
              value: 'BA'
            },
            {
              label: 'Botswana',
              value: 'BW'
            },
            {
              label: 'Bouvet Island',
              value: 'BV'
            },
            {
              label: 'Brazil',
              value: 'BR'
            },
            {
              label: 'British Indian Ocean Territory',
              value: 'IO'
            },
            {
              label: 'Brunei Darussalam',
              value: 'BN'
            },
            {
              label: 'Bulgaria',
              value: 'BG'
            },
            {
              label: 'Burkina Faso',
              value: 'BF'
            },
            {
              label: 'Burundi',
              value: 'BI'
            },
            {
              label: 'Cambodia',
              value: 'KH'
            },
            {
              label: 'Cameroon',
              value: 'CM'
            },
            {
              label: 'Canada',
              value: 'CA'
            },
            {
              label: 'Cape Verde',
              value: 'CV'
            },
            {
              label: 'Cayman Islands',
              value: 'KY'
            },
            {
              label: 'Central African Republic',
              value: 'CF'
            },
            {
              label: 'Chad',
              value: 'TD'
            },
            {
              label: 'Chile',
              value: 'CL'
            },
            {
              label: 'China',
              value: 'CN'
            },
            {
              label: 'Christmas Island',
              value: 'CX'
            },
            {
              label: 'Cocos (Keeling) Islands',
              value: 'CC'
            },
            {
              label: 'Colombia',
              value: 'CO'
            },
            {
              label: 'Comoros',
              value: 'KM'
            },
            {
              label: 'Congo',
              value: 'CG'
            },
            {
              label: 'Congo, the Democratic Republic of the',
              value: 'CD'
            },
            {
              label: 'Cook Islands',
              value: 'CK'
            },
            {
              label: 'Costa Rica',
              value: 'CR'
            },
            {
              label: "C\u00f4te d'Ivoire",
              value: 'CI'
            },
            {
              label: 'Croatia',
              value: 'HR'
            },
            {
              label: 'Cuba',
              value: 'CU'
            },
            {
              label: 'Cyprus',
              value: 'CY'
            },
            {
              label: 'Czech Republic',
              value: 'CZ'
            },
            {
              label: 'Denmark',
              value: 'DK'
            },
            {
              label: 'Djibouti',
              value: 'DJ'
            },
            {
              label: 'Dominica',
              value: 'DM'
            },
            {
              label: 'Dominican Republic',
              value: 'DO'
            },
            {
              label: 'Ecuador',
              value: 'EC'
            },
            {
              label: 'Egypt',
              value: 'EG'
            },
            {
              label: 'El Salvador',
              value: 'SV'
            },
            {
              label: 'Equatorial Guinea',
              value: 'GQ'
            },
            {
              label: 'Eritrea',
              value: 'ER'
            },
            {
              label: 'Estonia',
              value: 'EE'
            },
            {
              label: 'Ethiopia',
              value: 'ET'
            },
            {
              label: 'Falkland Islands (Malvinas)',
              value: 'FK'
            },
            {
              label: 'Faroe Islands',
              value: 'FO'
            },
            {
              label: 'Fiji',
              value: 'FJ'
            },
            {
              label: 'Finland',
              value: 'FI'
            },
            {
              label: 'France',
              value: 'FR'
            },
            {
              label: 'French Guiana',
              value: 'GF'
            },
            {
              label: 'French Polynesia',
              value: 'PF'
            },
            {
              label: 'French Southern Territories',
              value: 'TF'
            },
            {
              label: 'Gabon',
              value: 'GA'
            },
            {
              label: 'Gambia',
              value: 'GM'
            },
            {
              label: 'Georgia',
              value: 'GE'
            },
            {
              label: 'Germany',
              value: 'DE'
            },
            {
              label: 'Ghana',
              value: 'GH'
            },
            {
              label: 'Gibraltar',
              value: 'GI'
            },
            {
              label: 'Greece',
              value: 'GR'
            },
            {
              label: 'Greenland',
              value: 'GL'
            },
            {
              label: 'Grenada',
              value: 'GD'
            },
            {
              label: 'Guadeloupe',
              value: 'GP'
            },
            {
              label: 'Guam',
              value: 'GU'
            },
            {
              label: 'Guatemala',
              value: 'GT'
            },
            {
              label: 'Guernsey',
              value: 'GG'
            },
            {
              label: 'Guinea',
              value: 'GN'
            },
            {
              label: 'Guinea-Bissau',
              value: 'GW'
            },
            {
              label: 'Guyana',
              value: 'GY'
            },
            {
              label: 'Haiti',
              value: 'HT'
            },
            {
              label: 'Heard Island and McDonald Islands',
              value: 'HM'
            },
            {
              label: 'Holy See (Vatican City State)',
              value: 'VA'
            },
            {
              label: 'Honduras',
              value: 'HN'
            },
            {
              label: 'Hong Kong',
              value: 'HK'
            },
            {
              label: 'Hungary',
              value: 'HU'
            },
            {
              label: 'Iceland',
              value: 'IS'
            },
            {
              label: 'India',
              value: 'IN'
            },
            {
              label: 'Indonesia',
              value: 'ID'
            },
            {
              label: 'Iran, Islamic Republic of',
              value: 'IR'
            },
            {
              label: 'Iraq',
              value: 'IQ'
            },
            {
              label: 'Ireland',
              value: 'IE'
            },
            {
              label: 'Isle of Man',
              value: 'IM'
            },
            {
              label: 'Israel',
              value: 'IL'
            },
            {
              label: 'Italy',
              value: 'IT'
            },
            {
              label: 'Jamaica',
              value: 'JM'
            },
            {
              label: 'Japan',
              value: 'JP'
            },
            {
              label: 'Jersey',
              value: 'JE'
            },
            {
              label: 'Jordan',
              value: 'JO'
            },
            {
              label: 'Kazakhstan',
              value: 'KZ'
            },
            {
              label: 'Kenya',
              value: 'KE'
            },
            {
              label: 'Kiribati',
              value: 'KI'
            },
            {
              label: "Korea, Democratic People's Republic of",
              value: 'KP'
            },
            {
              label: 'Korea, Republic of',
              value: 'KR'
            },
            {
              label: 'Kosovo',
              value: 'XK'
            },
            {
              label: 'Kuwait',
              value: 'KW'
            },
            {
              label: 'Kyrgyzstan',
              value: 'KG'
            },
            {
              label: "Lao People's Democratic Republic",
              value: 'LA'
            },
            {
              label: 'Latvia',
              value: 'LV'
            },
            {
              label: 'Lebanon',
              value: 'LB'
            },
            {
              label: 'Lesotho',
              value: 'LS'
            },
            {
              label: 'Liberia',
              value: 'LR'
            },
            {
              label: 'Libyan Arab Jamahiriya',
              value: 'LY'
            },
            {
              label: 'Liechtenstein',
              value: 'LI'
            },
            {
              label: 'Lithuania',
              value: 'LT'
            },
            {
              label: 'Luxembourg',
              value: 'LU'
            },
            {
              label: 'Macao',
              value: 'MO'
            },
            {
              label: 'Macedonia, the former Yugoslav Republic of',
              value: 'MK'
            },
            {
              label: 'Madagascar',
              value: 'MG'
            },
            {
              label: 'Malawi',
              value: 'MW'
            },
            {
              label: 'Malaysia',
              value: 'MY'
            },
            {
              label: 'Maldives',
              value: 'MV'
            },
            {
              label: 'Mali',
              value: 'ML'
            },
            {
              label: 'Malta',
              value: 'MT'
            },
            {
              label: 'Marshall Islands',
              value: 'MH'
            },
            {
              label: 'Martinique',
              value: 'MQ'
            },
            {
              label: 'Mauritania',
              value: 'MR'
            },
            {
              label: 'Mauritius',
              value: 'MU'
            },
            {
              label: 'Mayotte',
              value: 'YT'
            },
            {
              label: 'Mexico',
              value: 'MX'
            },
            {
              label: 'Micronesia, Federated States of',
              value: 'FM'
            },
            {
              label: 'Moldova, Republic of',
              value: 'MD'
            },
            {
              label: 'Monaco',
              value: 'MC'
            },
            {
              label: 'Mongolia',
              value: 'MN'
            },
            {
              label: 'Montenegro',
              value: 'ME'
            },
            {
              label: 'Montserrat',
              value: 'MS'
            },
            {
              label: 'Morocco',
              value: 'MA'
            },
            {
              label: 'Mozambique',
              value: 'MZ'
            },
            {
              label: 'Myanmar',
              value: 'MM'
            },
            {
              label: 'Namibia',
              value: 'NA'
            },
            {
              label: 'Nauru',
              value: 'NR'
            },
            {
              label: 'Nepal',
              value: 'NP'
            },
            {
              label: 'Netherlands',
              value: 'NL'
            },
            {
              label: 'Netherlands Antilles',
              value: 'AN'
            },
            {
              label: 'New Caledonia',
              value: 'NC'
            },
            {
              label: 'New Zealand',
              value: 'NZ'
            },
            {
              label: 'Nicaragua',
              value: 'NI'
            },
            {
              label: 'Niger',
              value: 'NE'
            },
            {
              label: 'Nigeria',
              value: 'NG'
            },
            {
              label: 'Niue',
              value: 'NU'
            },
            {
              label: 'Norfolk Island',
              value: 'NF'
            },
            {
              label: 'Northern Mariana Islands',
              value: 'MP'
            },
            {
              label: 'Norway',
              value: 'NO'
            },
            {
              label: 'Oman',
              value: 'OM'
            },
            {
              label: 'Pakistan',
              value: 'PK'
            },
            {
              label: 'Palau',
              value: 'PW'
            },
            {
              label: 'Palestinian Territory, Occupied',
              value: 'PS'
            },
            {
              label: 'Panama',
              value: 'PA'
            },
            {
              label: 'Papua New Guinea',
              value: 'PG'
            },
            {
              label: 'Paraguay',
              value: 'PY'
            },
            {
              label: 'Peru',
              value: 'PE'
            },
            {
              label: 'Philippines',
              value: 'PH'
            },
            {
              label: 'Pitcairn',
              value: 'PN'
            },
            {
              label: 'Poland',
              value: 'PL'
            },
            {
              label: 'Portugal',
              value: 'PT'
            },
            {
              label: 'Puerto Rico',
              value: 'PR'
            },
            {
              label: 'Qatar',
              value: 'QA'
            },
            {
              label: 'R\u00e9union',
              value: 'RE'
            },
            {
              label: 'Romania',
              value: 'RO'
            },
            {
              label: 'Russian Federation',
              value: 'RU'
            },
            {
              label: 'Rwanda',
              value: 'RW'
            },
            {
              label: 'Saint Barth\u00e9lemy',
              value: 'BL'
            },
            {
              label: 'Saint Helena',
              value: 'SH'
            },
            {
              label: 'Saint Kitts and Nevis',
              value: 'KN'
            },
            {
              label: 'Saint Lucia',
              value: 'LC'
            },
            {
              label: 'Saint Martin (French part)',
              value: 'MF'
            },
            {
              label: 'Saint Pierre and Miquelon',
              value: 'PM'
            },
            {
              label: 'Saint Vincent and the Grenadines',
              value: 'VC'
            },
            {
              label: 'Samoa',
              value: 'WS'
            },
            {
              label: 'San Marino',
              value: 'SM'
            },
            {
              label: 'Sao Tome and Principe',
              value: 'ST'
            },
            {
              label: 'Saudi Arabia',
              value: 'SA'
            },
            {
              label: 'Senegal',
              value: 'SN'
            },
            {
              label: 'Serbia',
              value: 'RS'
            },
            {
              label: 'Seychelles',
              value: 'SC'
            },
            {
              label: 'Sierra Leone',
              value: 'SL'
            },
            {
              label: 'Singapore',
              value: 'SG'
            },
            {
              label: 'Slovakia',
              value: 'SK'
            },
            {
              label: 'Slovenia',
              value: 'SI'
            },
            {
              label: 'Solomon Islands',
              value: 'SB'
            },
            {
              label: 'Somalia',
              value: 'SO'
            },
            {
              label: 'South Africa',
              value: 'ZA'
            },
            {
              label: 'South Georgia and the South Sandwich Islands',
              value: 'GS'
            },
            {
              label: 'Spain',
              value: 'ES'
            },
            {
              label: 'Sri Lanka',
              value: 'LK'
            },
            {
              label: 'Sudan',
              value: 'SD'
            },
            {
              label: 'Suriname',
              value: 'SR'
            },
            {
              label: 'Svalbard and Jan Mayen',
              value: 'SJ'
            },
            {
              label: 'Swaziland',
              value: 'SZ'
            },
            {
              label: 'Sweden',
              value: 'SE'
            },
            {
              label: 'Switzerland',
              value: 'CH'
            },
            {
              label: 'Syrian Arab Republic',
              value: 'SY'
            },
            {
              label: 'Taiwan, Province of China',
              value: 'TW'
            },
            {
              label: 'Tajikistan',
              value: 'TJ'
            },
            {
              label: 'Tanzania, United Republic of',
              value: 'TZ'
            },
            {
              label: 'Thailand',
              value: 'TH'
            },
            {
              label: 'Timor-Leste',
              value: 'TL'
            },
            {
              label: 'Togo',
              value: 'TG'
            },
            {
              label: 'Tokelau',
              value: 'TK'
            },
            {
              label: 'Tonga',
              value: 'TO'
            },
            {
              label: 'Trinidad and Tobago',
              value: 'TT'
            },
            {
              label: 'Tunisia',
              value: 'TN'
            },
            {
              label: 'Turkey',
              value: 'TR'
            },
            {
              label: 'Turkmenistan',
              value: 'TM'
            },
            {
              label: 'Turks and Caicos Islands',
              value: 'TC'
            },
            {
              label: 'Tuvalu',
              value: 'TV'
            },
            {
              label: 'Uganda',
              value: 'UG'
            },
            {
              label: 'Ukraine',
              value: 'UA'
            },
            {
              label: 'United Arab Emirates',
              value: 'AE'
            },
            {
              label: 'United Kingdom',
              value: 'GB'
            },
            {
              label: 'United States',
              value: 'US'
            },
            {
              label: 'United States Minor Outlying Islands',
              value: 'UM'
            },
            {
              label: 'Uruguay',
              value: 'UY'
            },
            {
              label: 'Uzbekistan',
              value: 'UZ'
            },
            {
              label: 'Vanuatu',
              value: 'VU'
            },
            {
              label: 'Venezuela, Bolivarian Republic of',
              value: 'VE'
            },
            {
              label: 'Viet Nam',
              value: 'VN'
            },
            {
              label: 'Virgin Islands, British',
              value: 'VG'
            },
            {
              label: 'Virgin Islands, U.S.',
              value: 'VI'
            },
            {
              label: 'Wallis and Futuna',
              value: 'WF'
            },
            {
              label: 'Western Sahara',
              value: 'EH'
            },
            {
              label: 'Yemen',
              value: 'YE'
            },
            {
              label: 'Zambia',
              value: 'ZM'
            },
            {
              label: 'Zimbabwe',
              value: 'ZW'
            }
          ]
        },
        {
          key: 'age',
          type: 'multi',
          label: 'Age',
          groupKey: 'default',
          groupLabel: 'default',
          values: [
            {
              label: '0-9 Years',
              value: '0-9'
            },
            {
              label: '10-14 Years',
              value: '10-14'
            },
            {
              label: '15-17 Years',
              value: '15-17'
            },
            {
              label: '18-24 Years',
              value: '18-24'
            },
            {
              label: '25-39 Years',
              value: '25-39'
            },
            {
              label: '40-49 Years',
              value: '40-49'
            },
            {
              label: '50-59 Years',
              value: '50-59'
            },
            {
              label: '60-69 Years',
              value: '60-69'
            },
            {
              label: '>=70 Years',
              value: '>=70'
            }
          ]
        },
        {
          key: 'interests',
          type: 'multi',
          label: 'Interests',
          groupKey: 'default',
          groupLabel: 'default',
          values: [
            {
              label: 'Alpine huts & mountains',
              value: 'mountains'
            },
            {
              label: 'Alpinism',
              value: 'mountaineering'
            },
            {
              label: 'Architecture',
              value: 'architecture'
            },
            {
              label: 'Beauty & massages',
              value: 'beauty_and_massages'
            },
            {
              label: 'Beer & liquor',
              value: 'beer_and_schnapps'
            },
            {
              label: 'Christmas markets',
              value: 'christmas_markets'
            },
            {
              label: 'Cigars',
              value: 'cigars'
            },
            {
              label: 'Cities & sightseeing',
              value: 'cities'
            },
            {
              label: 'Classical Music',
              value: 'classical_music'
            },
            {
              label: 'Climbing',
              value: 'climbing'
            },
            {
              label: 'Concerts',
              value: 'concerts'
            },
            {
              label: 'Cross-country skiing',
              value: 'cross_country_skiing'
            },
            {
              label: 'Customs & celebration',
              value: 'customs_festivals'
            },
            {
              label: 'Cycling',
              value: 'cycling'
            },
            {
              label: 'Golf',
              value: 'golf'
            },
            {
              label: 'Hiking',
              value: 'hiking'
            },
            {
              label: 'History & cultural heritage',
              value: 'history'
            },
            {
              label: 'Horseback riding',
              value: 'horse_riding'
            },
            {
              label: 'Hotspots',
              value: 'hotspots'
            },
            {
              label: 'Jazz-Music',
              value: 'jazz_music'
            },
            {
              label: "Kids' program",
              value: 'children_entertainment'
            },
            {
              label: 'Lakeside landscape',
              value: 'seascape'
            },
            {
              label: 'Local specialties',
              value: 'local_specialities'
            },
            {
              label: 'Movies & cinema',
              value: 'films'
            },
            {
              label: 'Museums & exhibitions',
              value: 'museums'
            },
            {
              label: 'Music & Dance',
              value: 'music_dance'
            },
            {
              label: 'Oldtimer',
              value: 'classic_cars'
            },
            {
              label: 'Organic & sustainable products',
              value: 'bio_and_sustainability'
            },
            {
              label: 'Outdoor activities',
              value: 'outdoor_activities'
            },
            {
              label: 'Pool & sauna',
              value: 'pool_and_sauna'
            },
            {
              label: 'Pop-Music',
              value: 'pop_music'
            },
            {
              label: 'Rap / Hip-Hop',
              value: 'rap_hip_hop'
            },
            {
              label: 'Reading & books',
              value: 'reading'
            },
            {
              label: 'Regional cuisine',
              value: 'regional_cuisine'
            },
            {
              label: 'Rest & relaxation',
              value: 'relax'
            },
            {
              label: 'Riding motorcycles',
              value: 'motorbike'
            },
            {
              label: 'RnB & Soul',
              value: 'rnb_soul'
            },
            {
              label: 'Rock & Punk',
              value: 'rock_punk'
            },
            {
              label: 'Sea & beach',
              value: 'sea_beach'
            },
            {
              label: 'Ski & snowboard',
              value: 'ski_snowboard'
            },
            {
              label: 'Ski touring',
              value: 'ski_touring'
            },
            {
              label: 'Swimming',
              value: 'swimming'
            },
            {
              label: 'Tennis',
              value: 'tennis'
            },
            {
              label: 'Traditional Music',
              value: 'traditional_music'
            },
            {
              label: 'T\u00f6rggelen',
              value: 'toerggelen'
            },
            {
              label: 'Vegan cuisine',
              value: 'vegan_cuisine'
            },
            {
              label: 'Vegetarian cuisine',
              value: 'vegetarian_cuisine'
            },
            {
              label: 'Whisky, Rum & more',
              value: 'whisky_rum_etc'
            },
            {
              label: 'Wine',
              value: 'wine'
            },
            {
              label: 'Winter landscape',
              value: 'winter_landscape'
            },
            {
              label: 'Winter sports',
              value: 'winter_sports'
            },
            {
              label: 'Yoga',
              value: 'yoga'
            }
          ]
        },
        {
          key: 'travel_motivations',
          type: 'multi',
          label: 'Travel motives',
          groupKey: 'default',
          groupLabel: 'default',
          values: [
            {
              label: 'Active vacation',
              value: 'active_holiday'
            },
            {
              label: 'Adults-only vacation',
              value: 'adults_only_holiday'
            },
            {
              label: 'Bathing vacation',
              value: 'swimming_holiday'
            },
            {
              label: 'Business travel',
              value: 'business_trip'
            },
            {
              label: 'Camping vacation',
              value: 'camping_holiday'
            },
            {
              label: 'City vacation',
              value: 'city_holiday'
            },
            {
              label: 'Culinary vacation',
              value: 'gourmet_holiday'
            },
            {
              label: 'Cultural vacation',
              value: 'cultural_holiday'
            },
            {
              label: 'Family vacation',
              value: 'family_holiday'
            },
            {
              label: 'Health / cure vacation',
              value: 'health_holiday'
            },
            {
              label: 'Nature vacation',
              value: 'nature_holiday'
            },
            {
              label: 'Relaxing vacation',
              value: 'relax_holiday'
            },
            {
              label: 'Sightseeing vacation',
              value: 'sightseeing'
            },
            {
              label: 'Spa vacation',
              value: 'wellness_holiday'
            }
          ]
        },
        {
          key: 'favourite_travel_time',
          type: 'multi',
          label: 'Favorite time to travel',
          groupKey: 'default',
          groupLabel: 'default',
          values: [
            {
              label: 'Spring',
              value: 'spring'
            },
            {
              label: 'Easter',
              value: 'easter'
            },
            {
              label: 'Summer',
              value: 'summer'
            },
            {
              label: 'Autumn',
              value: 'autumn'
            },
            {
              label: 'Winter',
              value: 'winter'
            },
            {
              label: 'Christmas',
              value: 'christmas'
            }
          ]
        },
        {
          key: 'keyword',
          type: 'multi',
          label: 'Keyword',
          groupKey: 'default',
          groupLabel: 'default',
          values: [
            {
              label: 'Wandern',
              value: 'abc'
            }
          ]
        },
        {
          type: 'group_label',
          label: 'ASA HOTEL',
          groupKey: 'external.asa',
          groupLabel: 'external.asa'
        },
        {
          key: 'building',
          type: 'multi',
          label: 'Buildings',
          groupKey: 'external.asa',
          groupLabel: 'external.asa',
          values: [
            {
              label: '2',
              value: '2'
            },
            {
              label: '1',
              value: '1'
            }
          ]
        },
        {
          key: 'asa_address_groups',
          type: 'multi',
          label: 'Address Groups',
          groupKey: 'external.asa',
          groupLabel: 'external.asa',
          values: []
        },
        {
          key: 'asa_last_travel_group',
          type: 'multi',
          label: 'Last Travel Group',
          groupKey: 'external.asa',
          groupLabel: 'external.asa',
          values: [
            {
              label: 'Solo Traveler',
              value: 'solo_traveller'
            },
            {
              label: 'Couple',
              value: 'couple'
            },
            {
              label: 'Family',
              value: 'family'
            },
            {
              label: 'Friends / Acquaintances',
              value: 'friends'
            },
            {
              label: 'Tourist Group',
              value: 'tourist_party'
            },
            {
              label: 'Other',
              value: 'other'
            }
          ]
        },
        {
          key: 'asa_last_pre_booking',
          type: 'multi',
          label: 'Last Pre-Booking',
          groupKey: 'external.asa',
          groupLabel: 'external.asa',
          values: [
            {
              label: 'Last Minute',
              value: 'last_minute'
            },
            {
              label: 'Early',
              value: 'early'
            },
            {
              label: 'Normal',
              value: 'normal'
            },
            {
              label: 'Late',
              value: 'late'
            }
          ]
        },
        {
          key: 'asa_last_travel_vehicle',
          type: 'multi',
          label: 'Last Travel Vehicle',
          groupKey: 'external.asa',
          groupLabel: 'external.asa',
          values: [
            {
              label: 'On Foot',
              value: 'by_foot'
            },
            {
              label: 'Bicycle',
              value: 'bicycle'
            },
            {
              label: 'Motorcycle',
              value: 'motorbike'
            },
            {
              label: 'Car',
              value: 'car'
            },
            {
              label: 'Electric Car',
              value: 'e_car'
            },
            {
              label: 'Bus',
              value: 'bus'
            },
            {
              label: 'Train',
              value: 'train'
            },
            {
              label: 'Airplane',
              value: 'airplane'
            },
            {
              label: 'Other',
              value: 'other'
            }
          ]
        },
        {
          key: 'asa_last_travel_purpose',
          type: 'multi',
          label: 'Last Travel Purpose',
          groupKey: 'external.asa',
          groupLabel: 'external.asa',
          values: [
            {
              label: 'Holiday',
              value: 'holiday'
            },
            {
              label: 'Culinary',
              value: 'cuisine'
            },
            {
              label: 'Art / Culture',
              value: 'art_culture'
            },
            {
              label: 'Spa / Wellness',
              value: 'spa_wellness'
            },
            {
              label: 'Sports',
              value: 'sports'
            },
            {
              label: 'Health',
              value: 'health'
            },
            {
              label: 'Religion',
              value: 'religion'
            },
            {
              label: 'School',
              value: 'school'
            },
            {
              label: 'Business',
              value: 'business'
            },
            {
              label: 'Exhibition',
              value: 'exhibition'
            },
            {
              label: 'Congress',
              value: 'congress'
            },
            {
              label: 'Other',
              value: 'other'
            }
          ]
        },
        {
          type: 'group_label',
          label: 'Contact Information',
          groupKey: 'contact',
          groupLabel: 'contact'
        },
        {
          key: 'birthday',
          type: 'date_range',
          label: 'Birthday',
          groupKey: 'contact',
          groupLabel: 'contact',
          values: [
            {
              label: 'Ignore',
              value: null
            },
            {
              label: 'Today',
              value: 'today'
            },
            {
              label: 'Tomorrow',
              value: 'tomorrow'
            },
            {
              label: 'Custom Date',
              value: 'custom',
              meta: {
                year: false
              }
            }
          ]
        },
        {
          key: 'nationality',
          type: 'multi',
          label: 'Nationality',
          groupKey: 'contact',
          groupLabel: 'contact',
          values: [
            {
              label: 'Afghanistan',
              value: 'AF'
            },
            {
              label: '\u00c5land Islands',
              value: 'AX'
            },
            {
              label: 'Albania',
              value: 'AL'
            },
            {
              label: 'Algeria',
              value: 'DZ'
            },
            {
              label: 'American Samoa',
              value: 'AS'
            },
            {
              label: 'Andorra',
              value: 'AD'
            },
            {
              label: 'Angola',
              value: 'AO'
            },
            {
              label: 'Anguilla',
              value: 'AI'
            },
            {
              label: 'Antarctica',
              value: 'AQ'
            },
            {
              label: 'Antigua and Barbuda',
              value: 'AG'
            },
            {
              label: 'Argentina',
              value: 'AR'
            },
            {
              label: 'Armenia',
              value: 'AM'
            },
            {
              label: 'Aruba',
              value: 'AW'
            },
            {
              label: 'Australia',
              value: 'AU'
            },
            {
              label: 'Austria',
              value: 'AT'
            },
            {
              label: 'Azerbaijan',
              value: 'AZ'
            },
            {
              label: 'Bahamas',
              value: 'BS'
            },
            {
              label: 'Bahrain',
              value: 'BH'
            },
            {
              label: 'Bangladesh',
              value: 'BD'
            },
            {
              label: 'Barbados',
              value: 'BB'
            },
            {
              label: 'Belarus',
              value: 'BY'
            },
            {
              label: 'Belgium',
              value: 'BE'
            },
            {
              label: 'Belize',
              value: 'BZ'
            },
            {
              label: 'Benin',
              value: 'BJ'
            },
            {
              label: 'Bermuda',
              value: 'BM'
            },
            {
              label: 'Bhutan',
              value: 'BT'
            },
            {
              label: 'Bolivia, Plurinational State of',
              value: 'BO'
            },
            {
              label: 'Bosnia and Herzegovina',
              value: 'BA'
            },
            {
              label: 'Botswana',
              value: 'BW'
            },
            {
              label: 'Bouvet Island',
              value: 'BV'
            },
            {
              label: 'Brazil',
              value: 'BR'
            },
            {
              label: 'British Indian Ocean Territory',
              value: 'IO'
            },
            {
              label: 'Brunei Darussalam',
              value: 'BN'
            },
            {
              label: 'Bulgaria',
              value: 'BG'
            },
            {
              label: 'Burkina Faso',
              value: 'BF'
            },
            {
              label: 'Burundi',
              value: 'BI'
            },
            {
              label: 'Cambodia',
              value: 'KH'
            },
            {
              label: 'Cameroon',
              value: 'CM'
            },
            {
              label: 'Canada',
              value: 'CA'
            },
            {
              label: 'Cape Verde',
              value: 'CV'
            },
            {
              label: 'Cayman Islands',
              value: 'KY'
            },
            {
              label: 'Central African Republic',
              value: 'CF'
            },
            {
              label: 'Chad',
              value: 'TD'
            },
            {
              label: 'Chile',
              value: 'CL'
            },
            {
              label: 'China',
              value: 'CN'
            },
            {
              label: 'Christmas Island',
              value: 'CX'
            },
            {
              label: 'Cocos (Keeling) Islands',
              value: 'CC'
            },
            {
              label: 'Colombia',
              value: 'CO'
            },
            {
              label: 'Comoros',
              value: 'KM'
            },
            {
              label: 'Congo',
              value: 'CG'
            },
            {
              label: 'Congo, the Democratic Republic of the',
              value: 'CD'
            },
            {
              label: 'Cook Islands',
              value: 'CK'
            },
            {
              label: 'Costa Rica',
              value: 'CR'
            },
            {
              label: "C\u00f4te d'Ivoire",
              value: 'CI'
            },
            {
              label: 'Croatia',
              value: 'HR'
            },
            {
              label: 'Cuba',
              value: 'CU'
            },
            {
              label: 'Cyprus',
              value: 'CY'
            },
            {
              label: 'Czech Republic',
              value: 'CZ'
            },
            {
              label: 'Denmark',
              value: 'DK'
            },
            {
              label: 'Djibouti',
              value: 'DJ'
            },
            {
              label: 'Dominica',
              value: 'DM'
            },
            {
              label: 'Dominican Republic',
              value: 'DO'
            },
            {
              label: 'Ecuador',
              value: 'EC'
            },
            {
              label: 'Egypt',
              value: 'EG'
            },
            {
              label: 'El Salvador',
              value: 'SV'
            },
            {
              label: 'Equatorial Guinea',
              value: 'GQ'
            },
            {
              label: 'Eritrea',
              value: 'ER'
            },
            {
              label: 'Estonia',
              value: 'EE'
            },
            {
              label: 'Ethiopia',
              value: 'ET'
            },
            {
              label: 'Falkland Islands (Malvinas)',
              value: 'FK'
            },
            {
              label: 'Faroe Islands',
              value: 'FO'
            },
            {
              label: 'Fiji',
              value: 'FJ'
            },
            {
              label: 'Finland',
              value: 'FI'
            },
            {
              label: 'France',
              value: 'FR'
            },
            {
              label: 'French Guiana',
              value: 'GF'
            },
            {
              label: 'French Polynesia',
              value: 'PF'
            },
            {
              label: 'French Southern Territories',
              value: 'TF'
            },
            {
              label: 'Gabon',
              value: 'GA'
            },
            {
              label: 'Gambia',
              value: 'GM'
            },
            {
              label: 'Georgia',
              value: 'GE'
            },
            {
              label: 'Germany',
              value: 'DE'
            },
            {
              label: 'Ghana',
              value: 'GH'
            },
            {
              label: 'Gibraltar',
              value: 'GI'
            },
            {
              label: 'Greece',
              value: 'GR'
            },
            {
              label: 'Greenland',
              value: 'GL'
            },
            {
              label: 'Grenada',
              value: 'GD'
            },
            {
              label: 'Guadeloupe',
              value: 'GP'
            },
            {
              label: 'Guam',
              value: 'GU'
            },
            {
              label: 'Guatemala',
              value: 'GT'
            },
            {
              label: 'Guernsey',
              value: 'GG'
            },
            {
              label: 'Guinea',
              value: 'GN'
            },
            {
              label: 'Guinea-Bissau',
              value: 'GW'
            },
            {
              label: 'Guyana',
              value: 'GY'
            },
            {
              label: 'Haiti',
              value: 'HT'
            },
            {
              label: 'Heard Island and McDonald Islands',
              value: 'HM'
            },
            {
              label: 'Holy See (Vatican City State)',
              value: 'VA'
            },
            {
              label: 'Honduras',
              value: 'HN'
            },
            {
              label: 'Hong Kong',
              value: 'HK'
            },
            {
              label: 'Hungary',
              value: 'HU'
            },
            {
              label: 'Iceland',
              value: 'IS'
            },
            {
              label: 'India',
              value: 'IN'
            },
            {
              label: 'Indonesia',
              value: 'ID'
            },
            {
              label: 'Iran, Islamic Republic of',
              value: 'IR'
            },
            {
              label: 'Iraq',
              value: 'IQ'
            },
            {
              label: 'Ireland',
              value: 'IE'
            },
            {
              label: 'Isle of Man',
              value: 'IM'
            },
            {
              label: 'Israel',
              value: 'IL'
            },
            {
              label: 'Italy',
              value: 'IT'
            },
            {
              label: 'Jamaica',
              value: 'JM'
            },
            {
              label: 'Japan',
              value: 'JP'
            },
            {
              label: 'Jersey',
              value: 'JE'
            },
            {
              label: 'Jordan',
              value: 'JO'
            },
            {
              label: 'Kazakhstan',
              value: 'KZ'
            },
            {
              label: 'Kenya',
              value: 'KE'
            },
            {
              label: 'Kiribati',
              value: 'KI'
            },
            {
              label: "Korea, Democratic People's Republic of",
              value: 'KP'
            },
            {
              label: 'Korea, Republic of',
              value: 'KR'
            },
            {
              label: 'Kosovo',
              value: 'XK'
            },
            {
              label: 'Kuwait',
              value: 'KW'
            },
            {
              label: 'Kyrgyzstan',
              value: 'KG'
            },
            {
              label: "Lao People's Democratic Republic",
              value: 'LA'
            },
            {
              label: 'Latvia',
              value: 'LV'
            },
            {
              label: 'Lebanon',
              value: 'LB'
            },
            {
              label: 'Lesotho',
              value: 'LS'
            },
            {
              label: 'Liberia',
              value: 'LR'
            },
            {
              label: 'Libyan Arab Jamahiriya',
              value: 'LY'
            },
            {
              label: 'Liechtenstein',
              value: 'LI'
            },
            {
              label: 'Lithuania',
              value: 'LT'
            },
            {
              label: 'Luxembourg',
              value: 'LU'
            },
            {
              label: 'Macao',
              value: 'MO'
            },
            {
              label: 'Macedonia, the former Yugoslav Republic of',
              value: 'MK'
            },
            {
              label: 'Madagascar',
              value: 'MG'
            },
            {
              label: 'Malawi',
              value: 'MW'
            },
            {
              label: 'Malaysia',
              value: 'MY'
            },
            {
              label: 'Maldives',
              value: 'MV'
            },
            {
              label: 'Mali',
              value: 'ML'
            },
            {
              label: 'Malta',
              value: 'MT'
            },
            {
              label: 'Marshall Islands',
              value: 'MH'
            },
            {
              label: 'Martinique',
              value: 'MQ'
            },
            {
              label: 'Mauritania',
              value: 'MR'
            },
            {
              label: 'Mauritius',
              value: 'MU'
            },
            {
              label: 'Mayotte',
              value: 'YT'
            },
            {
              label: 'Mexico',
              value: 'MX'
            },
            {
              label: 'Micronesia, Federated States of',
              value: 'FM'
            },
            {
              label: 'Moldova, Republic of',
              value: 'MD'
            },
            {
              label: 'Monaco',
              value: 'MC'
            },
            {
              label: 'Mongolia',
              value: 'MN'
            },
            {
              label: 'Montenegro',
              value: 'ME'
            },
            {
              label: 'Montserrat',
              value: 'MS'
            },
            {
              label: 'Morocco',
              value: 'MA'
            },
            {
              label: 'Mozambique',
              value: 'MZ'
            },
            {
              label: 'Myanmar',
              value: 'MM'
            },
            {
              label: 'Namibia',
              value: 'NA'
            },
            {
              label: 'Nauru',
              value: 'NR'
            },
            {
              label: 'Nepal',
              value: 'NP'
            },
            {
              label: 'Netherlands',
              value: 'NL'
            },
            {
              label: 'Netherlands Antilles',
              value: 'AN'
            },
            {
              label: 'New Caledonia',
              value: 'NC'
            },
            {
              label: 'New Zealand',
              value: 'NZ'
            },
            {
              label: 'Nicaragua',
              value: 'NI'
            },
            {
              label: 'Niger',
              value: 'NE'
            },
            {
              label: 'Nigeria',
              value: 'NG'
            },
            {
              label: 'Niue',
              value: 'NU'
            },
            {
              label: 'Norfolk Island',
              value: 'NF'
            },
            {
              label: 'Northern Mariana Islands',
              value: 'MP'
            },
            {
              label: 'Norway',
              value: 'NO'
            },
            {
              label: 'Oman',
              value: 'OM'
            },
            {
              label: 'Pakistan',
              value: 'PK'
            },
            {
              label: 'Palau',
              value: 'PW'
            },
            {
              label: 'Palestinian Territory, Occupied',
              value: 'PS'
            },
            {
              label: 'Panama',
              value: 'PA'
            },
            {
              label: 'Papua New Guinea',
              value: 'PG'
            },
            {
              label: 'Paraguay',
              value: 'PY'
            },
            {
              label: 'Peru',
              value: 'PE'
            },
            {
              label: 'Philippines',
              value: 'PH'
            },
            {
              label: 'Pitcairn',
              value: 'PN'
            },
            {
              label: 'Poland',
              value: 'PL'
            },
            {
              label: 'Portugal',
              value: 'PT'
            },
            {
              label: 'Puerto Rico',
              value: 'PR'
            },
            {
              label: 'Qatar',
              value: 'QA'
            },
            {
              label: 'R\u00e9union',
              value: 'RE'
            },
            {
              label: 'Romania',
              value: 'RO'
            },
            {
              label: 'Russian Federation',
              value: 'RU'
            },
            {
              label: 'Rwanda',
              value: 'RW'
            },
            {
              label: 'Saint Barth\u00e9lemy',
              value: 'BL'
            },
            {
              label: 'Saint Helena',
              value: 'SH'
            },
            {
              label: 'Saint Kitts and Nevis',
              value: 'KN'
            },
            {
              label: 'Saint Lucia',
              value: 'LC'
            },
            {
              label: 'Saint Martin (French part)',
              value: 'MF'
            },
            {
              label: 'Saint Pierre and Miquelon',
              value: 'PM'
            },
            {
              label: 'Saint Vincent and the Grenadines',
              value: 'VC'
            },
            {
              label: 'Samoa',
              value: 'WS'
            },
            {
              label: 'San Marino',
              value: 'SM'
            },
            {
              label: 'Sao Tome and Principe',
              value: 'ST'
            },
            {
              label: 'Saudi Arabia',
              value: 'SA'
            },
            {
              label: 'Senegal',
              value: 'SN'
            },
            {
              label: 'Serbia',
              value: 'RS'
            },
            {
              label: 'Seychelles',
              value: 'SC'
            },
            {
              label: 'Sierra Leone',
              value: 'SL'
            },
            {
              label: 'Singapore',
              value: 'SG'
            },
            {
              label: 'Slovakia',
              value: 'SK'
            },
            {
              label: 'Slovenia',
              value: 'SI'
            },
            {
              label: 'Solomon Islands',
              value: 'SB'
            },
            {
              label: 'Somalia',
              value: 'SO'
            },
            {
              label: 'South Africa',
              value: 'ZA'
            },
            {
              label: 'South Georgia and the South Sandwich Islands',
              value: 'GS'
            },
            {
              label: 'Spain',
              value: 'ES'
            },
            {
              label: 'Sri Lanka',
              value: 'LK'
            },
            {
              label: 'Sudan',
              value: 'SD'
            },
            {
              label: 'Suriname',
              value: 'SR'
            },
            {
              label: 'Svalbard and Jan Mayen',
              value: 'SJ'
            },
            {
              label: 'Swaziland',
              value: 'SZ'
            },
            {
              label: 'Sweden',
              value: 'SE'
            },
            {
              label: 'Switzerland',
              value: 'CH'
            },
            {
              label: 'Syrian Arab Republic',
              value: 'SY'
            },
            {
              label: 'Taiwan, Province of China',
              value: 'TW'
            },
            {
              label: 'Tajikistan',
              value: 'TJ'
            },
            {
              label: 'Tanzania, United Republic of',
              value: 'TZ'
            },
            {
              label: 'Thailand',
              value: 'TH'
            },
            {
              label: 'Timor-Leste',
              value: 'TL'
            },
            {
              label: 'Togo',
              value: 'TG'
            },
            {
              label: 'Tokelau',
              value: 'TK'
            },
            {
              label: 'Tonga',
              value: 'TO'
            },
            {
              label: 'Trinidad and Tobago',
              value: 'TT'
            },
            {
              label: 'Tunisia',
              value: 'TN'
            },
            {
              label: 'Turkey',
              value: 'TR'
            },
            {
              label: 'Turkmenistan',
              value: 'TM'
            },
            {
              label: 'Turks and Caicos Islands',
              value: 'TC'
            },
            {
              label: 'Tuvalu',
              value: 'TV'
            },
            {
              label: 'Uganda',
              value: 'UG'
            },
            {
              label: 'Ukraine',
              value: 'UA'
            },
            {
              label: 'United Arab Emirates',
              value: 'AE'
            },
            {
              label: 'United Kingdom',
              value: 'GB'
            },
            {
              label: 'United States',
              value: 'US'
            },
            {
              label: 'United States Minor Outlying Islands',
              value: 'UM'
            },
            {
              label: 'Uruguay',
              value: 'UY'
            },
            {
              label: 'Uzbekistan',
              value: 'UZ'
            },
            {
              label: 'Vanuatu',
              value: 'VU'
            },
            {
              label: 'Venezuela, Bolivarian Republic of',
              value: 'VE'
            },
            {
              label: 'Viet Nam',
              value: 'VN'
            },
            {
              label: 'Virgin Islands, British',
              value: 'VG'
            },
            {
              label: 'Virgin Islands, U.S.',
              value: 'VI'
            },
            {
              label: 'Wallis and Futuna',
              value: 'WF'
            },
            {
              label: 'Western Sahara',
              value: 'EH'
            },
            {
              label: 'Yemen',
              value: 'YE'
            },
            {
              label: 'Zambia',
              value: 'ZM'
            },
            {
              label: 'Zimbabwe',
              value: 'ZW'
            }
          ]
        },
        {
          key: 'contactable',
          type: 'multi',
          label: 'Contactable',
          groupKey: 'contact',
          groupLabel: 'contact',
          values: [
            {
              label: 'Email',
              value: 'email'
            },
            {
              label: 'Telephone',
              value: 'phone'
            }
          ]
        },
        {
          type: 'group_label',
          label: 'Reservation Data Since {select}',
          groupKey: 'stays_since',
          groupLabel: 'stays_since',
          components: {
            select: {
              key: 'stays_since',
              type: 'single',
              values: [
                {
                  label: 'N/A',
                  value: null
                },
                {
                  label: '2024',
                  value: 2024
                },
                {
                  label: '2023',
                  value: 2023
                },
                {
                  label: '2022',
                  value: 2022
                },
                {
                  label: '2021',
                  value: 2021
                },
                {
                  label: '2020',
                  value: 2020
                },
                {
                  label: '2019',
                  value: 2019
                },
                {
                  label: '2018',
                  value: 2018
                },
                {
                  label: '2017',
                  value: 2017
                },
                {
                  label: '2016',
                  value: 2016
                },
                {
                  label: '2015',
                  value: 2015
                }
              ]
            }
          }
        },
        {
          key: 'stay_duration',
          type: 'multi',
          label: 'Stay Duration',
          groupKey: 'stays_since',
          groupLabel: 'stays_since',
          values: [
            {
              label: '1-3 Days',
              value: '1-3'
            },
            {
              label: '4-6 Days',
              value: '4-6'
            },
            {
              label: '>=7 Days',
              value: '>=7'
            }
          ]
        },
        {
          key: 'booking_lead_time',
          type: 'multi',
          label: 'Booking Lead Time',
          groupKey: 'stays_since',
          groupLabel: 'stays_since',
          values: [
            {
              label: '0-7 Days',
              value: '0-7'
            },
            {
              label: '8-30 Days',
              value: '8-30'
            },
            {
              label: '31-60 Days',
              value: '31-60'
            },
            {
              label: '61-120 Days',
              value: '61-120'
            },
            {
              label: '121-180 Days',
              value: '121-180'
            },
            {
              label: '>=181 Days',
              value: '>=181'
            }
          ]
        },
        {
          key: 'trip_group',
          type: 'multi',
          label: 'Trip Group',
          groupKey: 'stays_since',
          groupLabel: 'stays_since',
          values: [
            {
              label: '1 Adult',
              value: 'one_adult'
            },
            {
              label: '2 Adults',
              value: 'two_adults'
            },
            {
              label: '1-2 Adults with Child(ren)',
              value: 'adults_with_children'
            },
            {
              label: 'Group',
              value: 'group'
            },
            {
              label: 'Group with Child(ren)',
              value: 'group_with_children'
            },
            {
              label: 'Other',
              value: 'other'
            }
          ]
        },
        {
          key: 'stay_month',
          type: 'multi',
          label: 'Stay Months',
          groupKey: 'stays_since',
          groupLabel: 'stays_since',
          values: [
            {
              label: 'January',
              value: '1'
            },
            {
              label: 'February',
              value: '2'
            },
            {
              label: 'March',
              value: '3'
            },
            {
              label: 'April',
              value: '4'
            },
            {
              label: 'May',
              value: '5'
            },
            {
              label: 'June',
              value: '6'
            },
            {
              label: 'July',
              value: '7'
            },
            {
              label: 'August',
              value: '8'
            },
            {
              label: 'September',
              value: '9'
            },
            {
              label: 'October',
              value: '10'
            },
            {
              label: 'November',
              value: '11'
            },
            {
              label: 'December',
              value: '12'
            }
          ]
        },
        {
          type: 'group_label',
          label: 'Past Stays',
          groupKey: 'past_stays',
          groupLabel: 'past_stays'
        },
        {
          key: 'stays',
          type: 'multi',
          label: 'Stays',
          groupKey: 'past_stays',
          groupLabel: 'past_stays',
          values: [
            {
              label: '0x',
              value: '0'
            },
            {
              label: '1x',
              value: '1'
            },
            {
              label: '2x',
              value: '2'
            },
            {
              label: '3x',
              value: '3'
            },
            {
              label: '4-5x',
              value: '4-5'
            },
            {
              label: '6-7x',
              value: '6-7'
            },
            {
              label: '8-9x',
              value: '8-9'
            },
            {
              label: '10-14x',
              value: '10-14'
            },
            {
              label: '15-19x',
              value: '15-19'
            },
            {
              label: '>=20x',
              value: '>=20'
            }
          ]
        },
        {
          key: 'stay_year',
          type: 'multi',
          label: 'Stay Year',
          groupKey: 'past_stays',
          groupLabel: 'past_stays',
          values: [
            {
              label: '2024',
              value: 2024
            },
            {
              label: '2023',
              value: 2023
            },
            {
              label: '2022',
              value: 2022
            },
            {
              label: '2021',
              value: 2021
            },
            {
              label: '2020',
              value: 2020
            },
            {
              label: '2019',
              value: 2019
            },
            {
              label: '2018',
              value: 2018
            },
            {
              label: '2017',
              value: 2017
            },
            {
              label: '2016',
              value: 2016
            },
            {
              label: '2015',
              value: 2015
            }
          ]
        },
        {
          key: 'last_stay_year',
          type: 'multi',
          label: 'Last Stay Year',
          groupKey: 'past_stays',
          groupLabel: 'past_stays',
          values: [
            {
              label: '2024',
              value: 2024
            },
            {
              label: '2023',
              value: 2023
            },
            {
              label: '2022',
              value: 2022
            },
            {
              label: '2021',
              value: 2021
            },
            {
              label: '2020',
              value: 2020
            },
            {
              label: '2019',
              value: 2019
            },
            {
              label: '2018',
              value: 2018
            },
            {
              label: '2017',
              value: 2017
            },
            {
              label: '2016',
              value: 2016
            },
            {
              label: '2015',
              value: 2015
            }
          ]
        },
        {
          key: 'last_stay_duration',
          type: 'multi',
          label: 'Length of Last Stay',
          groupKey: 'past_stays',
          groupLabel: 'past_stays',
          values: [
            {
              label: '1-3 Days',
              value: '1-3'
            },
            {
              label: '4-6 Days',
              value: '4-6'
            },
            {
              label: '>=7 Days',
              value: '>=7'
            }
          ]
        },
        {
          key: 'total_stay_duration',
          type: 'multi',
          label: 'Total Stay Duration',
          groupKey: 'past_stays',
          groupLabel: 'past_stays',
          values: [
            {
              label: '1-3 Nights',
              value: '1-3'
            },
            {
              label: '4-6 Nights',
              value: '4-6'
            },
            {
              label: '7-20 Nights',
              value: '7-21'
            },
            {
              label: '> 21 Nights',
              value: '> 21'
            }
          ]
        },
        {
          key: 'revenue',
          type: 'range',
          label: 'Reservation Revenue',
          groupKey: 'past_stays',
          groupLabel: 'past_stays',
          values: [
            {
              label: 'min',
              value: 0
            },
            {
              label: 'max',
              value: 7156470
            }
          ]
        },
        {
          key: 'revenue_extras',
          type: 'range',
          label: 'Revenue from Extras',
          groupKey: 'past_stays',
          groupLabel: 'past_stays',
          values: [
            {
              label: 'min',
              value: 0
            },
            {
              label: 'max',
              value: 1526595
            }
          ]
        },
        {
          type: 'group_label',
          label: 'Current and Upcoming Stays',
          groupKey: 'current_stays',
          groupLabel: 'current_stays'
        },
        {
          key: 'next_stay_year',
          type: 'multi',
          label: 'Next Stay Year',
          groupKey: 'current_stays',
          groupLabel: 'current_stays',
          values: [
            {
              label: 'No one',
              value: 'no_stay'
            },
            {
              label: '2024',
              value: 'current_year'
            },
            {
              label: '2025',
              value: 'next_year'
            },
            {
              label: '2026',
              value: 'after_next_year'
            }
          ]
        },
        {
          key: 'is_present',
          type: 'date_range',
          label: 'Is Present',
          groupKey: 'current_stays',
          groupLabel: 'current_stays',
          values: [
            {
              label: 'Ignore',
              value: null
            },
            {
              label: 'Today not present',
              value: 'not_present'
            },
            {
              label: 'Today',
              value: 'today'
            },
            {
              label: 'Tomorrow',
              value: 'tomorrow'
            },
            {
              label: 'Custom Date',
              value: 'custom'
            }
          ]
        },
        {
          key: 'is_arriving',
          type: 'date_range',
          label: 'Is Arriving',
          groupKey: 'current_stays',
          groupLabel: 'current_stays',
          values: [
            {
              label: 'Ignore',
              value: null
            },
            {
              label: 'Today',
              value: 'today'
            },
            {
              label: 'Tomorrow',
              value: 'tomorrow'
            },
            {
              label: 'Custom Date',
              value: 'custom'
            }
          ]
        },
        {
          key: 'is_leaving',
          type: 'date_range',
          label: 'Is Leaving',
          groupKey: 'current_stays',
          groupLabel: 'current_stays',
          values: [
            {
              label: 'Ignore',
              value: null
            },
            {
              label: 'Today',
              value: 'today'
            },
            {
              label: 'Tomorrow',
              value: 'tomorrow'
            },
            {
              label: 'Custom Date',
              value: 'custom'
            }
          ]
        },
        {
          key: 'has_open_request',
          type: 'single',
          label: 'Has Open Requests',
          groupKey: 'current_stays',
          groupLabel: 'current_stays',
          values: [
            {
              label: 'Ignore',
              value: null
            },
            {
              label: 'Yes',
              value: true
            },
            {
              label: 'No',
              value: false
            }
          ]
        },
        {
          type: 'group_label',
          label: 'Source',
          groupKey: 'source',
          groupLabel: 'source'
        },
        {
          key: 'facebook_lead',
          type: 'multi',
          label: 'Facebook Lead Campaign',
          groupKey: 'source',
          groupLabel: 'source',
          values: [
            {
              label: 'LEAD-ADS-CAMPAIGN',
              value: 'LEAD-ADS-CAMPAIGN'
            }
          ]
        },
        {
          key: 'on_page_lead',
          type: 'multi',
          label: 'On-Page Lead Campaign',
          groupKey: 'source',
          groupLabel: 'source',
          values: [
            {
              label: 'Gutscheine f\u00fcr Alle',
              value: 'Gutscheine f\u00fcr Alle'
            },
            {
              label: 'On-Page Landingpage',
              value: 'On-Page Landingpage'
            },
            {
              label: 'On-Page Landingpage Mobile',
              value: 'On-Page Landingpage Mobile'
            },
            {
              label: 'On-Page Website',
              value: 'On-Page Website'
            },
            {
              label: 'On-Page Website Mobil',
              value: 'On-Page Website Mobil'
            },
            {
              label: 'Gutschein Kampagne',
              value: 'Gutschein Kampagne'
            }
          ]
        },
        {
          key: 'referral_marketing_lead',
          type: 'multi',
          label: 'Referral Marketing Campaign',
          groupKey: 'source',
          groupLabel: 'source',
          values: [
            {
              label: 'Gutschein',
              value: 'Gutschein'
            },
            {
              label: 'Voucher',
              value: 'Voucher'
            }
          ]
        }
      ]
    };
  });

  server.get('/subscribers/:id', (schema, { params: { id } }) => {
    return schema.subscribers.find(id);
  });

  server.put('/subscribers/:id', (schema, request) => {
    const id = request.params.id;
    const requestBody = JSON.parse(request.requestBody);

    const subscriber = schema.subscribers.find(id);
    return subscriber.update(requestBody);
  });
};
