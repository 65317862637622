import ApplicationAdapter from './application';
import { ACCEPT_HEADER } from 'additive-newsletter/utils/constants';

export default class CampaignBlockAdapter extends ApplicationAdapter {
  get headers() {
    return Object.assign({}, super.headers, ACCEPT_HEADER);
  }

  urlForMultiple(_, snapshot) {
    let url = this.buildURL();
    if (snapshot?.adapterOptions?.campaign) {
      const campaign = snapshot.adapterOptions.campaign;
      url += `/campaigns/${campaign}/blocks`;
    }

    return url;
  }

  urlForSingle(id, modelName, snapshot) {
    return `${this.urlForMultiple(modelName, snapshot)}/${id}`;
  }

  urlForFindRecord() {
    return this.urlForSingle(...arguments);
  }

  urlForFindAll() {
    return this.urlForMultiple(...arguments);
  }

  urlForCreateRecord() {
    return this.urlForMultiple(...arguments);
  }

  urlForUpdateRecord() {
    return this.urlForSingle(...arguments);
  }

  urlForDeleteRecord() {
    return this.urlForSingle(...arguments);
  }
}
