import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class InstanceAddressBooksAddressBookGroupsGroupSubscribersNewController extends Controller {
  @service router;
  @service uiFilter;

  /**
   * the model containing the address book and the group id
   *
   * @argument model
   * @type {Model}
   * @default null
   */
  model = null;

  @action
  onClose() {
    this.router.transitionTo('instance.address-books.address-book.groups.group.subscribers');
  }

  @action
  onSubmit() {
    this.uiFilter.reset('group-subscribers');
  }
}
