import { Factory } from 'ember-cli-mirage';
import faker from 'faker';

export default Factory.extend({
  name: () => faker.lorem.word(),
  active: () => faker.random.boolean(),
  styles: () => ({
    globals: {
      borderRadius: '2',
      border: '1',
      boxShadow: '0 1px 2px 0 rgba(0,0,0,0.2)'
    },
    colors: {
      main: {
        color: '#967e6e',
        contrastColor: '#ffffff',
        sync: true,
        syncedValue: 'main'
      },
      accent: {
        color: '#967e6e',
        contrastColor: '#ffffff',
        sync: false,
        syncedValue: 'accent'
      },
      ambient: {
        color: '#967e6e',
        contrastColor: '#ffffff',
        sync: true,
        syncedValue: 'ambient'
      }
    },
    typography: {
      h1: {
        fontFamily:
          '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif',
        fontSize: '32px',
        lineHeight: '40px',
        color: 'main',
        serif: false,
        sync: true,
        syncedTypography: 'h1'
      },
      h2: {
        fontFamily:
          '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif',
        fontSize: '24px',
        lineHeight: '28px',
        color: 'main',
        serif: false,
        sync: true,
        syncedTypography: 'h2'
      },
      h3: {
        fontFamily:
          '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif',
        fontSize: '24px',
        lineHeight: '28px',
        color: 'main',
        serif: false,
        sync: true,
        syncedTypography: 'h3'
      },
      p: {
        fontFamily: '"Book Antiqua",Palatino,"Palatino Linotype","Palatino LT STD",Georgia,serif',
        fontSize: '18px',
        lineHeight: '24px',
        color: 'main',
        serif: true,
        sync: true,
        syncedTypography: 'p'
      },
      small: {
        fontFamily: '"Book Antiqua",Palatino,"Palatino Linotype","Palatino LT STD",Georgia,serif',
        fontSize: '14px',
        lineHeight: '20px',
        color: 'main',
        serif: true,
        sync: true,
        syncedTypography: 'small'
      },
      label: {
        fontFamily: '"Book Antiqua",Palatino,"Palatino Linotype","Palatino LT STD",Georgia,serif',
        fontSize: '11px',
        lineHeight: '16px',
        color: 'main',
        serif: true,
        sync: true,
        syncedTypography: 'label'
      },
      button: {
        fontFamily: '"Book Antiqua",Palatino,"Palatino Linotype","Palatino LT STD",Georgia,serif',
        fontSize: '14px',
        lineHeight: '20px',
        color: 'main',
        serif: true,
        sync: true,
        syncedTypography: 'button'
      }
    }
  })
});
