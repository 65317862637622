import { validateFormat } from 'ember-changeset-validations/validators';

/**
 *  @module Validators
 */

/**
 * Validates multiple emails in an array
 *
 * @function multipleEmailsValidator
 *
 * @param {Any} value
 * @param {Object} options
 * @param {String} options.message Error message
 *
 * @return {Boolean|ValidationErr}
 */
export function multipleEmailsValidator(options = {}) {
  const presenceMessage = options?.presenceMessage || false;
  const emailMessage = options?.emailMessage || false;

  return (key, value) => {
    let isPresent = true;
    let isValid = true;

    const placeholders = value;

    if (!value?.length) {
      isPresent = false;
    }

    const validation = validateFormat({ type: 'email', message: emailMessage });
    placeholders?.forEach((placeholder) => {
      if (validation(key, placeholder) !== true) {
        isValid = false;
      }
    });

    return !isPresent ? presenceMessage : isValid || emailMessage;
  };
}
