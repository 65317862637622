export const Groups = (server) => {
  server.get('/addressbooks/:aId/groups', (schema, { params: { aId } }) => {
    return schema.groups.where({ addressBookId: aId });
  });
  server.get('/addressbooks/:aId/groups/:id', (schema, { params: { aId, id } }) => {
    const found = schema.groups.where({ addressBookId: aId, id });

    return found.models.length > 0 ? found.models[0] : null;
  });
};
