import Model, { attr } from '@ember-data/model';
import { lt } from '@ember/object/computed';

export default Model.extend({
  name: attr('string'),
  description: attr('string'),
  selector: attr('string'),
  type: attr('string'),
  addressBookId: attr('string'),
  groupId: attr('string'),
  version: attr('string', { defaultValue: 3 }),

  config: attr(),
  styles: attr(),
  texts: attr({
    defaultValue() {
      return {};
    }
  }),
  tracking: attr(),

  language: attr('string', { defaultValue: 'de' }),
  availableLanguages: attr(),

  isLegacy: lt('version', 3)
});
