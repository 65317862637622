import Component from '@ember/component';
import { isArray } from '@ember/array';
import { computed, set } from '@ember/object';
import { alias, gt } from '@ember/object/computed';
import { inject as service } from '@ember/service';

/**
 * Handles the switch of content block variations
 *
 * @module anl-campaign-configurator
 * @class anl-campaign-configurator/content-block-variation-switch
 */
export default Component.extend({
  store: service(),

  classNames: ['anl-content-block-variation-switch', 'absolute', 'font-sm white'],

  /**
   * the type of this block
   *
   * @argument type
   * @type {String}
   * @default null
   */
  variationId: null,

  /**
   * array of variations for this block
   *
   * @argument _variations
   * @type {Array}
   * @default null
   * @private
   */
  _variations: null,

  /**
   * whether the variation switch is shown
   *
   * @computed _showVariationSwitch
   * @type {Boolean}
   * @private
   */
  _showVariationSwitch: gt('_variations.length', 1),

  /**
   * the count of variations for this block
   *
   * @computed _variationTotalCount
   * @type {Number}
   * @private
   */
  _variationTotalCount: alias('_variations.length'),

  /**
   * the array index of the current variation id
   *
   * @computed _currentVariationIndex
   * @type {Number}
   * @private
   */
  _currentVariationIndex: computed('variationId', '_variations.[]', {
    get() {
      if (!isArray(this._variations) || !this.variationId) {
        return;
      }

      return this._variations.indexOf(this.variationId) + 1;
    }
  }),

  didInsertElement() {
    this._super(...arguments);
    let contentBlocks = this.store.peekAll('content-block');

    const contentBlock =
      isArray(contentBlocks) &&
      contentBlocks.find((contentBlock) => {
        return (
          isArray(contentBlock.contentBlockVariations) &&
          contentBlock.contentBlockVariations.includes(this.variationId)
        );
      });

    if (contentBlock) {
      set(this, '_variations', contentBlock.contentBlockVariations);
    }
  },

  onVariationSwitch() {},

  actions: {
    previousVariation() {
      const variations = this._variations;
      if (isArray(variations)) {
        const currentIndex = this._currentVariationIndex && this._currentVariationIndex - 1;
        const previousIndex = currentIndex === 0 ? variations.length - 1 : currentIndex - 1;
        set(this, 'variationId', variations[previousIndex]);
        this.onVariationSwitch();
      }
    },

    nextVariation() {
      const variations = this._variations;
      if (isArray(variations)) {
        const currentIndex = this._currentVariationIndex && this._currentVariationIndex - 1;
        const nextIndex = currentIndex === variations.length - 1 ? 0 : currentIndex + 1;
        set(this, 'variationId', variations[nextIndex]);
        this.onVariationSwitch();
      }
    }
  }
});
