import Service from '@ember/service';

/**
 * Service to register callbacks which get triggered when the ember store did change.
 *
 * !!Do not forget to unregister!!
 *
 * Register
 * ```js
 * this.uiStoreEvents.register('my-key', 'modelName', () => {
 *   // Code which gets executed on store change
 * });
 * ```
 *
 *  Unregister
 * ```js
 * this.uiStoreEvents.unregister('my-key');
 * ```
 *
 * @class ui-store-events
 * @public
 */
export default Service.extend({
  _registeredCallbacks: null,

  init() {
    this._super(...arguments);

    this._registeredCallbacks = {};
  },

  /**
   * To register an callback, by key
   *
   * @function register
   * @param {string}   key  A unique key needed for unregister
   * @param {string}   modelName  The model name which is observed
   * @param {function} callback  The callback which gets triggered
   * @return {Void}
   */
  register(key, modelName, callback) {
    this._registeredCallbacks[key] = {
      modelName,
      callback
    };
  },

  /**
   * To unregister an registered callback, by key
   *
   * @function unregister
   * @param {string}   key  The key to unregister
   * @return {Void}
   */
  unregister(key) {
    let callbacks = this._registeredCallbacks;
    delete callbacks[key];
    this._registeredCallbacks = callbacks;
  },

  /**
   * Which gets triggered, when the model in the store got updated
   */
  didChange(modelName) {
    Object.keys(this._registeredCallbacks).map((key) => {
      if (this._registeredCallbacks[key].modelName === modelName) {
        const callback = this._registeredCallbacks[key].callback;
        typeof callback === 'function' && callback(...arguments);
      }
    });
  },

  /**
   * Which gets triggered for a specific registered key
   */
  updateByKey(key) {
    if (this._registeredCallbacks[key]) {
      const callback = this._registeredCallbacks[key].callback;
      typeof callback === 'function' && callback(...arguments);
    }
  }
});
