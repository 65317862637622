import Controller from '@ember/controller';

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { computed } from '@ember/object';

import {
  CAMPAIGN_STATES,
  PENDING_CAMPAIGN_STATES,
  SENT_CAMPAIGN_STATES
} from 'additive-newsletter/utils/constants';

export default class InstanceCampaignsController extends Controller {
  @service currentUser;
  @service intl;
  @service router;
  @service uiDialog;
  @service uiFilter;

  @tracked
  isCampaignTypeDialog = false;

  @tracked
  isLegacyCreateDialog = false;

  @tracked
  isCreateDialog = false;

  @computed('router.currentRouteName')
  get activeDetail() {
    return this.router.isActive('instance.campaigns.index.campaign')
      ? 'instance.campaigns.index.campaign.index'
      : 'instance.campaigns.sent.campaign';
  }

  get campaignFilter() {
    const campaignFilter = this.uiFilter.getQPFilter('campaigns');
    return campaignFilter && campaignFilter.filter;
  }

  @computed('campaignFilter.data.[]')
  get filterDraftCampaigns() {
    const campaigns = this.campaignFilter && this.campaignFilter.data;

    return campaigns.filter((campaign) => {
      return campaign.status === CAMPAIGN_STATES.DRAFT;
    });
  }

  @computed('campaignFilter.data.[]')
  get filterFailedCampaigns() {
    const campaigns = this.campaignFilter && this.campaignFilter.data;

    return campaigns.filter((campaign) => {
      return (
        campaign.status === CAMPAIGN_STATES.SENDING_FAILED_NO_SUBSCRIBERS ||
        campaign.status === CAMPAIGN_STATES.SENDING_FAILED_NO_VALID_EMAIL
      );
    });
  }

  @computed('campaignFilter.data.[]')
  get filterPendingCampaigns() {
    const campaigns = this.campaignFilter && this.campaignFilter.data;

    return campaigns.filter((campaign) => {
      return PENDING_CAMPAIGN_STATES.indexOf(campaign.status) !== -1;
    });
  }

  @computed('campaignFilter.data.[]')
  get filterSentCampaigns() {
    const campaigns = this.campaignFilter && this.campaignFilter.data;

    return campaigns.filter((campaign) => {
      return SENT_CAMPAIGN_STATES.indexOf(campaign.status) !== -1;
    });
  }

  get _isViewer() {
    return this.currentUser.isViewer;
  }

  @action
  back() {
    this.router.transitionTo('instance.campaigns');
  }

  @action
  onCreateButtonClick() {
    this.isCreateDialog = true;
  }
}
