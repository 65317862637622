import { Factory } from 'ember-cli-mirage';
import faker from 'faker';

import {
  SUBSCRIBER_SOURCE_MANUAL,
  SUBSCRIBER_SOURCE_IMPORT,
  SUBSCRIBER_SOURCE_HOTEL_SOFTWARE_IMPORT,
  SUBSCRIBER_SOURCE_ENQUIRY_IMPORT,
  SUBSCRIBER_SOURCE_ENQUIRY_IMPORT_ALL,
  SUBSCRIBER_SOURCE_WEBSITE,
  SUBSCRIBER_SOURCE_MANAGE_SUBSCRIPTION
} from 'additive-newsletter/utils/constants';

export default Factory.extend({
  createdAt: () => faker.date.recent(),
  updatedAt: () => faker.date.recent(),
  note: () => faker.lorem.words(),
  email: () => faker.internet.email(),
  source: () =>
    faker.random.arrayElement([
      SUBSCRIBER_SOURCE_MANUAL,
      SUBSCRIBER_SOURCE_IMPORT,
      SUBSCRIBER_SOURCE_HOTEL_SOFTWARE_IMPORT,
      SUBSCRIBER_SOURCE_ENQUIRY_IMPORT,
      SUBSCRIBER_SOURCE_ENQUIRY_IMPORT_ALL,
      SUBSCRIBER_SOURCE_WEBSITE,
      SUBSCRIBER_SOURCE_MANAGE_SUBSCRIPTION
    ])
});
