import { isArray } from '@ember/array';
import { helper } from '@ember/component/helper';
import { calculatePercentage } from 'additive-newsletter/utils/number-utils';

export const _calculatePercentage = (params) => {
  let value = null;
  let total = null;
  let decimalCount = 2;

  if (isArray(params)) {
    [value, total, decimalCount] = params;
  } else {
    value = params;
  }

  return calculatePercentage(value, total, decimalCount);
};

export default helper(_calculatePercentage);
