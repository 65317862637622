import ENV from 'additive-newsletter/config/environment';
import { ACCEPT_HEADER } from 'additive-newsletter/utils/constants';
import Controller from '@ember/controller';

import { task, timeout, all } from 'ember-concurrency';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class SettingsTextsController extends Controller {
  @service authenticatedFetch;
  @service uiAppSettings;
  @service currentUser;
  @service uiToast;
  @service intl;

  /**
   * the available languages
   *
   * @property availableLanguages
   * @type {Array}
   * @default null
   */
  @tracked
  availableLanguages = null;

  /**
   * the current language
   *
   * @property currentLanguage
   * @type {String}
   * @default null
   */
  @tracked
  currentLanguage = null;

  /**
   * whether the subscriber deactivation setting is active
   *
   * @property isSubscriberDeactivation
   * @type {Boolean}
   * @default false
   */
  @tracked
  isSubscriberDeactivation = false;

  /**
   * supported languages
   *
   * @computed langugages
   * @type {Array}
   */
  get languages() {
    return this.uiAppSettings?.languages?.contentLanguages;
  }

  /**
   * the current organization id
   *
   * @computed organizationId
   * @type {String}
   */
  get organizationId() {
    return this.currentUser?.currentOrganization?.id;
  }

  /**
   * fetches the general settings
   *
   * @function fetchGeneralSettings
   * @type {Task}
   */
  @task(function* () {
    const url = `${ENV.APP.apiBaseHost}/${this.organizationId}`;

    try {
      let tasks = [];

      const requestOptions = {
        headers: ACCEPT_HEADER
      };
      const request = this.authenticatedFetch.fetch(`${url}/settings/general`, requestOptions);

      tasks.push(request);
      tasks.push(timeout(400));

      const [response] = yield all(tasks);
      if (!response || !response.ok) {
        throw new Error('[SETTINGS] general settings', response);
      }

      const json = yield response.json();

      if (!json || !json.general) {
        throw new Error('[SETTINGS] general settings');
      }

      this.isSubscriberDeactivation = json.general?.deactivateInactive?.enabled || false;
    } catch (error) {
      this.uiToast.showToast({
        title: this.intl.t('global.toast.error.savedChanges'),
        type: 'error'
      });
    }
  })
  fetchGeneralSettings;

  @action
  setLanguages(language, availableLanguages) {
    if (this.currentLanguage !== language) {
      this.currentLanguage = language;
    }

    this.availableLanguages = availableLanguages;
  }

  @action
  changeLanguage(lang) {
    this.currentLanguage = lang;
  }
}
