import RESTSerializer from '@ember-data/serializer/rest';

export default RESTSerializer.extend({
  attrs: {
    key: { serialize: false },
    htmlUrl: { serialize: false },
    contentBlocks: { serialize: false }
  },

  serialize() {
    let json = this._super(...arguments);

    // remove styles properties for custom fonts to avoid validation error on PUT
    delete json.styles.customFonts;
    delete json.styles.hasCustomFonts;

    return json;
  }
});
