export const Integrations = (server) => {
  server.get(`/settings/integrations`, () => {
    return {
      integrations: {
        googleAnalyticsProfile: [],
        asaSyncEnabled: true,
        enquirySyncEnabled: true,
        onPageLeads: { enabled: true, lastImport: new Date() },
        facebookLeads: { enabled: true, lastImport: new Date() },
        pmsStatus: null
      }
    };
  });
};
