import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div ...attributes {{did-update this.onDidUpdate @organizationSlug}}>\n  <div class=\"flex flex-column items-start\">\n    <UiSelect\n      @label={{t \"dashboard.periodSelection.label\"}}\n      @selected={{this.selected.name}}\n      @onChange={{this.onChange}}\n      @loadOptions={{perform this.loadPeriods}}\n      @reloadOptionsOnOpen={{this._shouldReload}} as |option item|\n    >\n      <option.item @value={{item}}>\n        {{item.name}}\n      </option.item>\n    </UiSelect>\n  </div>\n</div>", {"contents":"<div ...attributes {{did-update this.onDidUpdate @organizationSlug}}>\n  <div class=\"flex flex-column items-start\">\n    <UiSelect\n      @label={{t \"dashboard.periodSelection.label\"}}\n      @selected={{this.selected.name}}\n      @onChange={{this.onChange}}\n      @loadOptions={{perform this.loadPeriods}}\n      @reloadOptionsOnOpen={{this._shouldReload}} as |option item|\n    >\n      <option.item @value={{item}}>\n        {{item.name}}\n      </option.item>\n    </UiSelect>\n  </div>\n</div>","moduleName":"additive-newsletter/components/anl-dashboard/period-selection.hbs","parseOptions":{"srcName":"additive-newsletter/components/anl-dashboard/period-selection.hbs"}});
import Component from '@glimmer/component';
import { arg } from 'ember-arg-types';
import { array, func, string, object } from 'prop-types';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { ACCEPT_HEADER } from 'additive-newsletter/utils/constants';

import { task, all, timeout } from 'ember-concurrency';
import { inject as service } from '@ember/service';

export default class AnlDashboardPeriodSelectionComponent extends Component {
  @service authenticatedFetch;
  @service intl;
  @service uiPaths;
  @service uiToast;

  /**
   * the current organization slug
   *
   * @property organizationSlug
   * @type {String}
   * @default null
   */
  @arg(string)
  @tracked
  organizationSlug = null;

  /**
   * current selected time period
   *
   * @argument selected
   * @type {Object | Array}
   * @default null
   */
  @arg(object | array)
  @tracked
  selected = null;

  /**
   * the organization slug of which the chart-data was fetched,
   * used to detect whether the slug has changes
   *
   * @property _previousOrganization
   * @type {String}
   * @default null
   * @private
   */
  @tracked
  _previousOrganization = null;

  /*
   * @property _shouldReload
   * indicates whether the periods should be reloaded
   * @type {Boolean}
   * @default false
   * @private
   */
  @tracked
  _shouldReload = false;

  @action
  onDidUpdate() {
    // reload indicator data on organization change
    if (this.organizationSlug !== this._previousOrganization) {
      this._shouldReload = true;
      this._previousOrganization = this.organizationSlug;
    }
  }

  /**
   * <slug>/dashboard/filters
   *
   * Loads the available periods for the select
   * Pre-selects the first item
   *
   * @throws {Error}
   */
  @task(function* () {
    try {
      let tasks = [];
      const url = this.uiPaths
        .pathsByRouteName('instance.dashboard', this.organizationSlug)
        .api().url;
      // load periods
      tasks.push(
        this.authenticatedFetch.fetch(`${url}/dashboard/filters`, {
          headers: ACCEPT_HEADER,
          method: 'GET'
        })
      );
      tasks.push(timeout(250));

      const [response] = yield all(tasks);

      if (!response || !response.ok) {
        throw new Error('[DASHBOARD] Error in response');
      }

      const { timePeriods } = yield response.json();

      let data = [];
      if (!Array.isArray(timePeriods)) {
        throw new Error('[DASHBOARD] Error while preparing data');
      }

      timePeriods.forEach((period) => {
        // Translate if it's a string e.g. last_12_months
        if (typeof period === 'string') {
          data.push({
            name: this.intl.t(`dashboard.periodSelection.${period}`),
            value: period
          });
        } else {
          data.push({ name: period.label, value: period.key });
        }
      });

      // Pre-select first item
      this.onChange(data[0]);
      this._shouldReload = false;

      return data;
    } catch (error) {
      this.uiToast.showToast({
        title: this.intl.t('toast.unexpectedError'),
        type: 'error'
      });
    }
  })
  loadPeriods;

  @arg(func)
  onChange = () => {};
}
