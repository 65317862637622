import { isEmpty } from '@ember/utils';

/**
 * calculates the percentage of a value to a total amount
 * returns it as formatted string
 *
 * @function calculatePercentage
 * @param {number} value
 * @param {number} total
 * @return {String} percentage
 */
export const calculatePercentage = (value, total, decimalCount = 2) => {
  if (isEmpty(value) || isNaN(value) || isEmpty(total) || isNaN(total) || total === 0) {
    return null;
  }

  let percentage = roundNumber((value / total) * 100, decimalCount);

  if (percentage === null) {
    return null;
  }

  percentage = percentage.toLocaleString('de');

  return `${percentage}%`;
};

/**
 * round number to at most a given number of decimals
 * if the given number is not a number, null is returned
 * the default number of decimals is 2
 *
 * @function roundNumber
 * @param {number} value
 * @param {number} decimalCount
 * @return {number} roundedValue
 */
export const roundNumber = (value, decimalCount = 2) => {
  if (isEmpty(value) || isNaN(value)) {
    return null;
  }

  if (value === 0) {
    return value;
  }

  value = Number(value);
  if (decimalCount === 0) {
    value = value.toFixed(decimalCount);
  } else {
    /* https://www.jacklmoore.com/notes/rounding-in-javascript/ */
    value = Number(Math.round(value + `e${decimalCount}`) + `e-${decimalCount}`);
  }

  return value;
};

/**
 * turns seconds into minutes
 *
 * @function toMinutes
 * @param {number} value
 * @return {String} minutes
 */
export const toMinutes = (value) => {
  if (isEmpty(value) || isNaN(value)) {
    return null;
  }

  const minutes = Math.floor(value / 60);
  const seconds = value % 60;

  const minutesString = ('0' + minutes).slice(-2);
  const secondsString = ('0' + seconds).slice(-2);

  return `${minutesString}:${secondsString}`;
};
