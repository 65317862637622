import ENV from 'additive-newsletter/config/environment';

import {
  GenderChart,
  ClientChart,
  MobileChart,
  KeyIndicators,
  Conversions,
  LinkActivities,
  MarketingInsights
} from './routes/reports';
import { Detail as CampaignsDetail, List as CampaignsList } from './routes/campaigns';
import { Groups, AddressBooks, Subscribers as AddressBookSubscribers } from './routes/address-book';
import { Subscribers } from './routes/subscriber';
import { Interests } from './routes/interest';
import { Countries } from './routes/additive-utils';
import { Filters } from './routes/filters';
import { Dashboard } from './routes/dashboard';
import { RenderService } from './routes/render-service';
import { Legals, Salutations, Integrations } from './routes/settings';
import { Styles } from './routes/styles';
import { Templates } from './routes/templates';

export default function () {
  this.urlPrefix = ENV.APP.apiBaseHost;
  /* Allow any organization-id */
  this.namespace = '**';

  Dashboard(this);

  GenderChart(this);
  ClientChart(this);
  MobileChart(this);
  KeyIndicators(this);
  Conversions(this);
  LinkActivities(this);
  MarketingInsights(this);

  CampaignsDetail(this);
  CampaignsList(this);

  AddressBooks(this);
  AddressBookSubscribers(this);

  Subscribers(this);
  Groups(this);

  Interests(this);
  Countries(this);

  Legals(this);
  Salutations(this);

  RenderService(this);
  Styles(this);
  Templates(this);

  Filters(this);
  Integrations(this);
}
