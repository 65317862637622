import { SALUTATION_PLACEHOLDER } from 'additive-newsletter/utils/constants';

/**
 *  @module Validators
 */

/**
 * @function validateSalutationPlaceholders
 *
 * @param {Any} value
 * @param {Object} options
 * @param {String} options.message Error message
 *
 * @return {Boolean|ValidationErr}
 */
export function validateSalutationPlaceholders(options = {}) {
  const message = (options && options.message) || false;

  const validPlaceholders = Object.keys(SALUTATION_PLACEHOLDER);

  return (key, value) => {
    let isValid = true;

    const placeholders = value.match(/{#[a-zA-Z]*#}/g) || [];
    placeholders.forEach((placeholder) => {
      if (validPlaceholders.indexOf(placeholder.toUpperCase()) === -1) {
        isValid = false;
      }
    });

    const openingBracketsCount = (value.match(/{#/g) || []).length;
    const closingBracketsCount = (value.match(/#}/g) || []).length;

    /**
     * check if there are incomplete placeholders
     * or other variations ignore by the regex above
     */
    if (
      openingBracketsCount !== closingBracketsCount ||
      openingBracketsCount !== placeholders.length
    ) {
      isValid = false;
    }

    return isValid || message;
  };
}
