const BLOCKS = [
  'header',
  'footer',
  'title',
  'text',
  'button',
  'menu',
  'cta',
  'social_media',
  'image',
  'image_gallery',
  'video',
  'image_text',
  'two_columns',
  'teaser',
  'signature',
  'html',
  'divider'
];

export const Styles = (server) => {
  server.get(`/styles`, (schema) => {
    return schema.styles.all();
  });
  server.get(`/styles/:id`, (schema, { params: { id } }) => {
    return schema.styles.find(id);
  });
  server.put('/styles/:id', (schema, request) => {
    const requestBody = JSON.parse(request.requestBody);
    const style = schema.styles.find(request.params.id);
    style.update(requestBody);
    return style;
  });
  server.post('/styles', (schema, request) => {
    const requestBody = JSON.parse(request.requestBody);
    const newStyle = schema.styles.create({ ...requestBody });
    return newStyle;
  });
  server.delete('/styles/:id', (schema, { params: { id } }) => {
    const style = schema.styles.find(id);
    return style.destroy();
  });
  server.put('/styles/:id/duplicate', (schema, request) => {
    const style = schema.styles.find(request.params.id);
    const requestBody = JSON.parse(request.requestBody);
    const styleBody = style.toJSON();
    delete styleBody.id;
    const newStyle = schema.styles.create({ ...styleBody, ...requestBody });
    return newStyle;
  });
  server.get('/styles/:id/blocks', () => {
    let blocks = [];
    BLOCKS.forEach((block, idx) => {
      const variants = [];
      for (let i = 0; i < 3; i++) {
        variants.push({
          id: idx * 3 + i,
          blockName: block,
          name: `${i} ${block}`,
          blockIdentifier: block,
          variationIdentifier: `${block}-${i}`,
          variationPosition: i,
          visible: true,
          content:
            i === 0
              ? [
                  {
                    id: 'contents',
                    properties: [
                      {
                        id: 'title',
                        name: 'Titel',
                        type: 'string',
                        value: 'Platzhalter Titel'
                      },
                      {
                        id: 'text',
                        name: 'Text',
                        type: 'string',
                        value: ''
                      },
                      {
                        id: 'link',
                        name: 'Link',
                        type: 'link',
                        value: {
                          text: 'link',
                          url: 'additive.eu'
                        }
                      }
                    ]
                  }
                ]
              : [
                  {
                    id: 'image',
                    name: 'Image',
                    properties: [
                      {
                        id: 'image',
                        name: 'Image',
                        description: 'Info text',
                        value: {
                          url: 'https://source.unsplash.com/random'
                        },
                        type: 'image'
                      },
                      {
                        id: 'logo',
                        name: 'Logo',
                        description: 'Info text',
                        value: null,
                        type: 'image',
                        sync: true,
                        syncedValue: {
                          url: 'https://source.unsplash.com/random'
                        }
                      }
                    ]
                  }
                ],
          style: [
            {
              id: 'padding',
              name: 'Padding',
              properties: [
                {
                  id: 'padding_top',
                  name: 'Padding Top',
                  description: 'Info text',
                  value: '2',
                  type: 'number',
                  sync: false,
                  syncedValue: '5'
                },
                {
                  id: 'alignment',
                  name: 'Alignment',
                  description: 'Info text',
                  value: 'left',
                  allowedValues: ['left', 'center', 'right'],
                  type: 'enum'
                },
                {
                  id: 'alignment',
                  name: 'Alignment',
                  description: 'Info text',
                  value: 'left',
                  allowedValues: ['left', 'center', 'right'],
                  type: 'enum'
                },
                {
                  id: 'boolean',
                  name: 'boolean',
                  description: 'Info text',
                  value: false,
                  type: 'boolean'
                }
              ]
            },
            {
              id: 'typo',
              name: 'Typography',
              properties: [
                {
                  id: 'typography',
                  name: 'Überschrift',
                  description: 'Info text',
                  value: {
                    fontFamily: 'primary.light',
                    fontSize: '10px',
                    lineHeight: '100px',
                    color: 'main',
                    serif: true
                  },
                  type: 'typography',
                  sync: true,
                  syncedValue: 'h1'
                },
                {
                  id: 'color',
                  name: 'Color',
                  description: 'Info text',
                  value: {
                    color: '#fff',
                    contrastColor: '#000'
                  },
                  type: 'color',
                  sync: false,
                  syncedValue: 'main'
                }
              ]
            },
            {
              id: 'button',
              name: 'Bottone',
              properties: [
                {
                  id: 'button',
                  name: 'Button',
                  description: 'Info text',
                  value: 'button-1',
                  type: 'button',
                  sync: false,
                  syncedValue: 'button-2'
                }
              ]
            },
            {
              id: 'background',
              name: 'Hintergrund',
              properties: [
                {
                  id: 'backgroundColor',
                  name: 'Hintergrundfarbe',
                  type: 'color',
                  value: ['red', 'green', 'yellow', 'blue', 'violet'][
                    Math.floor(Math.random() * 5)
                  ],
                  sync: false,
                  syncedValue: 'ambient'
                }
              ]
            }
          ]
        });
      }
      blocks = blocks.concat(variants);
    });

    return { styleBlocks: blocks };
  });
};
