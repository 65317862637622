import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task, all, timeout } from 'ember-concurrency';

import { ACCEPT_HEADER } from 'additive-newsletter/utils/constants';

export default class InstanceAddressBooksAddressBookGroupsGroupSubscribersSubscriberController extends Controller {
  @service authenticatedFetch;
  @service currentUser;
  @service intl;
  @service router;
  @service store;
  @service uiDialog;
  @service uiFilter;
  @service uiPaths;
  @service uiToast;

  @tracked
  addressBookSubscriber = null;

  addressBooks = null;

  model = null;

  @tracked
  isError = false;

  @tracked
  isInfoSidebarOpen = false;

  get _isViewer() {
    return this.currentUser.isViewer;
  }

  /**
   * fetches the subscriber
   *
   * @function fetchSubscriber
   * @type {Task}
   */
  @task(function* () {
    try {
      const { addressBookId, groupId, subscriberId } = this.model;

      let subscriber = this.store.peekRecord('address-book-subscriber', subscriberId);

      // fetch subscriber only if record is not in store
      if (!subscriber) {
        subscriber = this.store.findRecord('address-book-subscriber', subscriberId, {
          adapterOptions: {
            addressBookId,
            groupId
          }
        });
      }
      // let task run for at least 450ms
      const [fetchedSubscriber] = yield all([subscriber, timeout(450)]);
      this.addressBookSubscriber = fetchedSubscriber;
    } catch (e) {
      this.isError = true;
    }
  })
  fetchSubscriber;

  /**
   * remove the given addressbook subscriber from the group
   *
   * @function removeFromGroup
   * @type {Task}
   */
  @task(function* () {
    try {
      const { addressBookId, groupId, subscriberId } = this.model;

      const baseUrl = this.uiPaths.pathsByRouteName('instance').api().url;
      const response = yield this.authenticatedFetch.fetch(
        `${baseUrl}/addressbooks/${addressBookId}/groups/${groupId}/subscribers/${subscriberId}`,
        { headers: ACCEPT_HEADER, method: 'DELETE' }
      );

      if (!response.ok) {
        throw new Error();
      }

      this.uiFilter.reset('group-subscribers');
      this.uiToast.showToast({
        type: 'success',
        title: this.intl.t('global.toast.success.savedChanges')
      });

      if (
        this.router.isActive(
          'instance.address-books.address-book.groups.group.subscribers.subscriber',
          addressBookId,
          groupId,
          subscriberId
        )
      ) {
        this.router.transitionTo(
          'instance.address-books.address-book.groups.group.subscribers',
          addressBookId,
          groupId
        );
      }
    } catch (e) {
      this.uiToast.showToast({
        type: 'error',
        title: this.intl.t('global.toast.error.savedChanges')
      });
    }
  })
  removeFromGroup;

  /**
   * unsubscribes the given addressbook subscriber
   *
   * @function unsubscribe
   * @type {Task}
   */
  @task(function* () {
    try {
      const { addressBookId, subscriberId } = this.model;

      const baseUrl = this.uiPaths.pathsByRouteName('instance').api().url;
      const response = yield this.authenticatedFetch.fetch(
        `${baseUrl}/addressbooks/${addressBookId}/subscribers/${subscriberId}/unsubscribe`,
        { headers: ACCEPT_HEADER, method: 'PUT' }
      );

      // update subscriber in the list
      const payload = yield response.json();
      this.store.pushPayload(payload);

      this.uiToast.showToast({
        type: 'success',
        title: this.intl.t('global.toast.success.savedChanges')
      });
    } catch (e) {
      this.uiToast.showToast({
        type: 'error',
        title: this.intl.t('global.toast.error.savedChanges')
      });
    }
  })
  unsubscribe;

  @action
  back() {
    this.router.transitionTo(
      'instance.address-books.address-book.groups.group',
      this.model.groupId
    );
  }

  @action
  openEdit() {
    this.router.transitionTo(
      'instance.address-books.address-book.groups.group.subscribers.subscriber.edit'
    );
  }

  @action
  onRemoveFromGroup() {
    const { intl } = this;
    this.uiDialog.showConfirm(
      intl.t('addressBooks.dialogs.removeFromGroup.title'),
      intl.t('addressBooks.dialogs.removeFromGroup.description'),
      () => this.removeFromGroup.perform(),
      intl.t('global.actions.remove'),
      true
    );
  }

  @action
  showUnsubscribeDialog() {
    const { intl } = this;
    this.uiDialog.showConfirm(
      intl.t('addressBooks.dialogs.unsubscribe.title'),
      intl.t('addressBooks.dialogs.unsubscribe.description'),
      () => this.unsubscribe.perform(),
      intl.t('global.actions.unsubscribe'),
      true
    );
  }
}
