import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class InstanceReportsReportRoute extends Route {
  @service store;

  model(params) {
    return params.report_id;
  }

  setupController(controller, model) {
    controller.reportId = model;
    controller.fetchReport.perform(model);
  }

  resetController(controller) {
    controller.report = null;
  }
}
