import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class InstanceCampaignsIndexCampaignRoute extends Route {
  @service router;
  @service store;

  renderTemplate() {
    this.render('instance/campaigns/index/campaign', {
      into: 'instance/campaigns',
      outlet: 'detail'
    });
  }

  model(params) {
    if (this.store.hasRecordForId('campaign', params.campaign_id)) {
      return this.store.peekRecord('campaign', params.campaign_id);
    }

    return this.store.findRecord('campaign', params.campaign_id);
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    controller.setup();
  }

  resetController(controller) {
    super.resetController(...arguments);

    controller.tearDown();
  }

  @action
  error() {
    this.router.transitionTo('instance.campaigns.index');
  }
}
