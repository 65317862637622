import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { bind, scheduleOnce } from '@ember/runloop';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class InstanceStylesWidgetsController extends Controller {
  @service intl;
  @service router;
  @service uiDialog;
  @service uiState;
  @service uiToast;
  @service currentUser;

  /**
   * the widget to delete
   *
   * @argument deleteWidget
   * @type {Object}
   * @default null
   */
  @tracked
  deleteWidget = null;

  /**
   * the widget to edit
   *
   * @argument editWidget
   * @type {Object}
   * @default null
   */
  @tracked
  editWidget = null;

  /**
   * whether the delete dialog is open
   *
   * @argument isDeleteDialog
   * @type {Boolean}
   * @default false
   */
  @tracked
  isDeleteDialog = false;

  /**
   * whether the edit dialog is open
   *
   * @argument isEditDialog
   * @type {Boolean}
   * @default false
   */
  @tracked
  isEditDialog = false;

  /**
   * whether a mobile device is used
   *
   * @argument isMobile
   * @type {Boolean}
   * @default false
   */
  @tracked
  isMobile = false;

  get isViewer() {
    return this.currentUser.isViewer;
  }

  constructor() {
    super(...arguments);

    this.resizeListener = bind(this, this._checkWindow);
    // TODO: Listener cleanup when implementing mobile view
    window.addEventListener('resize', this.resizeListener, false);

    scheduleOnce('afterRender', this, this.resizeListener);
  }

  /**
   * Checks the current windowsize programmatically and sets the `isMobile`-prop
   * to true if the current window size is lower than `600px`.
   *
   * @function _checkWindow
   * @private
   * @return {void}
   */
  _checkWindow() {
    this.isMobile = !window.matchMedia('(min-width: 600px)').matches;
  }

  /**
   * Opens the widget in configuration-mode
   */
  @action
  openConfigurator(widget) {
    if (this.isMobile) {
      this.uiDialog.showError(
        this.intl.t('errors.desktopOnly.title'),
        this.intl.t('errors.desktopOnly.widget')
      );
    } else {
      this.router.transitionTo('instance.styles.widgets.widget', widget);
    }
  }
}
