import ENV from 'additive-newsletter/config/environment';

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';

import { decodeSortString } from '@additive-apps/ui/utils/sort-util';

export default Route.extend({
  currentUser: service(),
  intl: service(),
  uiDialog: service(),
  uiFilter: service(),
  store: service(),

  organizationSlug: alias('currentUser.currentOrganization.id'),

  _currentAddressBookId: null,
  _currentGroupId: null,

  renderTemplate() {
    this.render('instance/address-books/address-book/groups/group', {
      into: 'instance'
    });
  },

  afterModel(model) {
    this._super(...arguments);

    // Ggroup has changed, reset filter to re-fetch
    if (!this._currentGroupId || this._currentGroupId !== model.groupId) {
      try {
        this.uiFilter.reset('group-subscribers');
      } catch (error) {
        // filter might not exist yet, if so do nothing
      }
    }

    this._currentAddressBookId = model.addressBook && model.addressBook.id;
    this._currentGroupId = model.groupId;
  },

  model(params) {
    const addressBook = this.modelFor('instance.address-books.address-book');

    return {
      addressBook,
      groupId: params.group_id
    };
  },

  setupController(controller) {
    this._super(...arguments);

    controller.fetchGroup.perform();
    controller.fetchSettings.perform();

    const baseUrl = ENV.APP.apiBaseHost;
    const filtersUrl = `${baseUrl}/${this.organizationSlug}/subscribers/filters`;

    this.uiFilter.register('group-subscribers', 'address-book-subscriber', {
      dynamicFilters: {
        url: filtersUrl
      },
      sort: '-subscribedAt',
      request: (queryParams) => {
        return this.store.query(
          'address-book-subscriber',
          Object.assign({}, queryParams, {
            'addressBook.id': this._currentAddressBookId,
            'group.id': this._currentGroupId
          })
        );
      }
    });
    controller.filterKey = 'group-subscribers';

    this.uiFilter.registerCallback('group-subscribers', 'onChange', () => {
      if (
        !controller ||
        !controller.selection ||
        typeof controller.selection.clearSelection !== 'function'
      ) {
        return;
      }

      controller.selection.clearSelection();
    });

    const { filter } = this.uiFilter.getQPFilter('group-subscribers');
    const value = filter.getSortValue() || '-subscribedAt';

    controller.sortState = decodeSortString(value);
  },

  resetController(controller) {
    this._super(...arguments);

    this.uiFilter.unregister('group-subscribers');
    controller.filterKey = null;
    if (
      controller &&
      controller.selection &&
      typeof controller.selection.clearSelection === 'function'
    ) {
      controller.selection.clearSelection();
    }
  },

  actions: {
    willTransition(transition) {
      if (this.controller.isUploadingFile) {
        transition.abort();
        this.uiDialog.showDiscardChangesConfirm(
          () => {
            this.controller.fileUploadAbortController?.abort();
            this.controller.isUploadingFile = false;
            transition.retry();
          },
          this.intl.t('addressBooks.dialogs.abortUpload.title'),
          this.intl.t('addressBooks.dialogs.abortUpload.description'),
          this.intl.t('addressBooks.dialogs.abortUpload.continue'),
          this.intl.t('addressBooks.dialogs.abortUpload.cancel')
        );
        return;
      }
      return true;
    }
  }
});
