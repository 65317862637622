import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<UiModal\n  @title={{t \"addressBooks.addressBook.createDialog\"}}\n  @modalClassNames=\"nl-create-dialog pb2\"\n  @onClose={{action this.onClose}}\n  as |m|\n>\n  <m.header />\n  <m.content @classNames=\"pb0\" as |c|>\n    <c.body>\n      <UiItemGroup @class=\"w-100 pv0\">\n        {{yield\n          (hash\n            item=(component \"ui-item\" class=\"mv05 font-sm bg-white pa2\")\n            goTo=(action \"goTo\")\n            delimiter=(component \"ui-blank-template\" tagName=\"div\" classNames=\"bb b--black-10 mv2\")\n          )\n        }}\n      </UiItemGroup>\n    </c.body>\n  </m.content>\n</UiModal>", {"contents":"<UiModal\n  @title={{t \"addressBooks.addressBook.createDialog\"}}\n  @modalClassNames=\"nl-create-dialog pb2\"\n  @onClose={{action this.onClose}}\n  as |m|\n>\n  <m.header />\n  <m.content @classNames=\"pb0\" as |c|>\n    <c.body>\n      <UiItemGroup @class=\"w-100 pv0\">\n        {{yield\n          (hash\n            item=(component \"ui-item\" class=\"mv05 font-sm bg-white pa2\")\n            goTo=(action \"goTo\")\n            delimiter=(component \"ui-blank-template\" tagName=\"div\" classNames=\"bb b--black-10 mv2\")\n          )\n        }}\n      </UiItemGroup>\n    </c.body>\n  </m.content>\n</UiModal>","moduleName":"additive-newsletter/components/anl-create-dialog.hbs","parseOptions":{"srcName":"additive-newsletter/components/anl-create-dialog.hbs"}});
import Component from '@ember/component';
import { inject as service } from '@ember/service';

export default Component.extend({
  router: service(),
  tagName: '',

  onClose() {},

  actions: {
    goTo(path) {
      this.router.transitionTo(path);
      this.onClose();
    }
  }
});
