import Model, { attr } from '@ember-data/model';

import { equal } from '@ember/object/computed';

export default Model.extend({
  name: attr('string'),
  type: attr('string'),
  subscriberCount: attr('number'),

  hasNoSubscribers: equal('subscriberCount', 0)
});
