import Model, { attr, belongsTo } from '@ember-data/model';

export default class TemplateBlockModel extends Model {
  @attr('string') blockName;
  @attr('string') name;
  @attr('string') blockIdentifier;
  @attr('string') variationIdentifier;
  @attr() style;
  @attr() content;
  @belongsTo('style-block') styleBlockId;
}
