import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class InstanceTemplatesController extends Controller {
  @service currentUser;

  @tracked
  isCreateDialog = false;

  get _isViewer() {
    return this.currentUser.isViewer;
  }
}
