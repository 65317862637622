import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<UiModal\n  @modalClassNames=\"nl-email-detail-dialog h-auto\"\n  @size=\"lg\"\n  @onClose={{@onClose}}\n  as |modal|\n>\n  <modal.header as |header|>\n    <header.action\n      @class=\"mr1\"\n      @icon=\"reload\"\n      @label={{t \"routes.campaigns.sendCampaign.emailPreview.reprocess\"}}\n      @onClick={{@onClickReprocess}}\n    />\n  </modal.header>\n  <modal.content @class=\"pb3\" as |content|>\n    <content.body>\n      {{#if (eq @preview.status \"Complete\")}}\n        <img src={{@preview.screenshot}} alt=\"The preview of the email.\" />\n      {{else}}\n        <UiSkeleton @items={{1}} @cardHeight={{800}} @gap={{0}} />\n      {{/if}}\n    </content.body>\n  </modal.content>\n</UiModal>", {"contents":"<UiModal\n  @modalClassNames=\"nl-email-detail-dialog h-auto\"\n  @size=\"lg\"\n  @onClose={{@onClose}}\n  as |modal|\n>\n  <modal.header as |header|>\n    <header.action\n      @class=\"mr1\"\n      @icon=\"reload\"\n      @label={{t \"routes.campaigns.sendCampaign.emailPreview.reprocess\"}}\n      @onClick={{@onClickReprocess}}\n    />\n  </modal.header>\n  <modal.content @class=\"pb3\" as |content|>\n    <content.body>\n      {{#if (eq @preview.status \"Complete\")}}\n        <img src={{@preview.screenshot}} alt=\"The preview of the email.\" />\n      {{else}}\n        <UiSkeleton @items={{1}} @cardHeight={{800}} @gap={{0}} />\n      {{/if}}\n    </content.body>\n  </modal.content>\n</UiModal>","moduleName":"additive-newsletter/components/anl-campaigns/email-preview-detail-dialog/email-preview-detail-dialog.hbs","parseOptions":{"srcName":"additive-newsletter/components/anl-campaigns/email-preview-detail-dialog/email-preview-detail-dialog.hbs"}});
import Component from '@glimmer/component';
import { arg } from 'ember-arg-types';
import { object, func } from 'prop-types';

export default class EmailPreviewDetailDialog extends Component {
  /**
   * the function to be executed when the dialog is closed.
   *
   * @argument onClose
   * @type {Function}
   */
  @arg(func)
  onClose = () => {};

  /**
   * the function to be executed when the reprocess button is clicked
   *
   * @argument onClickReprocess
   * @type {Function}
   */
  @arg(func)
  onClickReprocess = () => {};

  /**
   * the email-preview to be shown
   *
   * @argument preview
   * @type {Object}
   * @default null
   */
  @arg(object)
  preview = null;
}
