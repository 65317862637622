import { validateFormat, validatePresence } from 'ember-changeset-validations/validators';

export const layoutValidation = (intl) => {
  const message = intl.t('errors.color');
  const presenceMessage = intl.t('errors.required');
  const regex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
  return {
    'styles.mainBackgroundColor': [
      validateFormat({
        regex,
        message,
        allowBlank: true
      }),
      validatePresence({ presence: true, message: presenceMessage })
    ],
    'styles.newsletterBackgroundColor': [
      validateFormat({
        regex,
        message,
        allowBlank: true
      }),
      validatePresence({ presence: true, message: presenceMessage })
    ],
    'styles.titleColor': [
      validateFormat({
        regex,
        message,
        allowBlank: true
      }),
      validatePresence({ presence: true, message: presenceMessage })
    ],
    'styles.subtitleColor': [
      validateFormat({
        regex,
        message,
        allowBlank: true
      }),
      validatePresence({ presence: true, message: presenceMessage })
    ],
    'styles.textColor': [
      validateFormat({
        regex,
        message,
        allowBlank: true
      }),
      validatePresence({ presence: true, message: presenceMessage })
    ],
    'styles.buttonBackgroundColor': [
      validateFormat({
        regex,
        message,
        allowBlank: true
      }),
      validatePresence({ presence: true, message: presenceMessage })
    ],
    'styles.buttonColor': [
      validateFormat({
        regex,
        message,
        allowBlank: true
      }),
      validatePresence({ presence: true, message: presenceMessage })
    ],
    'styles.linkColor': [
      validateFormat({
        regex,
        message,
        allowBlank: true
      }),
      validatePresence({ presence: true, message: presenceMessage })
    ],
    'styles.metaColor': [
      validateFormat({
        regex,
        message,
        allowBlank: true
      }),
      validatePresence({ presence: true, message: presenceMessage })
    ],
    'styles.placeholderColor': [
      validateFormat({
        regex,
        message,
        allowBlank: true
      }),
      validatePresence({ presence: true, message: presenceMessage })
    ]
  };
};
