import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class InstanceAddressBooksAddressBookSubscribersNewController extends Controller {
  @service router;
  @service uiFilter;

  /**
   * the model containing the address book
   *
   * @argument model
   * @type {Model}
   * @default null
   */
  model = null;

  @action
  onClose() {
    this.router.transitionTo('instance.address-books.address-book.subscribers');
  }

  @action
  onSubmit() {
    this.uiFilter.reset('address-book-subscribers');
  }
}
