import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class InstanceCampaignsSentCampaignRoute extends Route {
  @service router;
  @service store;

  renderTemplate() {
    this.render('instance/campaigns/index/campaign', {
      into: 'instance/campaigns',
      outlet: 'detail'
    });
  }

  model(params) {
    if (this.store.hasRecordForId('campaign', params.campaign_id)) {
      return this.store.peekRecord('campaign', params.campaign_id);
    }

    return this.store.findRecord('campaign', params.campaign_id);
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    this.controllerFor('instance.campaigns.index.campaign').setup(model);
  }

  resetController() {
    super.resetController(...arguments);

    this.controllerFor('instance.campaigns.index.campaign').tearDown();
  }

  @action
  error() {
    this.router.transitionTo('instance.campaigns.sent');
  }
}
