export const RenderService = (server) => {
  server.post(
    'https://render.newsletter.additive-apps.tech/blocks',
    (schema, request) => {
      const response = {
        layout: `<html><head><style>p {font-size: 24px;}</style></head><body><div style="background:black; padding:54px;">{{CONTENT_BLOCKS}}</div></body></html>`,
        blocks: []
      };

      const requestBody = JSON.parse(request.requestBody);
      requestBody.blocks.forEach((block) => {
        response.blocks.push(
          `<div style="background:${
            block.style.backgroundColor
          };height:200px;width:600px;padding:48px;margin:0 auto;box-sizing:border-box;">
            <p>${block.content?.title || ''}</p>
          </div>`
        );
      });

      return response;
    },
    { timing: 100 }
  );
};
