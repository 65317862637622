import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import FullWidthMixin from '@additive-apps/ui/mixins/ui-full-width-view';

export default class InstanceStylesIndexStyleRoute extends Route.extend(FullWidthMixin) {
  @service store;

  renderTemplate() {
    this.render('instance/styles/index/style', {
      into: 'application'
    });
  }

  model(params) {
    // unload all style blocks as they may be loaded for multiple styles
    this.store.unloadAll('style-block');

    return this.store.findRecord('style', params.style_id);
  }

  resetController(controller) {
    this.store.unloadAll('style-block');

    controller.templateBlocks = [];
    controller.styleBlocks = [];
    controller.blockGroups = [];
  }
}
