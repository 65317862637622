import ApplicationSerializer from './application';

export default ApplicationSerializer.extend({
  isNewSerializerAPI: true,
  attrs: {
    createdAt: { serialize: false },
    subscriberCount: { serialize: false },
    openRate: { serialize: false },
    clickRate: { serialize: false }
  }
});
