import ApplicationAdapter from './application';

export default class TemplateBlockAdapter extends ApplicationAdapter {
  urlForMultiple(_, snapshot) {
    let url = this.buildURL();
    if (snapshot?.adapterOptions?.template) {
      const template = snapshot.adapterOptions.template;
      url += `/templates/${template}/blocks`;
    }

    return url;
  }

  urlForSingle(id, modelName, snapshot) {
    return `${this.urlForMultiple(modelName, snapshot)}/${id}`;
  }

  urlForFindRecord() {
    return this.urlForSingle(...arguments);
  }

  urlForFindAll() {
    return this.urlForMultiple(...arguments);
  }

  urlForCreateRecord() {
    return this.urlForMultiple(...arguments);
  }

  urlForUpdateRecord() {
    return this.urlForSingle(...arguments);
  }

  urlForDeleteRecord() {
    return this.urlForSingle(...arguments);
  }
}
