import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<UiNavigationDrawer @navDrawerStateKey=\"widget-detail\" @isRight={{true}} @isPermanent={{false}}>\n  <UiToolbar @class=\"no-shadow\" @isSecondary={{true}}>\n    <UiActionButton\n      @icon=\"close\"\n      @label={{t \"global.actions.close\"}}\n      @onClick={{action this.onClose}}\n    />\n  </UiToolbar>\n\n  <div class=\"flex flex-column pa2 pa3-sm\">\n    <UiLangSelect\n      @selected={{this.selectedLanguage}}\n      @languages={{this.languages}}\n      @publishedLanguages={{this.languages}}\n      @availableLanguages={{this.languages}}\n      @defaultLanguage={{this.defaultLanguage}}\n      @hideLanguageActions={{true}}\n      @hideState={{true}}\n      @onSelect={{action (mut this.selectedLanguage)}}\n    />\n\n    <UiInput @textarea={{true}} @value={{this._code}} @rows={{10}} @isReadOnly={{true}} />\n  </div>\n</UiNavigationDrawer>", {"contents":"<UiNavigationDrawer @navDrawerStateKey=\"widget-detail\" @isRight={{true}} @isPermanent={{false}}>\n  <UiToolbar @class=\"no-shadow\" @isSecondary={{true}}>\n    <UiActionButton\n      @icon=\"close\"\n      @label={{t \"global.actions.close\"}}\n      @onClick={{action this.onClose}}\n    />\n  </UiToolbar>\n\n  <div class=\"flex flex-column pa2 pa3-sm\">\n    <UiLangSelect\n      @selected={{this.selectedLanguage}}\n      @languages={{this.languages}}\n      @publishedLanguages={{this.languages}}\n      @availableLanguages={{this.languages}}\n      @defaultLanguage={{this.defaultLanguage}}\n      @hideLanguageActions={{true}}\n      @hideState={{true}}\n      @onSelect={{action (mut this.selectedLanguage)}}\n    />\n\n    <UiInput @textarea={{true}} @value={{this._code}} @rows={{10}} @isReadOnly={{true}} />\n  </div>\n</UiNavigationDrawer>","moduleName":"additive-newsletter/components/anl-widget-configuration/detail-navbar.hbs","parseOptions":{"srcName":"additive-newsletter/components/anl-widget-configuration/detail-navbar.hbs"}});
import ENV from 'additive-newsletter/config/environment';

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';
import { arg } from 'ember-arg-types';
import { func, object } from 'prop-types';

/**
 * @class AnlWidgetConfiguratorDetailNavbar
 * @module anl-widget-configuration
 */
export default class AnlWidgetConfiguratorDetailNavbar extends Component {
  @service currentUser;
  @service uiAppSettings;

  @alias('currentUser.currentOrganization.id') organizationSlug;
  @alias('uiAppSettings.languages.defaultLanguage') defaultLanguage;
  @alias('uiAppSettings.languages.contentLanguages') languages;

  @arg(object)
  widget = null;

  @tracked selectedLanguage = 'de';

  get _code() {
    return `<div id="${this.widget.selector}"></div><script async src="${ENV.APP.publicHost}/${this.organizationSlug}/widgets/${this.widget.id}/${this.selectedLanguage}"></script>`;
  }

  @arg(func)
  onClose() {}
}
