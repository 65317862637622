import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.showList}}\n  <AnlCampaignList::List\n    @items={{this.campaigns}}\n    @title={{this.title}}\n    @isLoading={{this.isLoading}}\n    @isError={{this.isError}}\n    @reload={{this.onReload}}\n    @placeholderCount={{5}}\n    @detailRoute=\"instance.campaigns.index.campaign\"\n    ...attributes\n  />\n{{/if}}", {"contents":"{{#if this.showList}}\n  <AnlCampaignList::List\n    @items={{this.campaigns}}\n    @title={{this.title}}\n    @isLoading={{this.isLoading}}\n    @isError={{this.isError}}\n    @reload={{this.onReload}}\n    @placeholderCount={{5}}\n    @detailRoute=\"instance.campaigns.index.campaign\"\n    ...attributes\n  />\n{{/if}}","moduleName":"additive-newsletter/components/anl-campaign-list.hbs","parseOptions":{"srcName":"additive-newsletter/components/anl-campaign-list.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { or } from '@ember/object/computed';
import { arg } from 'ember-arg-types';
import { array, bool, func, string, object } from 'prop-types';

export default class AnlCampaignList extends Component {
  /**
   * the array of campaigns
   *
   * @argument campaigns
   * @type {Array}
   * @default null
   */
  @arg(array | object)
  @tracked
  campaigns = null;

  /**
   * whether an error occured while fetching
   *
   * @argument isError
   * @type {Boolean}
   * @default false
   */
  @arg(bool)
  @tracked
  isError = false;

  /**
   * whether the campaigns are being fetched
   *
   * @argument isLoading
   * @type {Boolean}
   * @default false
   */
  @arg(bool)
  @tracked
  isLoading = false;

  /**
   * the state of the items
   *
   * @argument itemState
   * @type {String}
   * @default null
   */
  @arg(string)
  @tracked
  itemState = null;

  /**
   * the title of the list
   *
   * @argument title
   * @type {String}
   * @default null
   */
  @arg(string)
  @tracked
  title = null;

  /**
   * the reload function
   *
   * @argument onReload
   * @type {Function}
   */
  @arg(func)
  @tracked
  onReload = () => {};

  /**
   * whether the list should be shown
   *
   * @computed showList
   * @type {Boolean}
   */
  @or('campaigns.length', 'isError') showList;
}
