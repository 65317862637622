/**
 * server is the default scenario for normal app-use (development, staging)
 */
export default function (server) {
  server.passthrough('https://staging.account.additive-apps.tech/**');
  server.passthrough('https://api.staging.multimedia.additive-apps.tech/**');
  server.passthrough('https://helper.additive-apps.tech/**');
  server.passthrough('https://staging.journal.additive-apps.tech/**');
  server.passthrough('https://staging.content.additive-apps.tech/**');
  server.passthrough('https://cdn-apps-testing.s3.amazonaws.com/**');

  server.passthrough(`${server.urlPrefix}/${server.namespace}/`);

  server.createList('campaign', 5, 'draft');
  server.createList('campaign', 5, 'failedNoSubscribers');
  server.createList('campaign', 5, 'failedNoValidEmail');
  server.createList('campaign', 5, 'scheduled');
  server.createList('campaign', 5, 'sent');
  server.createList('campaign', 5, 'resent');
  server.createList('campaign', 5, 'aborted');

  server.createList('email-preview', 5);

  const styles = server.createList('style', 5);
  const template = server.create('template', 1, { styleId: styles[0] });
  server.createList('template-block', 5, {
    template
  });

  const abs = server.create('address-book');
  const group = server.create('group', { addressBook: abs });
  const subscribers = server.createList('subscriber', 10, {
    addressBooks: [abs],
    groups: [group]
  });

  subscribers.forEach((subscriber) => {
    server.create(
      'address-book-subscriber',
      {
        addressBook: abs,
        subscriber
      },
      'isConfirmedForInfo'
    );
  });
}
