import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{yield (hash\n  isResolved=this._state.isResolved\n  isLoading=this._state.isLoading\n  relationship=this._state.relationship\n)}}", {"contents":"{{yield (hash\n  isResolved=this._state.isResolved\n  isLoading=this._state.isLoading\n  relationship=this._state.relationship\n)}}","moduleName":"additive-newsletter/components/anl-ed-relationship-resolver.hbs","parseOptions":{"srcName":"additive-newsletter/components/anl-ed-relationship-resolver.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { arg } from 'ember-arg-types';
import { object, string } from 'prop-types';
import { run } from '@ember/runloop';
import Model from '@ember-data/model';
import { inject as service } from '@ember/service';

/**
 * Wrapper which resolves ED async relationship
 * https://guides.emberjs.com/release/models/relationships/#toc_relationships-as-promises
 */
export default class AnlEdRelationshipResolverComponent extends Component {
  @service uiDialog;

  @arg(object)
  model = null;

  @arg(string)
  relationshipKey = '';

  @tracked
  _state = {
    isLoading: true,
    isResolved: false,
    relationship: null
  };

  constructor(owner, args) {
    super(owner, args);

    if (args.model instanceof Model && args.relationshipKey) {
      /* Async code must be wrapped in 'run' */
      run(() => this._resolve());
    }
  }

  async _resolve() {
    try {
      const model = await this.model.get(this.relationshipKey);

      this._state = {
        isLoading: false,
        isResolved: true,
        relationship: model
      };
    } catch (error) {
      this.uiDialog.showError();
      this._state = {
        isLoading: false,
        isResolved: false,
        relationship: null
      };
    }
  }
}
