import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<UiModal\n  @title={{t \"components.anlStyles.duplicateDialog.title\"}}\n  @size=\"md\"\n  @onClose={{this.close}}\n  as |modal|\n>\n  <modal.header />\n  <modal.content as |content|>\n    <content.body {{did-insert this.setup}}>\n      <UiInput\n        @label={{t \"components.anlStyles.duplicateDialog.name\"}}\n        @value={{this.changeset.name}}\n        @isTouched={{this.isTouched}}\n        @errorMessage={{get this.changeset.error.name \"validation\"}}\n      />\n    </content.body>\n  </modal.content>\n\n  <modal.footer>\n    <UiButton @label={{t \"global.actions.save\"}} @isSecondary={{true}} @onClick={{this.save}} />\n  </modal.footer>\n</UiModal>", {"contents":"<UiModal\n  @title={{t \"components.anlStyles.duplicateDialog.title\"}}\n  @size=\"md\"\n  @onClose={{this.close}}\n  as |modal|\n>\n  <modal.header />\n  <modal.content as |content|>\n    <content.body {{did-insert this.setup}}>\n      <UiInput\n        @label={{t \"components.anlStyles.duplicateDialog.name\"}}\n        @value={{this.changeset.name}}\n        @isTouched={{this.isTouched}}\n        @errorMessage={{get this.changeset.error.name \"validation\"}}\n      />\n    </content.body>\n  </modal.content>\n\n  <modal.footer>\n    <UiButton @label={{t \"global.actions.save\"}} @isSecondary={{true}} @onClick={{this.save}} />\n  </modal.footer>\n</UiModal>","moduleName":"additive-newsletter/components/anl-styles/duplicate-dialog.hbs","parseOptions":{"srcName":"additive-newsletter/components/anl-styles/duplicate-dialog.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { arg } from 'ember-arg-types';
import { func, object } from 'prop-types';

import Changeset from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import { styleValidation } from 'additive-newsletter/validations/style';

import { ACCEPT_HEADER } from 'additive-newsletter/utils/constants';

/**
 * Dialog that is shown when a user wants to duplicate a style
 *
 * @module anl-styles
 * @class AnlStylesDuplicateDialog
 */
export default class AnlStylesDuplicateDialog extends Component {
  @service authenticatedFetch;
  @service intl;
  @service store;
  @service uiDialog;
  @service uiToast;

  /**
   * the style resource
   *
   * @argument style
   * @type {Object}
   * @default null
   */
  @arg(object)
  @tracked
  style = null;

  /**
   * the changeset of the style
   *
   * @argument changeset
   * @type {Object}
   * @default null
   */
  @tracked
  changeset = null;

  /**
   * whether the form is touched
   *
   * @argument isTouched
   * @type {Boolean}
   * @default false
   */
  @tracked
  isTouched = false;

  @action
  async save() {
    try {
      this.isTouched = true;

      const adapter = this.store.adapterFor('style');
      const url = adapter.urlForFindRecord(this.style.id, 'style');
      const response = await this.authenticatedFetch.fetch(
        `${url}/duplicate`,
        {
          method: 'POST',
          body: JSON.stringify({ name: this.changeset.get('name') }),
          headers: ACCEPT_HEADER
        },
        null,
        [422, 403]
      );

      if (!response.ok) {
        throw response;
      }

      const body = await response.json();
      this.store.pushPayload('style', body);

      this.onClose();

      this.uiToast.showToast({
        type: 'success',
        title: this.intl.t('global.toast.success.savedChanges')
      });
    } catch (e) {
      if (e?.status === 403) {
        this.uiDialog.showError(
          this.intl.t('routes.styles.exceededError.title'),
          this.intl.t('routes.styles.exceededError.description')
        );
      } else if (e?.status === 422) {
        this.changeset.pushErrors('name', this.intl.t('errors.nameExists'));
      } else {
        this.uiToast.showToast({
          type: 'error',
          title: this.intl.t('global.toast.error.savedChanges'),
          duration: 2000
        });
      }
    }
  }

  /**
   * on close callback
   *
   * @function onClose
   */
  @arg(func)
  onClose = () => {};

  @action
  setup() {
    const validation = styleValidation(this.intl);
    this.changeset = new Changeset(this.style, lookupValidator(validation), validation);
  }

  @action
  close() {
    this.uiDialog.showDiscardChangesConfirm(() => {
      this.changeset.destroy();
      this.onClose();
    });
  }
}
