/**
 * Routes for the google analytics tabs
 * @module routes: instance/reports/report
 */

/**
 * @param {*} self
 */
export const KeyIndicators = (self) => {
  self.get('/reports/:id/google-analytics-key-indicators', () => {
    return {
      conversionsTotal: {
        type: 'compare',
        current: {
          value: 110,
          type: 'average'
        },
        compare: {
          value: 100,
          type: 'average'
        },
        change: 10
      },
      sessionsTotal: {
        type: 'compare',
        current: {
          value: 1000,
          type: 'average'
        },
        compare: {
          value: 500,
          type: 'average'
        },
        change: 100
      },
      pageviewsPerSession: {
        type: 'compare',
        current: {
          value: 4.9,
          type: 'average'
        },
        compare: {
          value: 3.5,
          type: 'average'
        },
        change: 40
      },
      bounceRate: {
        type: 'compare',
        current: {
          value: 4.9,
          type: 'average'
        },
        compare: {
          value: 3.5,
          type: 'average'
        },
        change: 40
      },
      durationPerSession: {
        type: 'compare',
        current: {
          value: 54,
          type: 'average'
        },
        compare: {
          value: 60,
          type: 'average'
        },
        change: -10
      }
    };
  });
};

/**
 * @param {*} self
 */
export const Conversions = (self) => {
  self.get('/reports/:id/google-analytics-conversions', () => {
    return {
      conversionsTotal: 76,
      conversions: [
        { name: 'Anfrage DE', value: '25' },
        { name: 'Buchung DE', value: '14' },
        { name: 'Buchungsanfrage deutsch', value: '12' },
        { name: 'Buchungsanfrage italienisch', value: '11' },
        { name: 'Anfrage IT', value: '9' },
        { name: 'Anfrage EN', value: '3' },
        { name: 'Buchung IT', value: '2' },
        { name: 'Buchung EN', value: '0' }
      ]
    };
  });
};
