import ApplicationSerializer from './application';

export default ApplicationSerializer.extend({
  isNewSerializerAPI: true,
  attrs: {
    deactivatedAt: { serialize: false },
    createdAt: { serialize: false },
    updatedAt: { serialize: false }
  },

  serialize(snapshot) {
    const hash = this._super(...arguments);

    const addressBookId = snapshot?.adapterOptions?.addressBookId;
    if (addressBookId) {
      hash.addressBook = addressBookId;
    }

    const groupId = snapshot?.adapterOptions?.groupId;

    if (groupId) {
      hash.group = groupId;
    }

    return hash;
  }
});
