import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class InstanceCampaignsIndexRoute extends Route {
  @service uiFilter;

  resetController(controller) {
    super.resetController(...arguments);
    this.uiFilter.unregister('templates');
    controller.tearDown();
  }

  setupController() {
    super.setupController(...arguments);
    this.uiFilter.register('templates', 'template', { paginated: false });
  }
}
