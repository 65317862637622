import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<UiModal @title={{this.title}} @size=\"md\" @onClose={{action \"onClose\"}} as |modal|>\n  <modal.header />\n  <modal.content as |content|>\n    <content.body>\n      {{#if this.isCreateDialog}}\n        <p class=\"font-md pb2\">\n          {{t \"addressBooks.dialogs.create.info\"}}\n        </p>\n      {{/if}}\n\n      <UiInput\n        @label={{t \"addressBooks.dialogs.name\"}}\n        @value={{this.changeset.name}}\n        @errorMessage={{get this.changeset.error.name \"validation\"}}\n      />\n    </content.body>\n  </modal.content>\n\n  <modal.footer>\n    <UiButton\n      @label={{t \"global.actions.save\"}}\n      @isSecondary={{true}}\n      @onClick={{perform this.save}}\n    />\n  </modal.footer>\n</UiModal>", {"contents":"<UiModal @title={{this.title}} @size=\"md\" @onClose={{action \"onClose\"}} as |modal|>\n  <modal.header />\n  <modal.content as |content|>\n    <content.body>\n      {{#if this.isCreateDialog}}\n        <p class=\"font-md pb2\">\n          {{t \"addressBooks.dialogs.create.info\"}}\n        </p>\n      {{/if}}\n\n      <UiInput\n        @label={{t \"addressBooks.dialogs.name\"}}\n        @value={{this.changeset.name}}\n        @errorMessage={{get this.changeset.error.name \"validation\"}}\n      />\n    </content.body>\n  </modal.content>\n\n  <modal.footer>\n    <UiButton\n      @label={{t \"global.actions.save\"}}\n      @isSecondary={{true}}\n      @onClick={{perform this.save}}\n    />\n  </modal.footer>\n</UiModal>","moduleName":"additive-newsletter/components/anl-address-book/detail-dialog.hbs","parseOptions":{"srcName":"additive-newsletter/components/anl-address-book/detail-dialog.hbs"}});
import Component from '@ember/component';
import { computed, set } from '@ember/object';
import { inject as service } from '@ember/service';

import { task } from 'ember-concurrency';
import Changeset from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import { addressBookValidation } from 'additive-newsletter/validations/address-book';
import { getAdapterError } from '@additive-apps/utils/utils/errors';
/**
 * Dialog that is shown when a user wants to either rename and exisiting or
 * create a new address-book
 *
 * @module anl-address-book
 * @class anl-address-book/detail-dialog
 */
export default Component.extend({
  intl: service(),
  store: service(),
  uiDialog: service(),
  uiFilter: service(),
  uiToast: service(),

  tagName: '',

  /**
   * the address-book model
   *
   * @argument addressBook
   * @type {DS.Model}
   */
  addressBook: null,

  /**
   * whether its a create dialog
   *
   * @argument isCreateDialog
   * @type {Boolean}
   */
  isCreateDialog: false,

  /**
   * the previous name of the address book for rollback
   *
   * @argument _previousName
   * @type {String}
   */
  _previousName: null,

  /**
   * the title of the dialog
   *
   * @computed title
   * @type {String}
   */
  title: computed('addressBook', {
    get() {
      return (
        this.addressBook &&
        this.intl.t(`addressBooks.dialogs.${this.isCreateDialog ? 'create' : 'rename'}.title`)
      );
    }
  }),

  didInsertElement() {
    this._super(...arguments);

    // create new model if no model is passed
    if (!this.addressBook) {
      const addressBook = this.store.createRecord('address-book', { name: '' });
      set(this, 'addressBook', addressBook);
    }

    const validation = addressBookValidation(this.intl);
    const changeset = new Changeset(this.addressBook, lookupValidator(validation), validation);
    set(this, 'changeset', changeset);
  },

  /**
   * saves the current model instance if the changeset isValid
   *
   * @function save
   * @type {Task}
   */
  save: task(function* () {
    const { changeset, addressBook } = this;

    try {
      yield changeset.validate();
      if (!changeset.get('isValid')) {
        throw new Error();
      }

      // dont send request if no changes were made
      if (changeset.get('isPristine')) {
        return this.onClose();
      }

      // check whether addressbook is new before saving
      const isNew = addressBook.isNew;

      yield changeset.save();
      this.uiToast.showToast({
        type: 'success',
        title: this.intl.t('global.toast.success.savedChanges')
      });

      if (isNew) {
        yield this.uiFilter.reset('address-books');
      }
      this.onClose();
    } catch (e) {
      const error = getAdapterError(e);
      if (error === 'An address book with this name already exists') {
        changeset.pushErrors('name', this.intl.t('errors.nameExists'));
      } else {
        this.uiToast.showToast({
          type: 'error',
          title: this.intl.t('global.toast.error.savedChanges')
        });
      }
    }
  }),

  init() {
    this._super(...arguments);

    const previousName = this.addressBook && this.addressBook.name;
    set(this, '_previousName', previousName);
  },

  /**
   * on close callback
   *
   * @function onClose
   */
  onClose() {},

  actions: {
    /**
     * destroys the changeset and deletes the record in case it is new
     *
     * @function onClose
     */
    onClose() {
      const { changeset, addressBook } = this;
      if (changeset.get('isDirty')) {
        this.uiDialog.showDiscardChangesConfirm(() => {
          changeset.destroy();

          if (addressBook.isNew) {
            addressBook.deleteRecord();
          } else {
            // reset name to previous one
            set(this.addressBook, 'name', this._previousName);
          }

          this.onClose();
        });
        return;
      }

      changeset.destroy();

      if (addressBook.isNew) {
        addressBook.deleteRecord();
      }
      this.onClose();
    }
  }
});
