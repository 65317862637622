/**
 * filters the apps without settings from a given object
 *
 * @function filterSettingsApps
 * @param {Object} apps
 * @return {Object} filteredApps
 */
export const filterSettingsApps = (apps) => {
  if (!apps || typeof apps !== 'object') {
    return {};
  }

  // remove apps without settings
  const filteredApps = Object.keys(apps)
    .filter((key) => ['content', 'journal', 'voucher'].includes(key))
    .reduce((object, key) => {
      object[key] = apps[key];
      return object;
    }, {});

  return filteredApps;
};
