import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class InstanceAddressBooksAddressBookGroupsGroupSubscribersSubscriberEditController extends Controller {
  @service currentUser;
  @service router;

  /**
   * the model containing the address book, the group and the subscriber
   *
   * @argument model
   * @type {Model}
   * @default null
   */
  model = null;

  @action
  onClose() {
    this.router.transitionTo(
      'instance.address-books.address-book.groups.group.subscribers.subscriber'
    );
  }
}
