import Model, { attr, belongsTo } from '@ember-data/model';
import { inject as service } from '@ember/service';
import { notEmpty, equal } from '@ember/object/computed';
import { computed } from '@ember/object';

export default Model.extend({
  intl: service(),

  subscriberId: attr('string'),
  subscribedAt: attr('date'),
  unsubscribedAt: attr('date'),
  unsubscribeSource: attr('string'),
  confirmed: attr('boolean', { defaultValue: false }),

  addressBook: belongsTo('address-book'),
  subscriber: belongsTo(),
  groupId: attr('string'),

  gender: attr('string', {
    defaultValue: null
  }),
  givenName: attr('string'),
  familyName: attr('string'),
  birthDate: attr('string'),
  language: attr('string'),
  addressCountry: attr('string'),
  email: attr('string'),
  note: attr('string'),
  companyName: attr('string'),
  source: attr('number', {
    defaultValue: 0
  }),
  salutation: attr('string'),
  salutationText: attr('string'),
  customSalutation: attr('string'),
  keywordIds: attr(),

  deactivatedAt: attr('date'),
  deactivationSource: attr('string'),
  inactiveSince: attr('date'),

  isDeactivated: computed.notEmpty('deactivatedAt'),

  isUnsubscribed: notEmpty('unsubscribedAt'),
  isSubscribed: equal('status', 'subscribed'),

  status: computed('isUnsubscribed', 'isDeactivated', {
    get() {
      if (this.isDeactivated) {
        return 'deactivated';
      }
      if (this.isUnsubscribed) {
        return 'unsubscribed';
      }

      return 'subscribed';
    }
  }).readOnly(),

  name: computed('givenName', 'familyName', {
    get() {
      if (this.givenName && this.familyName) {
        return `${this.givenName} ${this.familyName}`;
      }
      if (this.givenName) {
        return this.givenName;
      }
      if (this.familyName) {
        return this.familyName;
      }

      return '-';
    }
  }).readOnly()
});
